import React, { useState } from "react";
import { useEffect } from "react";
import { TbDots } from "react-icons/tb";
import ApiClient from "../../methods/api/apiClient";
import Box from "@mui/material/Box";
import { useSpring, animated } from "@react-spring/web";
import SvgIcon from "@mui/material/SvgIcon";
import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Pagination from "react-pagination-js";
import {
  TreeItem,
  treeItemClasses,
  useTreeItem,
} from "@mui/x-tree-view/TreeItem";
import { Tooltip } from "antd";
import ChildOKR from "./ChildOKR";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import loader from "../../methods/loader";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import KeyResultModal from "./KeyResultModal";
import { useSelector } from "react-redux";
import datepipeModel from "../../models/datepipemodel";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

const AlignmentOKR = ({
  id,
  handleUpdateKeyResultModal = () => {},
  getOKRListing = () => {},
  OKRListing,
  handleAction = () => {},
  handleKeyResultAction = () => {},
  findOKRProgress,
  getData,
  owners,
  getKeyResults,
  detail,
  detailModal,
  view = () => {},
  ConfidenceLevel = () => {},
  valueData,
  setNewValue,
  valueDataUnit,
  setNewValueUnit,
  filter,
  total,
  changePage = () => {},
}) => {
  const [Item, setItem] = useState(null);
  const [keyloader, setloader] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getOKRListing();
  }, [id]);

  // Hierarchy Tree
  function MinusSquare(props) {
    return (
      <div className="circle-arrow">
        <FaArrowDown />
      </div>
    );
  }
  function PlusSquare(props) {
    // if (props?.data?.keyResults?.length > 0) {
    return (
      <div className="circle-arrow">
        <FaArrowRightLong />
      </div>
    );
    // } else {
    //     return ''
    // }
  }

  const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
      handleSelection(event);
    };

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      </div>
    );
  });

  const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
  });

  const delectOkr = (id) => {
    if (window.confirm(`Do you really want to delete this OKR`)) {
      let payload = {
        id: id,
        // model: "okr",
      };
      loader(true);
      ApiClient.delete(`okr`, payload).then((res) => {
        if (res?.success) {
          getOKRListing();
          getData();
        }
        loader(false);
      });
    }
  };

  const handleKeyModal = async (item) => {
    await detailModal(item?.id || item?._id, false);
    await setItem(item);
    document.getElementById(`OpenKeyResultModal`).click();
  };

  const getBrowserLocale = () => {
    const language = navigator.language.toLowerCase();
    if (language.includes("fr")) {
      return LOCALES.FRENCH;
    }
    return LOCALES.ENGLISH;
  };

  const locale = getBrowserLocale();
  return (
    <>
      <I18nProvider locale={locale}>
        <div className="oksrs_alignment ">
          <div className="alignment-background">
            <div className="card_line_head_two align-items-center align-width">
              <div className="width-25">
                <h6 className="mb-0"></h6>
              </div>
              <div className="width-75 end-lines">
                <div className="d-flex align-items-center jusendokrs justify-content-between">
                  <div className="common-w progress-h">
                    <h6 className="mb-0">{translate("progress")}</h6>
                  </div>
                  <div className="common-w confidence">
                    <h6 className="mb-0">{translate("confidence")}</h6>
                  </div>
                  <div className="common-w owners">
                    <h6 className="mb-0">{translate("owner")}</h6>
                  </div>
                  <div className="common-w contributors">
                    <h6 className="mb-0">{translate("contributor")}</h6>
                  </div>
                  <div className="common-w last-updates">
                    <h6 className="mb-0">{translate("lastUpdated")}</h6>
                  </div>
                  <div className="common-w actions">
                    <h6 className="mb-0 pe-2"></h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              {OKRListing &&
                OKRListing?.map((item, index) => {
                  return (
                    <>
                      <div className="aligmentinrs pb-1 row">
                        <div className="col-md-12 mx-auto">
                          <div className="position-relative multitree-box">
                            <Box>
                              <TreeView
                                aria-label="customized"
                                defaultCollapseIcon={<MinusSquare />}
                                defaultExpandIcon={<PlusSquare data={item} />}
                                // defaultEndIcon={<CloseSquare />}
                                sx={{ overflowX: "hidden" }}
                              >
                                <CustomTreeItem
                                  nodeId={item?.id || item?._id}
                                  onClick={(e) => {}}
                                  label={
                                    <div className="">
                                      <div className=" card_line position-relative align-width">
                                        <div className="width-25">
                                          <div className="alings_data d-flex align-items-center">
                                            <span className="mr-1 objective-img">
                                              <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                                                <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                                                <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                                                <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                                                <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                                                <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                                                <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                                                <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                                                <circle
                                                  cx="12"
                                                  cy="12"
                                                  r="1"
                                                ></circle>
                                              </svg>
                                            </span>
                                            <span
                                              className={`progressDiv mx-2 progress-color`}
                                            >
                                              {" "}
                                              {findOKRProgress(
                                                item?.keyResults,
                                                "this okr is from okr listing"
                                              )}
                                            </span>
                                            <span
                                              className="fs14"
                                              onClick={() => {
                                                view(item);
                                              }}
                                            >
                                              {item?.title}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="width-75 end-line">
                                          <div className="d-flex align-items-center jusendokrs justify-content-between">
                                            <div className="common-w progress-h">
                                              {/* <Tooltip
                                              title={`Objective Progress: ${findOKRProgress(
                                                item?.keyResults
                                              )}`}
                                              placement="top"
                                            >
                                              <div
                                                className="d-flex align-items-center"
                                                style={{ marginTop: "5px" }}
                                              >
                                                <div
                                                  className="progress"
                                                  style={{
                                                    width: "100px",
                                                    height: "8px",
                                                  }}
                                                >
                                                  <div
                                                    className="progress-bar progress-bar-color"
                                                    role="progressbar"
                                                    style={{
                                                      width: findOKRProgress(
                                                        item?.keyResults
                                                      ),
                                                    }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                  ></div>
                                                </div>
                                              </div>
                                            </Tooltip> */}
                                            </div>
                                            <div className="common-w confidence">
                                              {!(
                                                item?.keyType ==
                                                  "child_objective" ||
                                                item?.keyType == "okr"
                                              ) ? (
                                                <>
                                                  <ConfidenceLevel
                                                    data={item}
                                                  />
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="common-w owners">
                                              <div className="owners-number d-flex justify-content-end flex-wrap gap-2">
                                                {item?.ownerDetails?.map(
                                                  (itm, i) => {
                                                    if (i < 4)
                                                      return (
                                                        <>
                                                          {itm?.id ? (
                                                            <span
                                                              style={{
                                                                backgroundColor:
                                                                  itm.color ||
                                                                  "",
                                                              }}
                                                            >
                                                              {itm?.name}
                                                            </span>
                                                          ) : (
                                                            <span className="fs-6">
                                                              <Tooltip
                                                                title="No owner"
                                                                placement="top"
                                                              >
                                                                <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth="0"
                                                                  viewBox="0 0 640 512"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L440.6 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 30.2-10.5 58-28 79.9l-25.2-19.7C408.1 267.7 416 246.8 416 224c0-53-43-96-96-96c-31.1 0-58.7 14.8-76.3 37.7l-40.6-31.8c13-14.2 20.9-33.1 20.9-53.9c0-44.2-35.8-80-80-80C116.3 0 91.9 14.1 77.5 35.5L38.8 5.1zM106.7 192C47.8 192 0 239.8 0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-20.6-18.2-35.2-42.8-40.8-70.8L121.8 192H106.7zM261.3 352C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c10.5 0 19.5-6 23.9-14.8L324.9 352H261.3zM512 160A80 80 0 1 0 512 0a80 80 0 1 0 0 160z"></path>
                                                                </svg>
                                                              </Tooltip>
                                                            </span>
                                                          )}
                                                        </>
                                                      );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                            <div className="common-w contributors">
                                              <div className="owners-number d-flex justify-content-end flex-wrap gap-2">
                                                {item?.contributorDetails?.map(
                                                  (itm, i) => {
                                                    if (i < 4)
                                                      return (
                                                        <>
                                                          {itm?.id ? (
                                                            <span
                                                              style={{
                                                                backgroundColor:
                                                                  itm.color ||
                                                                  "",
                                                              }}
                                                            >
                                                              {itm?.name}
                                                            </span>
                                                          ) : (
                                                            <span className="fs-6">
                                                              <Tooltip
                                                                title="No owner"
                                                                placement="top"
                                                              >
                                                                <svg
                                                                  stroke="currentColor"
                                                                  fill="currentColor"
                                                                  strokeWidth="0"
                                                                  viewBox="0 0 640 512"
                                                                  height="1em"
                                                                  width="1em"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L440.6 320H618.7c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192H490.7c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 30.2-10.5 58-28 79.9l-25.2-19.7C408.1 267.7 416 246.8 416 224c0-53-43-96-96-96c-31.1 0-58.7 14.8-76.3 37.7l-40.6-31.8c13-14.2 20.9-33.1 20.9-53.9c0-44.2-35.8-80-80-80C116.3 0 91.9 14.1 77.5 35.5L38.8 5.1zM106.7 192C47.8 192 0 239.8 0 298.7C0 310.4 9.6 320 21.3 320H234.7c.2 0 .4 0 .7 0c-20.6-18.2-35.2-42.8-40.8-70.8L121.8 192H106.7zM261.3 352C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c10.5 0 19.5-6 23.9-14.8L324.9 352H261.3zM512 160A80 80 0 1 0 512 0a80 80 0 1 0 0 160z"></path>
                                                                </svg>
                                                              </Tooltip>
                                                            </span>
                                                          )}
                                                        </>
                                                      );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                            {/* <div className="col-2"> */}
                                            <div className="common-w last-updates">
                                              {/* {datepipeModel.getDays(item.updatedAt,new Date())} Day{datepipeModel.getDays(item.updatedAt,new Date())>1?'s':''} */}
                                            </div>
                                            {/* </div> */}
                                            <div className="common-w actions">
                                              <div className="dropsdowns_align spacing_equal text-end">
                                                <div className="dropdown d-flex justify-content-end">
                                                  <p
                                                    className="text-black pointer"
                                                    data-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <i className="fa fa-ellipsis-v text-dark new-ellipses"></i>
                                                  </p>
                                                  {/* {user?.role != "employee" && ( */}
                                                  <div className="dropdown-menu menu-design">
                                                    <span
                                                      className="dropdown-item"
                                                      onClick={(e) => {
                                                        const actionType =
                                                          item.keyType ===
                                                          "child_objective"
                                                            ? "Child Objective"
                                                            : "Edit";
                                                        const id =
                                                          item?._id || item?.id;
                                                        handleAction(
                                                          actionType,
                                                          id
                                                        );
                                                      }}
                                                    >
                                                      <i className="fa fa-pen me-2"></i>
                                                      Edit
                                                    </span>
                                                    <span
                                                      className="dropdown-item"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        handleKeyModal(item);
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#KeyResultModal"
                                                    >
                                                      <i className="fa fa-plus me-2"></i>
                                                      Add Key Result
                                                    </span>
                                                    <span
                                                      className="dropdown-item"
                                                      onClick={(e) => {
                                                        handleKeyResultAction(
                                                          "Child Objective",
                                                          item
                                                        );
                                                      }}
                                                    >
                                                      <i className="fa fa-plus me-2"></i>
                                                      Add a Child Objective
                                                    </span>

                                                    <span
                                                      className="dropdown-item"
                                                      onClick={(e) => {
                                                        const idToDelete =
                                                          item?.id || item?._id; // Make sure to define this before logging
                                                        delectOkr(idToDelete);
                                                      }}
                                                    >
                                                      <i className="fa fa-trash me-2"></i>
                                                      Delete
                                                    </span>
                                                  </div>
                                                  {/* )} */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  {item.child?.length > 0 ? (
                                    <ChildOKR
                                      valueData={valueData}
                                      setNewValue={setNewValue}
                                      valueDataUnit={valueDataUnit}
                                      setNewValueUnit={setNewValueUnit}
                                      data={item.child}
                                      handleAction={handleAction}
                                      handleKeyResultAction={
                                        handleKeyResultAction
                                      }
                                      handleUpdateKeyResultModal={
                                        handleUpdateKeyResultModal
                                      }
                                      handleKeyModal={handleKeyModal}
                                      delectOkr={delectOkr}
                                      findOKRProgress={findOKRProgress}
                                      ConfidenceLevel={ConfidenceLevel}
                                      view={view}
                                    />
                                  ) : null}
                                </CustomTreeItem>
                              </TreeView>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          {/* {OKRListing?.length<=0?<p className="text-center clock-icon mt-5">no data <img src="/assets/img/loader-gif.gif" className="mb-4"/>We are getting all your ambitious OKRs aligned</p>:''} */}
          {OKRListing?.length <= 0 ? (
            <div className="py-3 text-center no-data">
              <img src="/assets/img/no-data-logo.svg" />
              No Data Found
            </div>
          ) : (
            ""
          )}
          {OKRListing?.length != 0 && (
            <div className="paginationWrapper">
              <span>
                {translate("show")}{" "}
                {filter.page === Math.ceil(total / filter.count)
                  ? total
                  : filter.page * filter.count < total
                  ? filter.page * filter.count
                  : total}{" "}
                {translate("of")} {total} {translate("records")}
              </span>
              <Pagination
                currentPage={filter.page}
                totalSize={total}
                sizePerPage={filter.count}
                changeCurrentPage={changePage}
              />
            </div>
          )}

          {!keyloader && (
            <KeyResultModal
              id={`keyresult`}
              keyloader={keyloader}
              setloader={setloader}
              owners={owners}
              getKeyResults={getKeyResults}
              getData={getData}
              item={Item}
              detail={detail}
              getOKRListing={getOKRListing}
            />
          )}
        </div>
      </I18nProvider>
    </>
  );
};

export default AlignmentOKR;
