import methodModel from "../../../methods/methods";
import { Link } from "react-router-dom";
import "./style.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { LuPlusCircle } from "react-icons/lu";
import environment from "../../../environment";
import translate from "../../../i18n/translate";

// import PlacesAutocomplete from "react-places-autocomplete";

const Html = ({
  handleSubmit,
  setForm,
  form,
  uploadImage,
  submitted,
  Layout,
  // handleSelect, handleChange, clearaddress, inputFocused, setInputFocused, address,
}) => {
  return (
    <>
      {Layout == true ? (
        <>
          <div className="container-fluid">
            <div className="pprofile1">
              <div className="d-flex justify-content-between align-items-center ">
                <h3 className="ViewUser">Basic Information</h3>
              </div>

              <form className="form-row" onSubmit={handleSubmit}>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>Image</label>
                  <div>
                    <div className="maininutcls">
                      <label className="profileImageLabel">
                        <img
                          src={
                            form?.image
                              ? `${environment.api}/images/users/` + form?.image
                              : "/assets/img/person.jpg"
                          }
                          className="profileImage"
                        />
                      </label>
                      <div className="profile_btn">
                        <div>
                          <label className="btn btn-primary edit ml-3">
                            <input
                              id="bannerImage"
                              type="file"
                              className="d-none"
                              accept="image/*"
                              value={form.baseImg ? form.baseImg : ""}
                              onChange={(e) => {
                                uploadImage(e);
                              }}
                            />
                            {form.image ? "Change" : "Upload"}{" "}
                            {form?.role == "individual" ? "Image" : "image"}
                          </label>
                        </div>
                        <div>
                          {form.image ? (
                            <label
                              className="btn btn-primary  delete ml-3"
                              onClick={(e) => setForm({ ...form, image: "" })}
                            >
                              Remove Image
                            </label>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>
                    Name<span className="star">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={form.fullName ? form?.fullName : form?.firstName}
                      onChange={(e) =>
                        setForm({ ...form, fullName: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>

                {/* {form?.role == 'company' && <div className="col-md-12 mb-3 inputFlex">
                <label>Company Name<span className='star'>*</span></label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    value={form?.companyName}
                    onChange={e => setForm({ ...form, companyName: e.target.value })}
                    required
                  />
                </div>
              </div>} */}

                <div className="col-md-12 mb-3 inputFlex">
                  <label>Email</label>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Name"
                      value={form.email ? form.email : ""}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>
                    Mobile No<span className="star">*</span>
                  </label>
                  <div>
                    <div className="form-row">
                      <div className="col-md-3">
                        <PhoneInput
                          country={"fr"}
                          required
                          enableSearch={true}
                          placeholder="Enter phone number"
                          value={form?.dialCode || ""}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                          countryCodeEditable={false}
                          onChange={(e) => {
                            setForm({ ...form, dialCode: e || "" });
                          }}
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="mobile-number-set">
                          <input
                            type="text"
                            className={`form-control ${
                              form.mobileNo && form.mobileNo.length < 10
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Mobile No."
                            value={(form && form.mobileNo) || ""}
                            maxLength={12}
                            onChange={(e) => {
                              const value = methodModel.isNumber(e);
                              setForm({ ...form, mobileNo: value || "" });
                            }}
                            required
                          />
                          {/* Instant validation feedback */}
                          {form.mobileNo && form.mobileNo.length < 10 && (
                            <div className="invalid-feedback">
                              Mobile number must be at least 10 characters long.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>Role</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={methodModel?.firtLetterCap(form?.role)}
                      disabled
                    />
                  </div>
                </div>

                {/* <div className="col-md-6 mb-3">
                <label>Address</label>
                <div>
                  <PlacesAutocomplete
                    value={form?.address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className='llk position-relative' style={{ display: 'block' }}>
                        <div className="position-relative">
                          <input className="form-control "

                            {...getInputProps({
                              placeholder: 'Enter an address...',
                              onFocus: () => setInputFocused(true),
                              onBlur: () => setInputFocused(false),
                            })} />

                          {address ? <i className='fa fa-times fg' title='Clear' onClick={clearaddress} /> : <></>}

                        </div>
                        {inputFocused && suggestions.length ? <div className='shadow mt-2 br20 p-3'>
                          {loading && <div>Loading...</div>}

                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                            };
                            return (
                              <div className='location_address'
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                <p className="mb-2 pointer"><i className="fa fa-map-marker me-2"></i>{suggestion.description}</p>
                              </div>
                            );
                          })}
                        </div> : <></>}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>

              </div> */}

                <div className="col-md-12 text-right mt-3">
                  <Link to="/profile" className="btn btn-primary reset">
                    Discard
                  </Link>
                  <button type="submit" className="btn btn-primary edit ml-3">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="  pprofile1">
            <div className="">
              <div className="d-flex justify-content-between align-items-center  mb-4">
                <h5 className="">Edit Profile</h5>
              </div>

              <form className="form-row" onSubmit={handleSubmit}>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>Image</label>
                  <div>
                    <div className="maininutcls position-relative">
                      <label className="profileImageLabel">
                        <img
                          src={
                            form?.image
                              ? `${environment.api}/images/users/` + form?.image
                              : "/assets/img/person.jpg"
                          }
                          className="profileImage"
                        />
                      </label>
                      <div className="profile_btn">
                        <div>
                          <label id="openfile" className="btn p-0  mb-0">
                            {/* <input
                            id="bannerImage"
                            type="file"
                            className="d-none"
                            accept="image/*"
                            value={form.baseImg ? form.baseImg : ''}
                            onChange={(e) => { uploadImage(e); }}
                          />{form.image ? 'Change' : 'Upload'} {form?.role == 'individual' ? 'Image' : 'image'} */}

                            <input
                              id="bannerImage"
                              type="file"
                              className="d-none"
                              accept="image/*"
                              value={form.baseImg ? form.baseImg : ""}
                              onChange={(e) => {
                                uploadImage(e);
                              }}
                            />
                          </label>
                          {form.image ? (
                            <span
                              className="profile-btn-label"
                              onClick={(e) => setForm({ ...form, image: "" })}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              className="profile-btn-label"
                              onClick={(e) =>
                                document.getElementById("openfile").click()
                              }
                            >
                              +
                            </span>
                          )}
                        </div>
                        <div>
                          {/* {form.image ? <label className="btn btn-primary  delete ml-3" onClick={e => setForm({ ...form, image: "" })}>Remove Image</label> : <></>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>
                    Name<span className="star">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={form.fullName ? form?.fullName : form?.firstName}
                      onChange={(e) =>
                        setForm({ ...form, fullName: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>Email</label>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Name"
                      value={form.email ? form.email : ""}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3  inputFlex mobile-no-main">
                  <label>
                    Mobile No<span className="star">*</span>
                  </label>
                  <div>
                    <div className="form-row ">
                      <div className="col-md-4">
                        <PhoneInput
                          country={"fr"}
                          required
                          enableSearch={true}
                          placeholder="Enter phone number"
                          value={form?.dialCode || ""}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                          countryCodeEditable={false}
                          onChange={(e) => {
                            setForm({ ...form, dialCode: e || "" });
                          }}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="mobile-number-set">
                          <input
                            type="text"
                            className={`form-control ${
                              form.mobileNo &&
                              (form.mobileNo.length < 10 ||
                                form.mobileNo.length > 12)
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Mobile No."
                            value={(form && form.mobileNo) || ""}
                            maxLength={12}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                mobileNo: methodModel.isNumber(e) || "",
                              })
                            }
                            required
                          />
                          {/* Instant validation feedback */}
                          {form.mobileNo &&
                            (form.mobileNo.length < 10 ||
                              form.mobileNo.length > 12) && (
                              <div className="invalid-feedback">
                                Mobile number must be between 10 and 12
                                characters long.
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3  inputFlex ">
                  <label>Role</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={methodModel?.firtLetterCap(form?.role)}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>What I Love Doing</label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Enter what you love doing"
                      value={form.whatILoveDoing || ""}
                      onChange={(e) =>
                        setForm({ ...form, whatILoveDoing: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>How to Communicate</label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Enter how to communicate"
                      value={form.howToCommunicate || ""}
                      onChange={(e) =>
                        setForm({ ...form, howToCommunicate: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>What Matters Most</label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Enter what matters most"
                      value={form.whatMattersMost || ""}
                      onChange={(e) =>
                        setForm({ ...form, whatMattersMost: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>What Can Frustrate</label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Enter what can frustrate"
                      value={form.whatCanFrustrate || ""}
                      onChange={(e) =>
                        setForm({ ...form, whatCanFrustrate: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>Something About Me</label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Enter something about yourself"
                      value={form.somethingAboutMe || ""}
                      onChange={(e) =>
                        setForm({ ...form, somethingAboutMe: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 inputFlex mb-3">
                  <label>{translate("default_trust_level")}</label>
                  <div className="text-center">
                    <div className=""></div>
                    <div className="col-md-9 mx-auto">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={form?.defaultTrustLevel}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            defaultTrustLevel: e.target.value,
                          })
                        }
                        className="trust-range"
                      />
                      <div className="battery">
                        <div
                          className="battery-bar"
                          role="progressbar"
                          data-level={
                            form?.defaultTrustLevel <= 30
                              ? "low"
                              : form?.defaultTrustLevel <= 70
                              ? "medium"
                              : "high"
                          }
                          style={{
                            width: `${form?.defaultTrustLevel}%`,
                          }}
                          aria-valuenow={form?.defaultTrustLevel}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {form?.defaultTrustLevel}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>How to Gain Trust</label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Enter how to gain trust"
                      value={form.howToGainTrust || ""}
                      onChange={(e) =>
                        setForm({ ...form, howToGainTrust: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 inputFlex">
                  <label>How to Lose Trust</label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Enter how to lose trust"
                      value={form.howToLoseTrust || ""}
                      onChange={(e) =>
                        setForm({ ...form, howToLoseTrust: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 text-right mt-3">
                  {/* <Link to="/profile" className="btn btn-primary reset">
                      Discard
                      </Link> */}
                  <button type="submit" className="btn btn-primary edit ml-3">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Html;
