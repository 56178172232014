import { createIntl, createIntlCache } from "react-intl";
import messages from "./messages";
import { LOCALES } from "./locales";

const getBrowserLocale = () => {
  const language = navigator.language.toLowerCase();
  if (language.includes("fr")) {
    return LOCALES.FRENCH;
  }
  return LOCALES.ENGLISH;
};

const locale = getBrowserLocale();

// Create the cache
const cache = createIntlCache();

// Create the intl object
const intl = createIntl(
  {
    locale: locale, // Set your default locale here
    messages: messages[locale],
  },
  cache
);

const translate = (id, values = {}) => intl.formatMessage({ id }, values);

export default translate;
