import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { RiBillLine } from "react-icons/ri";
import { SlLocationPin } from "react-icons/sl";
import { RiFileHistoryLine } from "react-icons/ri";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { move } from "formik";
import { useDispatch } from "react-redux";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import translate from "../../i18n/translate";
import "./style.scss"; // Import the CSS file

const AccountDomain = () => {
  const Navigate = useHistory();

  const user = useSelector((state) => state.user);
  const [data, setData] = useState({});
  const [workspaceId, setWorkspaceId] = useState([]); // State variable to store workspace ID
  const [tab, settab] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // State variable to handle edit mode
  const [workspaceNotFound, setWorkspaceNotFound] = useState(false); // State variable to handle workspace not found
  const dispatch = useDispatch();
  const navigate = useState();
  const getTotalUser = (p = {}) => {
    loader(true);
    ApiClient.get(`profile?id=${user?.id}`).then((res) => {
      if (res.success) {
        // Store the workspace ID
        setWorkspaceId(res?.data?.workspaceIds || []);
      }
      loader(false);
    });
  };

  const getWorkspaceDetails = () => {
    if (!workspaceId.length) {
      setWorkspaceNotFound(true);
      return;
    }
    loader(true);
    ApiClient.get(`workspace/details/${user.workspaceIds}`).then((res) => {
      if (res.success) {
        setData(res?.data);
        setWorkspaceNotFound(false);
      } else {
        setWorkspaceNotFound(true);
      }
      loader(false);
    });
  };

  useEffect(() => {
    getTotalUser();
  }, []);

  useEffect(() => {
    if (workspaceId.length) {
      getWorkspaceDetails();
    } else {
      setWorkspaceNotFound(true);
    }
  }, [workspaceId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit the updated data
    ApiClient.put(`profile?id=${user?.id}`, data).then((res) => {
      if (res.success) {
        setIsEditing(false);
        getTotalUser();
      }
    });
  };

  const editWorkspace = (e) => {
    e.preventDefault();
    ApiClient.put(`edit/workspace?id=${user.workspaceIds}`, data).then(
      (res) => {
        if (res.success) {
          setIsEditing(false);
          getWorkspaceDetails();
        }
      }
    );
  };

  if (workspaceNotFound) {
    return (
      <Layout>
        <div className="container company-profile">
          <div className="alert alert-warning" role="alert">
            {translate("noLinkedWorkspace")}
          </div>
        </div>
      </Layout>
    );
  }

  const isCompanyRole = user?.role?.includes("company");

  return (
    <Layout>
      <div className="container company-profile">
        {isCompanyRole && (
          <div className="text-end mb-4">
            {!isEditing && (
              <button
                className="btn btn-primary"
                onClick={() => setIsEditing(true)}
              >
                <i className="fa fa-pen me-2"></i>
                {translate("edit")}
              </button>
            )}
          </div>
        )}

        {/* Company details */}
        <div className="row bg-white rounded-top m-3">
          <div className="col-12 my-2">
            {isEditing ? (
              <form onSubmit={editWorkspace} className="company-form">
                {/* Header with company name and logo in edit mode */}
                <div className="row align-items-center mb-4">
                  <div className="col-md-6  mb-3">
                    <label className="form-label">
                      {translate("companyName")}:
                    </label>
                    <FormControl
                      type="text"
                      name="companyName"
                      value={data.companyName || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("companyLogo")}:
                    </label>
                    <div className="d-flex align-items-center">
                      {data.companyLogo && (
                        <img
                          src={
                            data.companyLogo.startsWith("http")
                              ? data.companyLogo
                              : methodModel.userImg(data.companyLogo)
                          }
                          alt="Current logo"
                          className="me-3"
                          style={{ height: "40px", width: "auto" }}
                        />
                      )}
                      <div className="flex-grow-1">
                        <input
                          type="file"
                          className="form-control"
                          id="logoUpload"
                          accept="image/*"
                          onChange={(e) => {}}
                        />
                        <small className="form-text text-muted">
                          {translate("clickToUploadImage")}
                        </small>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("annualRevenue")}:
                    </label>
                    <FormControl
                      type="text"
                      name="annualRevenue"
                      value={data.annualRevenue || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("companyUrl")}:
                    </label>
                    <FormControl
                      type="text"
                      name="companyUrl"
                      value={data.companyUrl || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("linkedinUrl")}:
                    </label>
                    <FormControl
                      type="text"
                      name="linkedinUrl"
                      value={data.linkedinUrl || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("industry")}:
                    </label>
                    <FormControl
                      type="text"
                      name="industry"
                      value={data.industry || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("employeeCount")}:
                    </label>
                    <FormControl
                      type="text"
                      name="employeeCount"
                      value={data.employeeCount || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("foundedYear")}:
                    </label>
                    <FormControl
                      type="text"
                      name="foundedYear"
                      value={data.foundedYear || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("departments")}:
                    </label>
                    <FormControl
                      type="text"
                      name="departments"
                      value={data.departments || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("keyMetrics")}:
                    </label>
                    <FormControl
                      type="text"
                      name="keyMetrics"
                      value={data.keyMetrics || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label">
                      {translate("mission")}:
                    </label>
                    <FormControl
                      as="textarea"
                      rows={3}
                      name="mission"
                      value={data.mission || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label">{translate("vision")}:</label>
                    <FormControl
                      as="textarea"
                      rows={3}
                      name="vision"
                      value={data.vision || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label">{translate("values")}:</label>
                    <FormControl
                      as="textarea"
                      rows={3}
                      name="values"
                      value={data.values || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label">
                      {translate("productsServices")}:
                    </label>
                    <FormControl
                      as="textarea"
                      rows={3}
                      name="productsServices"
                      value={data.productsServices || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label">
                      {translate("strategicPriorities")}:
                    </label>
                    <FormControl
                      as="textarea"
                      rows={3}
                      name="strategicPriorities"
                      value={data.strategicPriorities || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      {translate("createdAt")}:
                    </label>
                    <FormControl
                      type="text"
                      name="createdAt"
                      value={moment(data.createdAt).format("LL") || ""}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>

                  <div className="col-md-6  mb-3">
                    <label className="form-label">
                      {translate("updatedAt")}:
                    </label>
                    <FormControl
                      type="text"
                      name="updatedAt"
                      value={moment(data.updatedAt).format("LL") || ""}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>

                  <div className="col-12 text-end mb-4 mt-3">
                    <Button type="submit" className="btn btn-primary mr-2">
                      {translate("save")}
                    </Button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => setIsEditing(false)}
                    >
                      <i className="fa fa-times me-2"></i>
                      {translate("cancel")}
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div className="company-info">
                {/* Header with company name and logo in view mode */}
                <div className="row align-items-center mb-4">
                  <div className="col-md-6 ">
                    <h2 className="company-title my-2">
                      {data?.companyName || translate("company")}
                    </h2>

                    <p className="company-subtitle">{translate("nameofOrg")}</p>
                  </div>

                  <div className="col-md-6 text-md-end">
                    <img
                      src={
                        data.companyLogo
                          ? data.companyLogo
                          : methodModel.userImg(data.companyLogo)
                      }
                      alt="Current logo"
                      className="me-3"
                      style={{
                        height: "70px",
                        width: "auto",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 border rounded-3 my-2">
                    <div className="info-card mb-3">
                      <h4 className="info-card-title my-2 color-primary">
                        {translate("basicInfo")}
                      </h4>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("companyName")}:
                        </span>
                        <span className="info-value">{data?.companyName}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("industry")}:
                        </span>
                        <span className="info-value">{data?.industry}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("foundedYear")}:
                        </span>
                        <span className="info-value">{data?.foundedYear}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("employeeCount")}:
                        </span>
                        <span className="info-value">
                          {data?.employeeCount}
                        </span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("annualRevenue")}:
                        </span>
                        <span className="info-value">
                          {data?.annualRevenue}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 border rounded-3 my-2">
                    <div className="info-card mb-3">
                      <h4 className="my-2">{translate("contactInfo")}</h4>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("companyUrl")}:
                        </span>
                        <a
                          href={data?.companyUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="info-value"
                        >
                          {data?.companyUrl}
                        </a>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("linkedinUrl")}:
                        </span>
                        <a
                          href={data?.linkedinUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="info-value"
                        >
                          {data?.linkedinUrl}
                        </a>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("departments")}:
                        </span>
                        <span className="info-value">{data?.departments}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 border rounded-3 my-2">
                    <div className="info-card mb-3">
                      <h4 className="my-2">{translate("companyDetails")}</h4>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("mission")}:
                        </span>
                        <span className="info-value">{data?.mission}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("vision")}:
                        </span>
                        <span className="info-value">{data?.vision}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("values")}:
                        </span>
                        <span className="info-value">{data?.values}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 border rounded-3 my-2">
                    <div className="info-card mb-3">
                      <h4 className="my-2">{translate("businessInfo")}</h4>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("productsServices")}:
                        </span>
                        <span className="info-value">
                          {data?.productsServices}
                        </span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("strategicPriorities")}:
                        </span>
                        <span className="info-value">
                          {data?.strategicPriorities}
                        </span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("keyMetrics")}:
                        </span>
                        <span className="info-value">{data?.keyMetrics}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 border rounded-3 my-2">
                    <div className="info-card mb-3">
                      <h4 className="my-2">{translate("timestamps")}</h4>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("createdAt")}:
                        </span>
                        <span className="info-value">
                          {data?.createdAt
                            ? moment(data.createdAt).format("LL")
                            : ""}
                        </span>
                      </div>
                      <div className="info-item">
                        <span className="info-label mr-2">
                          {translate("updatedAt")}:
                        </span>
                        <span className="info-value">
                          {data?.updatedAt
                            ? moment(data.updatedAt).format("LL")
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AccountDomain;
