import { useState, useEffect } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./profile.scss";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChangePassword from "../../components/Profile/ChangePassword";
import EditProfile from "../../components/Profile/Edit";
import environment from "../../environment";
import translate from "../../i18n/translate";
import { toast } from "react-toastify";

const Profile = () => {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const history = useHistory();

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      gallaryData();

      let tab = methodModel.getPrams("tab");
      if (tab == "change-pasword") {
        document.getElementById("v-pills-profile-tab")?.click();
      }
    }
  }, []);

  useEffect(() => {
    const message = localStorage.getItem("profileUpdateMessage");
    if (message) {
      toast.success(message);
      localStorage.removeItem("profileUpdateMessage");
    }
  }, []);

  const [ShowWhich, setShowWhich] = useState("profile");
  return (
    <Layout>
      <div className="profile-tabs">
        <div className="container">
          <div className="row">
            <div className="p-0">
              <div className=" profile-tabs-left">
                <div
                  className="nav flex-row nav-pills "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="horizontal"
                >
                  <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={(e) => setShowWhich("profile")}
                  >
                    <i
                      className="fa fa-user-circle me-2"
                      aria-hidden="true"
                    ></i>
                    {translate("profile")}
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                    onClick={(e) => setShowWhich("changepassword")}
                  >
                    <i className="fa fa-lock me-2" aria-hidden="true"></i>
                    {translate("changePassword")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 p-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  {ShowWhich == "profile" ? (
                    <div className="pprofile1">
                      <div className="d-flex justify-content-end  mb-3">
                        <p
                          className="btn btn-primary edit-profiles"
                          onClick={(e) => setShowWhich("edit")}
                        >
                          <i
                            className="material-icons prob"
                            title="Edit Profile"
                          >
                            mode_edit_outline
                          </i>

                          {/* <i className="fa fa-edit" title='Edit Profile' /> */}
                        </p>
                      </div>
                      <div className="form-row">
                        <div className="col-md-12 mb-3 inputFlex">
                          <label>{translate("image")}</label>
                          <div>
                            <label className="profileImageLabel">
                              <img
                                src={
                                  data?.image
                                    ? `${environment.api}/images/users/` +
                                      data?.image
                                    : "/assets/img/person.jpg"
                                }
                                className="profileImage"
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 inputFlex mb-3">
                          <label>Name</label>
                          <div>
                            <p className="profile_data">
                              {data &&
                                methodModel.firtLetterCap(
                                  data.fullName || data.firstName
                                )}
                            </p>
                          </div>
                        </div>
                        {data?.role == "company" && (
                          <div className="col-md-12 inputFlex mb-3">
                            <label>{translate("companyName")}</label>
                            <div>
                              <p className="profile_data">
                                {data &&
                                  methodModel.firtLetterCap(data?.companyName)}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("role")}</label>
                          <div>
                            <p className="profile_data">
                              {data && methodModel.firtLetterCap(data.role)}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("email")}</label>
                          <div>
                            <p className="profile_data">{data && data.email}</p>
                          </div>
                        </div>
                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("what_i_love_doing")}</label>
                          <div>
                            <p className="profile_data">
                              {data && data.whatILoveDoing}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("how_to_communicate")}</label>
                          <div>
                            <p className="profile_data">
                              {data && data.howToCommunicate}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("what_matters_most")}</label>
                          <div>
                            <p className="profile_data">
                              {data && data.whatMattersMost}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("what_can_frustrate")}</label>
                          <div>
                            <p className="profile_data">
                              {data && data.whatCanFrustrate}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("something_about_me")}</label>
                          <div>
                            <p className="profile_data">
                              {data && data.somethingAboutMe}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("default_trust_level")}</label>
                          <div className="text-center">
                            <div className="col-md-9 ">
                              <div className="battery">
                                <div
                                  className="battery-bar"
                                  role="progressbar"
                                  data-level={
                                    data?.defaultTrustLevel <= 30
                                      ? "low"
                                      : data?.defaultTrustLevel <= 70
                                      ? "medium"
                                      : "high"
                                  }
                                  style={{
                                    width: `${data?.defaultTrustLevel}%`,
                                  }}
                                  aria-valuenow={data?.defaultTrustLevel}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {data?.defaultTrustLevel}%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("how_to_gain_trust")}</label>
                          <div>
                            <p className="profile_data">
                              {data && data.howToGainTrust}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12 inputFlex mb-3">
                          <label>{translate("how_to_lose_trust")}</label>
                          <div>
                            <p className="profile_data">
                              {data && data.howToLoseTrust}
                            </p>
                          </div>
                        </div>

                        {data.mobileNo ? (
                          <div className="col-md-12 inputFlex mb-3">
                            <label>{translate("mobile")}</label>
                            <div>
                              <p className="profile_data">
                                +{data && data.dialCode + " " + data.mobileNo}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {data.address ? (
                          <div className="col-md-12 inputFlex mb-3">
                            <label>{translate("mobile")}</label>
                            <div>
                              <p className="profile_data">
                                {data &&
                                  methodModel.firtLetterCap(data.address)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* <div className="col-md-12">
            <label>Created At</label>
            <p className="bg-light rounded px-3 py-2">{data && data.createdAt}</p>
          </div> */}
                      </div>
                    </div>
                  ) : (
                    <EditProfile Layout={false} />
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <div>
                    <ChangePassword />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
