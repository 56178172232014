import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import translate from "../../i18n/translate";
import { ProgressBar } from "react-bootstrap";
import "./style.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Row, Tooltip } from "antd";
import { set } from "react-ga";

const Onboarding = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [form, setForm] = useState({
    companyName: "",
    companyLogo: "",
    companyUrl: "",
    foundedYear: 0,
    industry: "",
    about: "",
    mission: "",
    vision: "",
    values: "",
    employeeCount: 0,
    annualRevenue: 0,
    departments: "",
    strategicPriorities: "",
    productsServices: "",
    keyMetrics: "",
    linkedinUrl: "",

    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    mobileNo: "",
    whatILoveDoing: "",
    howToCommunicate: "",
    whatMattersMost: "",
    whatCanFrustrate: "",
    somethingAboutMe: "",
    defaultTrustLevel: 0,
    howToGainTrust: "",
    howToLoseTrust: "",
  });
  const [submit, setSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const getData = () => {
    loader(true);
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        //console.log("User data:", res.data);
        setData(res.data);
        setForm({
          image: res.data.image || "",
          firstName: res.data.firstName || "",
          lastName: res.data.lastName || "",
          email: res.data.email || "",
          dialCode: res.data.dialCode || "",
          mobileNo: res.data.mobileNo || "",
          whatILoveDoing: res.data.whatILoveDoing || "",
          howToCommunicate: res.data.howToCommunicate || "",
          whatMattersMost: res.data.whatMattersMost || "",
          whatCanFrustrate: res.data.whatCanFrustrate || "",
          somethingAboutMe: res.data.somethingAboutMe || "",
          defaultTrustLevel: res.data.defaultTrustLevel || "",
          howToGainTrust: res.data.howToGainTrust || "",
          howToLoseTrust: res.data.howToLoseTrust || "",
        });
        setPreviewLogo(res.data.companyLogo || "");
        setPreviewImage(res.data.image || "");
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user && user.loggedIn && !dataFetched) {
      // console.log("Fetching  for user:", user.workspaceIds);

      if (user.role === "company") {
        setCurrentStep(1);

        const workspaceIds = Array.isArray(user.workspaceIds)
          ? user.workspaceIds
          : user.workspaceIds
          ? [user.workspaceIds]
          : [];

        const validWorkspaceId =
          workspaceIds.length > 0 ? workspaceIds[0] : null;

        if (validWorkspaceId) {
          setWorkspaceId(validWorkspaceId); // Set the extracted workspaceId
          getWorkspaceDetails(validWorkspaceId); // Fetch workspace details from the database
          setDataFetched(true);
        } else {
          // If no workspaceId exists, fetch data from the AI API
          const domainParts = user.email.split("@");
          if (domainParts.length === 2) {
            const domain = domainParts[1];
            const companyUrl = `https://${domain}`;
            const logoUrl = `https://img.logo.dev/${domain}`;

            setForm((prevForm) => ({
              ...prevForm,
              companyName: domain
                .split(".")[0]
                .replace(/-/g, " ")
                .replace(/\b\w/g, (c) => c.toUpperCase()),
              companyLogo: logoUrl,
              companyUrl: companyUrl,
            }));
            // console.log("companyUrl set to:", companyUrl),
            setPreviewLogo(logoUrl);

            fetchCompanyData(companyUrl); // Fetch company data from the AI API
            setDataFetched(true); // Mark data as fetched
          } else {
          }
        }
      } else {
        setCurrentStep(2);
      }

      // Fetch user data
      getData();
    }
  }, [user, workspaceId, dataFetched]);

  const fetchCompanyData = async (companyUrl) => {
    try {
      loader(true);

      const workspaceIds = Array.isArray(user.workspaceIds)
        ? user.workspaceIds
        : user.workspaceIds
        ? [user.workspaceIds] // Convert string to array if it's not already an array
        : [];

      if (workspaceIds.length > 0) {
        // console.log("Workspace ID exists.", workspaceIds);
        return; // Skip fetching AI data if workspaceIds array is not empty
      }

      if (dataFetched) {
        // console.log("API call prevented. dataFetched is already true.");
        return; // Prevent multiple calls
      }

      // console.log("Making API call to fetch company data...");
      setIsLoading(true);

      const response = await ApiClient.post("workspaces/ai", {
        url: companyUrl, // Send the company URL to the backend
      });

      if (response.success && response.data) {
        const companyData = response.data; // Assuming the backend returns the JSON object directly

        // Save AI-generated data in both form and originalData
        setOriginalData(companyData);

        setForm((prevForm) => {
          const domainParts = companyUrl.split("//")[1]; // Extract domain from companyUrl
          const logoUrl = `https://img.logo.dev/${domainParts}`; // Always use img.logo.dev

          const updatedForm = {
            ...prevForm,
            companyName: companyData.companyName || prevForm.companyName,
            companyLogo: logoUrl, // Always use img.logo.dev
            companyUrl: companyUrl,
            foundedYear: companyData.foundedYear || 0,
            industry: companyData.industry || "",
            about: companyData.about || "",
            mission: companyData.mission || "",
            vision: companyData.vision || "",
            values: companyData.values || "",
            employeeCount: companyData.employeeCount || 0,
            annualRevenue: companyData.annualRevenue || 0,
            departments: companyData.departments || "",
            strategicPriorities: companyData.strategicPriorities || "",
            productsServices: companyData.productsServices || "",
            keyMetrics: companyData.keyMetrics || "",
            //linkedinUrl: companyData.linkedinUrl || "",
          };

          // console.log("Updated form after fetching company data:", updatedForm); // Debugging log
          return updatedForm;
        });

        // console.log("Company data fetched successfully:", companyData);
        setDataFetched(true); // Mark data as fetched
        // console.log("dataFetched set to true after API call.");
      } else {
        // console.error("Failed to fetch company data:", response.message);
        toast.error(response.message || "Failed to fetch company data");
      }
    } catch (error) {
      // console.error("Error fetching company data:", error);
      toast.error("Error fetching company data");
    } finally {
      loader(false);
    }
  };

  const getWorkspaceDetails = (workspaceId) => {
    loader(true);
    ApiClient.get(`workspace/details/${workspaceId}`).then((res) => {
      if (res.success) {
        const workspace = res.data;
        setOriginalData(workspace);
        // console.log("Original data:", workspace);

        setForm({
          ...form,
          companyName: workspace.companyName,
          companyLogo: workspace.companyLogo,
          companyUrl: workspace.companyUrl,
          foundedYear: workspace.foundedYear,
          industry: workspace.industry,
          about: workspace.about,
          mission: workspace.mission,
          vision: workspace.vision,
          values: workspace.values,
          employeeCount: workspace.employeeCount,
          annualRevenue: workspace.annualRevenue,
          departments: workspace.departments,
          strategicPriorities: workspace.strategicPriorities,
          productsServices: workspace.productsServices,
          keyMetrics: workspace.keyMetrics,
          linkedinUrl: workspace.linkedinUrl,
        });
        setPreviewLogo(workspace.companyLogo);
      }
      loader(false);
    });
  };

  const handleRevertToAI = () => {
    if (originalData) {
      setForm((prevForm) => ({
        ...prevForm,
        ...originalData,
        companyLogo: prevForm.companyLogo,
      }));
      toast.success("Reverted to AI-generated data");
    } else {
      toast.error("No AI-generated data to revert to");
    }
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", {
      file: file,
      modelName: "users",
    })
      .then((res) => {
        if (res.success) {
          let image = res.data.fullpath;
          setForm({ ...form, image: image });
          setPreviewImage(image);
        } else {
          setForm({ ...form, image: "" });
          setPreviewImage(null); // Clear the preview image
        }
        loader(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 413) {
        } else {
          toast.error("File size too big please upload a smaller file");
        }
        loader(false);
      });
  };

  const uploadWorkspaceImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=workspaces", {
      file: file,
      modelName: "workspaces",
    })
      .then((res) => {
        if (res.success) {
          let image = res.data.fullpath;
          setForm({ ...form, companyLogo: image });
          setPreviewLogo(image);
        } else {
          setForm({ ...form, companyLogo: "" });
          setPreviewLogo(null); // Clear the preview image
        }
        loader(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 413) {
          toast.error("File size too big please upload a smaller file");
        } else {
          toast.error("Failed to upload image");
        }
        loader(false);
      });
  };

  const handleCompanySubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (user.role !== "company") {
      toast.error("Only company users can update company information.");
      setSubmit(false);
      return;
    }

    const payload = {
      companyName: form.companyName,
      companyLogo: form.companyLogo,
      companyUrl: form.companyUrl,
      foundedYear: form.foundedYear,
      industry: form.industry,
      about: form.about,
      mission: form.mission,
      vision: form.vision,
      values: form.values,
      employeeCount: form.employeeCount,
      annualRevenue: form.annualRevenue,
      departments: form.departments,
      strategicPriorities: form.strategicPriorities,
      productsServices: form.productsServices,
      keyMetrics: form.keyMetrics,
      linkedinUrl: form.linkedinUrl,
    };

    loader(true);
    const apiCall = workspaceId
      ? ApiClient.put(`edit/workspace?id=${workspaceId}`, payload)
      : ApiClient.post("add/workspace", payload);

    apiCall
      .then((res) => {
        if (res.success) {
          toast.success("Company information saved successfully");
          const workspace = res.data;
          const newWorkspaceId = workspace.id;
          setWorkspaceId(newWorkspaceId);

          // Immediately update the user's profile with the new workspaceId
          const profilePayload = {
            id: user.id,
            workspaceIds: [newWorkspaceId], // Update the user's workspaceIds
          };

          ApiClient.put("edit/profile", profilePayload)
            .then((profileRes) => {
              if (profileRes.success) {
                toast.success("Workspace linked to user profile successfully");
                setCurrentStep(2); // Move to the next step
              } else {
                toast.error(
                  profileRes.message || "Failed to link workspace to profile"
                );
              }
            })
            .catch((error) => {
              toast.error("Failed to link workspace to profile");
            })
            .finally(() => {
              loader(false);
            });
        } else if (res.status === 409) {
          toast.error("A workspace with this company name already exists");
        } else {
          toast.error(res.message || "Failed to create workspace");
        }
      })
      .catch((error) => {
        toast.error("Failed to create workspace");
      })
      .finally(() => {
        loader(false);
      });
  };

  const handlePersonalSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    // Basic validation
    if (!form.firstName || !form.lastName || !form.email) {
      toast.error("Please fill in all required fields");
      setSubmit(false);
      loader(false);
      return;
    }

    let payload = {
      id: user.id,
      firstName: form.firstName,
      lastName: form.lastName,
      dialCode: form.dialCode,
      mobileNo: form.mobileNo,
      email: form.email,
      whatILoveDoing: form.whatILoveDoing,
      howToCommunicate: form.howToCommunicate,
      whatMattersMost: form.whatMattersMost,
      whatCanFrustrate: form.whatCanFrustrate,
      somethingAboutMe: form.somethingAboutMe,
      defaultTrustLevel: form.defaultTrustLevel
        ? Number(form.defaultTrustLevel)
        : null, // Ensure it's a number
      howToGainTrust: form.howToGainTrust,
      howToLoseTrust: form.howToLoseTrust,
      image: form.image,
      onboardingDone: true,
    };

    loader(true);
    ApiClient.put("edit/profile", payload)
      .then((res) => {
        if (res.success) {
          toast.success("Onboarding completed successfully");
          history.push("/dashboard"); // Redirect to dashboard
        } else {
          toast.error(res.message || "Failed to complete onboarding");
        }
      })
      .catch((error) => {
        toast.error("Failed to complete onboarding");
      })
      .finally(() => {
        loader(false);
        setSubmit(false);
      });
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (user.role === "employee") {
      return;
    }
    setForm((prevForm) => ({
      ...prevForm,
      ...form,
    }));
    setCurrentStep(currentStep - 1);
  };

  const handleSkipOnboarding = () => {
    ApiClient.put("edit/profile", {
      id: user.id,
      onboardingDone: true,
    })
      .then((res) => {
        if (res.success) {
          toast.success("Onboarding skipped successfully");
          history.push("/dashboard");
        } else {
          toast.error(res.message || "Failed to skip onboarding");
        }
      })
      .catch((error) => {
        toast.error("Failed to skip onboarding");
      });
  };

  return (
    <Layout>
      <div className="container mt-3">
        <div className="onboarding-progress">
          <ProgressBar
            now={(currentStep / 2) * 100}
            label={`${currentStep}/2`}
            className="mb-4"
          />
        </div>
        <h2>{translate("onboarding")}</h2>

        {currentStep === 1 && user.role === "company" && (
          <form onSubmit={handleCompanySubmit}>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-secondary mt-3"
                onClick={handleRevertToAI}
              >
                Revert to AI-Generated Data
              </button>
            </div>

            <div className="form-group">
              <label>{translate("company_name")}</label>
              <input
                type="text"
                name="companyName"
                value={form.companyName}
                onChange={handleInputChange}
                className="form-control"
              />
              {submit && !form.companyName && (
                <p className="text-danger">
                  {translate("company_name_required")}
                </p>
              )}
            </div>

            <div className="form-group">
              <label>{translate("company_url")}</label>
              <input
                type="text"
                name="companyUrl"
                value={form.companyUrl}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>{translate("founded_year")}</label>
              <input
                type="number"
                name="foundedYear"
                value={form.foundedYear}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("industry")}</label>
              <input
                type="text"
                name="industry"
                value={form.industry}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("about")}</label>
              <input
                type="text"
                name="about"
                value={form.about}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("mission")}</label>
              <input
                type="text"
                name="mission"
                value={form.mission}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("vision")}</label>
              <input
                type="text"
                name="vision"
                value={form.vision}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("values")}</label>
              <input
                type="text"
                name="values"
                value={form.values}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("employee_count")}</label>
              <input
                type="number"
                name="employeeCount"
                value={form.employeeCount}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("annual_revenue")}</label>
              <input
                type="number"
                name="annualRevenue"
                value={form.annualRevenue}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("departments")}</label>
              <input
                type="text"
                name="departments"
                value={form.departments}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("strategic_priorities")}</label>
              <input
                type="text"
                name="strategicPriorities"
                value={form.strategicPriorities}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("products_services")}</label>
              <input
                type="text"
                name="productsServices"
                value={form.productsServices}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("key_metrics")}</label>
              <input
                type="text"
                name="keyMetrics"
                value={form.keyMetrics}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{translate("linkedin_url")}</label>
              <input
                type="text"
                name="linkedinUrl"
                value={form.linkedinUrl}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <button type="submit" className="btn btn-primary mt-3">
              {translate("next")}
            </button>

            <button
              onClick={handleNextStep}
              className="btn btn-secondary mt-3 ms-2"
            >
              {translate("skip")}
            </button>
          </form>
        )}
        {currentStep === 2 && (
          <div className="inners_wrapper main-view-page">
            <div className="container">
              <div className="row rounded">
                <div className="col-lg-8 rounded">
                  <form
                    onSubmit={handlePersonalSubmit}
                    className="border rounded"
                  >
                    <div className="bg-white rounded-top">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between head-view">
                            <div className="d-flex align-items-center">
                              <label className="position-relative labeledit edit-profile-view mb-0">
                                <img
                                  src={
                                    previewImage
                                      ? methodModel.userImg(previewImage)
                                      : ""
                                  }
                                  width=""
                                  className="pointer"
                                />
                                <input
                                  id="bannerImage"
                                  type="file"
                                  className="d-none"
                                  accept="image/*"
                                  value={form.baseImg ? form.baseImg : ""}
                                  onChange={(e) => {
                                    uploadImage(e);
                                  }}
                                />
                                <Tooltip
                                  placement="top"
                                  title={translate("upload_image")}
                                >
                                  <i className="fa fa-pen pointer"></i>
                                </Tooltip>
                              </label>
                              <span className="ml-3">
                                <div className="d-flex justify-content-between">
                                  <div className="input-field-new">
                                    <input
                                      type="text"
                                      className="form-control field-change"
                                      name="firstName"
                                      value={form.firstName}
                                      onChange={handleInputChange}
                                      required
                                      placeholder={translate("first_name")}
                                    />
                                    {submit && !form.firstName && (
                                      <p className="text-danger">
                                        {translate("first_name_required")}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {console.log("form", form)}{" "}
                    <div className="bg-white">
                      <div className="row description-detail team-detail-view">
                        <div className="row border rounded-3 body good-center mb-2">
                          <div className="col-md-3 my-2">
                            <label>{translate("last_name")}</label>
                          </div>
                          <div className="col-md-9 my-2">
                            <input
                              type="text"
                              name="lastName"
                              value={form.lastName}
                              onChange={handleInputChange}
                              className="form-control"
                              required
                            />
                            {submit && !form.lastName && (
                              <p className="text-danger">
                                {translate("last_name_required")}
                              </p>
                            )}
                          </div>

                          <div className="col-md-3 my-2">
                            <label>{translate("email")}</label>
                          </div>
                          <div className="col-md-9 my-2">
                            <input
                              type="email"
                              name="email"
                              value={form.email}
                              onChange={handleInputChange}
                              className="form-control"
                              required
                            />
                            {submit && !form.email && (
                              <p className="text-danger">
                                {translate("email_required")}
                              </p>
                            )}
                          </div>

                          <div className="col-md-3 my-2">
                            <label>
                              {translate("mobile_no")}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-9 my-2">
                            <div className="row">
                              <div className="col-md-3">
                                {/* <PhoneInput
                                  country={"fr"}
                                  required
                                  enableSearch={true}
                                  placeholder={translate("enter_phone_number")}
                                  value={form.dialCode}
                                  onKeyDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  countryCodeEditable={false}
                                  onChange={(e) =>
                                    setForm({ ...form, dialCode: e })
                                  }
                                /> */}
                              </div>
                              <div className="col-md-9">
                                <div className="phoneInput">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={translate("mobile_no")}
                                    value={(form && form.mobileNo) || ""}
                                    maxLength={12}
                                    onChange={(e) =>
                                      setForm({
                                        ...form,
                                        mobileNo: methodModel.isNumber(e),
                                      })
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="body p-4 border rounded-3">
                          <p className="linetext">
                            {translate("likesDislikes")}
                          </p>
                          <hr className="hremp" size="1" />

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("what_i_love_doing")}
                              </label>
                            </div>
                            <div className="form-group">
                              <textarea
                                value={form.whatILoveDoing}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.whatILoveDoing = e.target.value;
                                  setForm(updatedForm);
                                  e.target.style.height = "auto";
                                  e.target.style.height =
                                    e.target.scrollHeight + "px";
                                }}
                                className="form-control auto-expand"
                                placeholder="Describe what you enjoy doing effortlessly, what keeps you motivated every day."
                                required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("how_to_communicate")}
                              </label>
                            </div>
                            <div className="form-group">
                              <textarea
                                value={form.howToCommunicate}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.howToCommunicate = e.target.value;
                                  setForm(updatedForm);
                                  e.target.style.height = "auto";
                                  e.target.style.height =
                                    e.target.scrollHeight + "px";
                                }}
                                className="form-control auto-expand"
                                placeholder="Explain how you prefer people to communicate with you for smooth collaboration."
                                required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("what_matters_most")}
                              </label>
                            </div>
                            <div className="form-group">
                              <textarea
                                value={form.whatMattersMost}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.whatMattersMost = e.target.value;
                                  setForm(updatedForm);
                                  e.target.style.height = "auto";
                                  e.target.style.height =
                                    e.target.scrollHeight + "px";
                                }}
                                className="form-control auto-expand"
                                placeholder="Share the core values that are most important to you at work."
                                required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("what_can_frustrate")}
                              </label>
                            </div>
                            <div className="form-group">
                              <textarea
                                value={form.whatCanFrustrate}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.whatCanFrustrate = e.target.value;
                                  setForm(updatedForm);
                                  e.target.style.height = "auto";
                                  e.target.style.height =
                                    e.target.scrollHeight + "px";
                                }}
                                className="form-control auto-expand"
                                placeholder="Describe the behaviors or situations that could make collaboration difficult for you."
                                required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("something_about_me")}
                              </label>
                            </div>
                            <div className="form-group">
                              <textarea
                                value={form.somethingAboutMe}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.somethingAboutMe = e.target.value;
                                  setForm(updatedForm);
                                  e.target.style.height = "auto";
                                  e.target.style.height =
                                    e.target.scrollHeight + "px";
                                }}
                                className="form-control auto-expand"
                                placeholder="Add a unique detail about yourself that helps others understand you better."
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="linetext mt-3">
                          {translate("trustBattery")}
                        </div>
                        <span className="small-label">
                          {translate("sustainTrust")}
                        </span>
                        <hr className="hremp" size="1" />
                        <div className="body p-4 border rounded-3">
                          <div className="text-center">
                            <div className="">
                              <label className="fw">
                                {translate("trustLevel")}
                              </label>
                            </div>
                            <div className="col-md-9 mx-auto">
                              <input
                                type="range"
                                min="0"
                                max="100"
                                value={form.defaultTrustLevel}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.defaultTrustLevel =
                                    e.target.value;
                                  setForm(updatedForm);
                                }}
                                className="trust-range"
                                required
                              />
                              <div className="battery">
                                <div
                                  className="battery-bar"
                                  role="progressbar"
                                  data-level={
                                    form.defaultTrustLevel <= 30
                                      ? "low"
                                      : form.defaultTrustLevel <= 70
                                      ? "medium"
                                      : "high"
                                  }
                                  style={{
                                    width: `${form.defaultTrustLevel}%`,
                                  }}
                                  aria-valuenow={form.defaultTrustLevel}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {form.defaultTrustLevel}%
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 row">
                            <span className="small-label mb-3 text-start col-6">
                              {translate("startTrust")}
                            </span>
                            <span className="small-label mb-3 text-end col-6">
                              {translate("trustUntilProvenOtherwise")}
                            </span>
                          </div>

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("how_to_gain_trust")}
                              </label>
                              <span className="small-label">
                                {translate("refillBattery")}
                              </span>
                            </div>
                            <div className="form-group">
                              <textarea
                                value={form.howToGainTrust}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.howToGainTrust = e.target.value;
                                  setForm(updatedForm);
                                  e.target.style.height = "auto";
                                  e.target.style.height =
                                    e.target.scrollHeight + "px";
                                }}
                                className="form-control auto-expand"
                                placeholder="Describe what makes you trust someone more easily."
                                required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("how_to_lose_trust")}
                              </label>
                              <span className="small-label">
                                {translate("drainBattery")}
                              </span>
                            </div>
                            <div className="form-group">
                              <textarea
                                value={form.howToLoseTrust}
                                onChange={(e) => {
                                  const updatedForm = { ...form };
                                  updatedForm.howToLoseTrust = e.target.value;
                                  setForm(updatedForm);
                                  e.target.style.height = "auto";
                                  e.target.style.height =
                                    e.target.scrollHeight + "px";
                                }}
                                className="form-control auto-expand"
                                placeholder="Explain the behaviors that would make you lose trust in someone."
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 d-flex justify-content-end mt-4">
                          {user.role === "company" && (
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={handlePreviousStep}
                            >
                              {translate("previous")}
                            </button>
                          )}
                          <button
                            type="submit"
                            className="btn btn-primary mx-3"
                          >
                            {translate("complete_onboarding")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary mx-3"
                            onClick={handleSkipOnboarding}
                          >
                            {translate("skip")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Onboarding;
