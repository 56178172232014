import { Row, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Select from "react-select";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

const getBrowserLocale = () => {
  const language = navigator.language.toLowerCase();
  if (language.includes("fr")) {
    return LOCALES.FRENCH;
  }
  return LOCALES.ENGLISH;
};

const locale = getBrowserLocale();

const EmployeeDetail = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [detail, setdetail] = useState();
  const [managers, setmanagers] = useState();
  const [form, setform] = useState({ edit: false, teamId: "" });
  const navigator = useHistory();
  const [teams, setteams] = useState();

  useEffect(() => {
    if (id) {
      getDetail();
    }
    getManagers();
  }, []);

  // karm code
  useEffect(() => {
    getTeam();
  }, []);

  const getManagers = () => {
    ApiClient.get(
      `users/list?status=active&addedBy=${user?.id || user?._id}`
    ).then((res) => {
      if (res.success) {
        setmanagers(
          res?.data?.map((item) => {
            return { value: item?.id || item?._id, label: item?.fullName };
          })
        );
      }
    });
  };

  // karm code
  const getTeam = () => {
    ApiClient.get(`teams?addedBy=${user?.id}`).then((res) => {
      if (res.success) {
        setteams(
          res?.data?.map((item) => {
            return {
              value: item?.id || item?._id,
              id: item?.id || item?._id,
              label: item?.fullName || "",
              name: item?.fullName,
              managerName: item?.managerEmail,
            };
            // **********************
          })
        );
      }
    });
  };
  const getDetail = () => {
    loader(true);
    ApiClient.get(`user/details?id=${id}`).then((res) => {
      if (res.success) {
        setdetail(res?.data);
        setform({
          fullName: res?.data?.fullName,
          email: res?.data?.email,
          managerId: res?.data?.managerAndTeam?.map((item) => {
            return {
              value: item?.id,
              label: item?.fullName,
              email: item?.email,
            };
          }),
          teamId: res?.data?.managerAndTeam?.map((item) => {
            return {
              id: item?.team?.id,
              value: item?.team?.id,
              label: item?.team?.fullName,
              managerName: item?.fullName,
            };
          }),
          whatILoveDoing: res?.data?.whatILoveDoing,
          howToCommunicate: res?.data?.howToCommunicate || "",
          whatMattersMost: res?.data?.whatMattersMost || "",
          whatCanFrustrate: res?.data?.whatCanFrustrate || "",
          somethingAboutMe: res?.data?.somethingAboutMe || "",
          defaultTrustLevel: res?.data?.defaultTrustLevel || 50,
          howToGainTrust: res?.data?.howToGainTrust || "",
          howToLoseTrust: res?.data?.howToLoseTrust || "",
          image: res?.data?.image,
        });
      }
      loader(false);
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let payload = {
      id: detail?.id,
      fullName: form?.fullName,
      email: form?.email,
      teamId: form?.teamId?.map((item) => {
        return item?.id;
      }),
      whatILoveDoing: form?.whatILoveDoing,
      howToCommunicate: form?.howToCommunicate,
      whatMattersMost: form?.whatMattersMost,
      whatCanFrustrate: form?.whatCanFrustrate,
      somethingAboutMe: form?.somethingAboutMe,
      defaultTrustLevel: form?.defaultTrustLevel,
      howToGainTrust: form?.howToGainTrust,
      howToLoseTrust: form?.howToLoseTrust,
    };
    ApiClient.put(`edit/profile`, payload).then((res) => {
      if (res.success) {
        getDetail();
        toast.success("Employee Updated Successfully");
        setform({ edit: false });
      }
    });
  };

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this employee?")) {
      ApiClient.delete(`user/delete?id=${id}`).then((res) => {
        if (res.success) {
          setTimeout(() => {
            toast.success(res.message);
          }, 100);
          navigator.push(`/employees`);
        }
      });
    }
  };

  const handleStatus = (id) => {
    let status = detail?.status == "active" ? "deactive" : "active";
    if (
      window.confirm(
        "Do you really want to change the status of this employee?"
      )
    ) {
      ApiClient.put(`change/status?model=users&id=${id}&status=${status}`).then(
        (res) => {
          if (res.success) {
            toast.success(res?.message);
            getDetail();
          }
        }
      );
    }
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", {
      file: file,
      modelName: "users",
    }).then((res) => {
      if (res.success) {
        let image = res.data.fullpath;
        setform({ ...form, image: image });
      } else {
        setform({ ...form, image: "" });
      }
      loader(false);
    });
  };

  const handleCancel = () => {
    setform({ edit: false });
    getDetail();
  };

  return (
    <>
      <I18nProvider locale={locale}>
        <Layout>
          <div className="inners_wrapper main-view-page">
            <div className="container">
              <div className="row rounded">
                <div className="col-lg-8 rounded">
                  <form onSubmit={handleUpdate} className="border rounded">
                    <div className="bg-white rounded-top">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between head-view">
                            <div className="d-flex align-items-center">
                              <label className="position-relative labeledit edit-profile-view mb-0">
                                <img
                                  src={methodModel.userImg(form?.image)}
                                  width=""
                                  className={form?.edit ? "pointer" : ""}
                                />
                                {form?.edit ? (
                                  <>
                                    <input
                                      id="bannerImage"
                                      type="file"
                                      className="d-none"
                                      accept="image/*"
                                      value={form.baseImg ? form.baseImg : ""}
                                      onChange={(e) => {
                                        uploadImage(e);
                                      }}
                                    />
                                    <Tooltip
                                      placement="top"
                                      title="Upload Image"
                                    >
                                      <i className="fa fa-pen pointer"></i>
                                    </Tooltip>
                                  </>
                                ) : null}
                              </label>
                              <span className="ml-3">
                                <div className="d-flex  justify-content-between ">
                                  {!form?.edit ? (
                                    <>
                                      <h3 className="team-head">
                                        {detail?.fullName}
                                      </h3>
                                    </>
                                  ) : (
                                    <div className="input-field-new">
                                      <input
                                        description-detail
                                        team-detail-view
                                        type="text"
                                        className="form-control field-change"
                                        value={form?.fullName}
                                        onChange={(e) =>
                                          setform({
                                            ...form,
                                            fullName: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </div>
                                  )}
                                </div>
                                <p className="creation-date">
                                  {translate("createdOn")}{" "}
                                  {moment(detail?.createdAt).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </p>
                              </span>
                            </div>
                            <p>
                              <span
                                className={`status-pastille ${
                                  detail?.status === "deactive"
                                    ? "red"
                                    : "green"
                                }`}
                                title={`status: ${
                                  detail?.status === "deactive"
                                    ? "inactive"
                                    : "active"
                                }`}
                              ></span>
                            </p>
                            {user?.role != "employee" && (
                              <div className="dropdowns_custom ">
                                <div className="dropdown">
                                  <span
                                    className=" text-dark p-2"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i
                                      className="fa fa-ellipsis-h text-dark"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <div className="dropdown-menu">
                                    <span
                                      className=" dropdown-item pointer"
                                      onClick={(e) =>
                                        setform({ ...form, edit: true })
                                      }
                                    >
                                      <i className="fa fa-pen me-2"></i>{" "}
                                      {translate("edit")}
                                    </span>
                                    <span
                                      className="dropdown-item pointer"
                                      onClick={(e) => handleStatus(detail?.id)}
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-users me-2"
                                        aria-hidden="true"
                                      ></i>
                                      {locale === "en"
                                        ? detail?.status === "active"
                                          ? "Deactivate"
                                          : "Activate"
                                        : detail?.status === "active"
                                        ? "Désactiver"
                                        : "Activer"}{" "}
                                      {translate("employee")}
                                    </span>
                                    <span
                                      className="dropdown-item text-danger pointer"
                                      onClick={(e) => handleDelete(detail?.id)}
                                    >
                                      <i className="fa fa-trash me-2"></i>
                                      {translate("deleteEmployee")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white">
                      <div className="row description-detail team-detail-view ">
                        <div className="row border rounded-3 body good-center mb-2">
                          <div className="col-md-3 my-2">
                            <label>{translate("email")}</label>
                          </div>

                          <div className="col-md-9 my-2">
                            {!form?.edit ? (
                              <p className="mb-0 clean">{detail?.email}</p>
                            ) : (
                              <input
                                type="email"
                                value={form?.email}
                                onChange={(e) =>
                                  setform({ ...form, email: e.target.value })
                                }
                                className="form-control"
                                placeholder="Email"
                                required
                              />
                            )}
                          </div>
                          <div className="col-md-3 my-2">
                            <label>{translate("manager")}</label>
                          </div>
                          <div className="col-md-9 my-2">
                            {!form?.edit ? (
                              <p className="mb-0 clean">
                                {detail?.managerAndTeam
                                  .map((itm) => itm.fullName)
                                  .join(", ")}
                              </p>
                            ) : (
                              <div className="multiselect-custom multiselect-new">
                                <p className=" border rounded p-1 d-flex flex-wrap">
                                  {form?.teamId?.length > 0 ? (
                                    form?.teamId?.map((itm) => {
                                      return (
                                        <span className="mr-2 my-1 border rounded p-1 ">
                                          {itm?.managerName || "--"}
                                        </span>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </div>
                              // <Select
                              //   value={form?.managerId}
                              //   isClearable={true}
                              //   onChange={(e) =>{

                              //     setform({ ...form, managerId: e })}}
                              //   options={managers}
                              //   placeholder="Select Manager"
                              //   isDisabled={true}
                              // />
                            )}
                          </div>
                          {/* Karm code */}
                          <div className="col-md-3 my-2">
                            {!form?.edit ? (
                              <label>{translate("team")}</label>
                            ) : (
                              <label>
                                {translate("team")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                            )}
                          </div>

                          <div className="col-md-9 my-2">
                            {!form?.edit ? (
                              <p className="mb-0 clean">
                                {form?.teamId &&
                                  form?.teamId
                                    ?.map((itm) => itm?.label)
                                    .join(", ")}
                              </p>
                            ) : (
                              <div className="multiselect-custom">
                                <Multiselect
                                  avoidHighlightFirstOption={true}
                                  selectedValues={form?.teamId}
                                  options={teams}
                                  displayValue={"label"}
                                  onSelect={(e) =>
                                    setform({ ...form, teamId: e })
                                  }
                                  onRemove={(e) =>
                                    setform({ ...form, teamId: e })
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        {/* -------- */}
                        {/* {!form?.edit ? (
                      <>
                        <div className="col-md-3 my-2">
                          <label>Team</label>
                        </div>
                        <div className="col-md-9 my-2">
                          <p className="mb-0 clean">{detail?.managerAndTeam.map(itm=>(itm.teamName))}</p>
                        </div>
                      </>
                    ) : null} */}
                        <div className="body p-4 border rounded-3">
                          <p className="linetext">
                            {translate("likesDislikes")}
                          </p>
                          <hr className="hremp" size="1" />
                          <div className="row">
                            {/* What I love doing effortlessly */}
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("whatILoveDoing")}
                              </label>
                            </div>
                            <div className="form-group">
                              {!form?.edit ? (
                                <textarea
                                  className="form-control auto-expand"
                                  value={form?.whatILoveDoing}
                                  readOnly
                                />
                              ) : (
                                <textarea
                                  value={form?.whatILoveDoing}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      whatILoveDoing: e.target.value,
                                    });
                                    e.target.style.height = "auto";
                                    e.target.style.height =
                                      e.target.scrollHeight + "px";
                                  }}
                                  className="form-control auto-expand"
                                  placeholder="Describe what you enjoy doing effortlessly, what keeps you motivated every day."
                                />
                              )}
                            </div>
                          </div>

                          <div className="row">
                            {/* How to communicate with me */}
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("howToCommunicate")}
                              </label>
                            </div>
                            <div className="form-group">
                              {!form?.edit ? (
                                <textarea
                                  className="form-control auto-expand"
                                  value={form?.howToCommunicate || ""}
                                  readOnly
                                />
                              ) : (
                                <textarea
                                  value={form?.howToCommunicate}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      howToCommunicate: e.target.value,
                                    });
                                    e.target.style.height = "auto";
                                    e.target.style.height =
                                      e.target.scrollHeight + "px";
                                  }}
                                  className="form-control auto-expand"
                                  placeholder="Explain how you prefer people to communicate with you for smooth collaboration."
                                />
                              )}
                            </div>
                          </div>

                          <div className="row">
                            {/* What matters most to me at work */}
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("whatMattersMost")}
                              </label>
                            </div>
                            <div className="form-group">
                              {!form?.edit ? (
                                <textarea
                                  readOnly
                                  className="form-control auto-expand"
                                  value={form?.whatMattersMost}
                                />
                              ) : (
                                <textarea
                                  value={form?.whatMattersMost}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      whatMattersMost: e.target.value,
                                    });
                                    e.target.style.height = "auto";
                                    e.target.style.height =
                                      e.target.scrollHeight + "px";
                                  }}
                                  className="form-control auto-expand"
                                  placeholder="Share the core values that are most important to you at work."
                                />
                              )}
                            </div>
                          </div>

                          <div className="row">
                            {/* What can frustrate or block me */}
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("whatCanFrustrate")}
                              </label>
                            </div>
                            <div className="form-group">
                              {!form?.edit ? (
                                <textarea
                                  readOnly
                                  className="form-control auto-expand"
                                  value={form?.whatCanFrustrate}
                                ></textarea>
                              ) : (
                                <textarea
                                  value={form?.whatCanFrustrate}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      whatCanFrustrate: e.target.value,
                                    });
                                    e.target.style.height = "auto";
                                    e.target.style.height =
                                      e.target.scrollHeight + "px";
                                  }}
                                  className="form-control auto-expand"
                                  placeholder="Describe the behaviors or situations that could make collaboration difficult for you."
                                />
                              )}
                            </div>
                          </div>

                          <div className="row">
                            {/* Something you should know about me */}
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("somethingAboutMe")}
                              </label>
                            </div>
                            <div className="form-group">
                              {!form?.edit ? (
                                <textarea
                                  readOnly
                                  className="form-control auto-expand"
                                  value={form?.somethingAboutMe}
                                ></textarea>
                              ) : (
                                <textarea
                                  value={form?.somethingAboutMe}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      somethingAboutMe: e.target.value,
                                    });
                                    e.target.style.height = "auto";
                                    e.target.style.height =
                                      e.target.scrollHeight + "px";
                                  }}
                                  className="form-control auto-expand"
                                  placeholder="Add a unique detail about yourself that helps others understand you better."
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="linetext mt-3">
                          {translate("trustBattery")}
                        </div>
                        <span className="small-label">
                          {translate("sustainTrust")}
                        </span>
                        <hr className="hremp" size="1" />
                        <div className="body p-4 border rounded-3">
                          <div className="text-center">
                            <div className="">
                              <label className="fw">
                                {translate("trustLevel")}
                              </label>
                            </div>
                            <div className="col-md-9 mx-auto">
                              {!form?.edit ? (
                                <>
                                  <div className="battery">
                                    <div
                                      className="battery-bar"
                                      role="progressbar"
                                      data-level={
                                        form?.defaultTrustLevel <= 30
                                          ? "low"
                                          : form?.defaultTrustLevel <= 70
                                          ? "medium"
                                          : "high"
                                      }
                                      style={{
                                        width: `${form?.defaultTrustLevel}%`,
                                      }}
                                      aria-valuenow={form?.defaultTrustLevel}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {form?.defaultTrustLevel}%
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={form?.defaultTrustLevel}
                                    onChange={(e) =>
                                      setform({
                                        ...form,
                                        defaultTrustLevel: e.target.value,
                                      })
                                    }
                                    className="trust-range"
                                  />
                                  <div className="battery">
                                    <div
                                      className="battery-bar"
                                      role="progressbar"
                                      data-level={
                                        form?.defaultTrustLevel <= 30
                                          ? "low"
                                          : form?.defaultTrustLevel <= 70
                                          ? "medium"
                                          : "high"
                                      }
                                      style={{
                                        width: `${form?.defaultTrustLevel}%`,
                                      }}
                                      aria-valuenow={form?.defaultTrustLevel}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {form?.defaultTrustLevel}%
                                    </div>
                                  </div>
                                  <div className="mt-2 row">
                                    <span className="small-label mb-3">
                                      ↑ {translate("startTrust")}
                                      <span className="more-marginleft">
                                        {translate("trustUntilProvenOtherwise")}
                                        ↑
                                      </span>
                                    </span>
                                    {/*detail?.InfoNco?.[0]?.defaultTrustLevel < 50
                                    ? "Trust must be earned from scratch."
                                    : detail?.InfoNco?.[0]
                                        ?.defaultTrustLevel === 50
                                    ? "I trust people, but I need to see proof."
                                    : "I trust fully until proven otherwise."*/}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="mt-2 row">
                            <span className="small-label mb-3 text-start col-6">
                              {translate("startTrust")}
                            </span>
                            <span className="small-label mb-3 text-end col-6">
                              {translate("trustUntilProvenOtherwise")}
                            </span>
                            {/*detail?.InfoNco?.[0]?.defaultTrustLevel < 50
                                    ? "Trust must be earned from scratch."
                                    : detail?.InfoNco?.[0]
                                        ?.defaultTrustLevel === 50
                                    ? "I trust people, but I need to see proof."
                                    : "I trust fully until proven otherwise."*/}
                          </div>

                          <div className="row">
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("gainTrust")}
                              </label>
                              <span className="small-label">
                                {translate("refillBattery")}
                              </span>
                            </div>
                            <div className="form-group">
                              {!form?.edit ? (
                                <textarea
                                  readOnly
                                  className="form-control auto-expand"
                                  value={form?.howToGainTrust}
                                ></textarea>
                              ) : (
                                <textarea
                                  value={form?.howToGainTrust}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      howToGainTrust: e.target.value,
                                    });
                                    e.target.style.height = "auto"; // Reset height to auto
                                    e.target.style.height =
                                      e.target.scrollHeight + "px"; // Adjust height dynamically
                                  }}
                                  className="form-control auto-expand"
                                  placeholder="Describe what makes you trust someone more easily."
                                />
                              )}
                            </div>
                          </div>

                          <div className="row">
                            {/* How to lose my trust */}
                            <div className="row mb-2">
                              <label className="fw">
                                {translate("loseTrust")}
                              </label>
                              <span className="small-label">
                                {translate("drainBattery")}
                              </span>
                            </div>
                            <div className="form-group">
                              {" "}
                              {/* Changed from row-input to form-group */}
                              {!form?.edit ? (
                                <textarea
                                  readOnly
                                  className="form-control auto-expand"
                                  value={form?.howToLoseTrust}
                                ></textarea>
                              ) : (
                                <textarea
                                  value={form?.howToLoseTrust}
                                  onChange={(e) => {
                                    setform({
                                      ...form,
                                      howToLoseTrust: e.target.value,
                                    });
                                    e.target.style.height = "auto"; // Reset height
                                    e.target.style.height =
                                      e.target.scrollHeight + "px"; // Adjust dynamically
                                  }}
                                  className="form-control auto-expand"
                                  placeholder="Explain the behaviors that would make you lose trust in someone."
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        {form?.edit ? (
                          <div className="col-md-12 d-flex justify-content-end mt-4">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={(e) => handleCancel()}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary mx-3"
                            >
                              Update
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </I18nProvider>
    </>
  );
};

export default EmployeeDetail;
