import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import methodModel from "../../methods/methods";
import { logout } from "../../actions/user";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../../components/global/Footer";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";
import { useTranslation } from "react-i18next";
import "./KanamROI.scss";
import i18n from "../../i18n";

const About = () => {
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { username } = useParams();
  const [on, two] = useState(username);
  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    localStorage.removeItem("sessionID");
    localStorage.removeItem("sessionNAme");
    localStorage.removeItem("CreatedAt");
    navigate.push("/login");
  };
  const [data, setdata] = useState("");

  const [isOpen, setIsOpen] = useState({});
  const [selectedSection, setSelectedSection] = useState(null);

  const toggleAccordion = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const getBrowserLocale = () => {
    const language = navigator.language.toLowerCase();
    if (language.includes("fr")) {
      return LOCALES.FRENCH;
    }
    return LOCALES.ENGLISH;
  };

  const locale = getBrowserLocale();
  const { t } = useTranslation();

  const sections = [
    { key: "aboutMission", title: t("abouttitle.mission") },
    { key: "aboutOffer", title: t("abouttitle.offer") },
    { key: "aboutWhy", title: t("abouttitle.why") },
    { key: "aboutValues", title: t("abouttitle.values") },
    { key: "aboutJoinUs", title: t("abouttitle.joinUs") },
  ];

  useEffect(() => {
    calculateROI();
  }, []);

  const calculateROI = () => {
    let employees = parseInt(document.getElementById("employees").value);
    let salary = parseInt(document.getElementById("salary").value);
    let productivity = parseInt(document.getElementById("productivity").value);
    let seatCost = 9;

    let totalSalaryCost = (employees * salary) / 12;
    let roiGenerated =
      (employees * salary * (productivity / 100) - employees * seatCost) / 12;
    let resourcesCreated = (employees * (productivity / 100)).toFixed(2);
    let totalInvestment = employees * seatCost;
    let minROI = (roiGenerated / totalInvestment) * 100;

    document.getElementById(
      "total-salary-value"
    ).innerText = `$${totalSalaryCost.toLocaleString()}`;
    document.getElementById("roi-result-value").innerText = `$${Math.round(
      roiGenerated
    ).toLocaleString()}`;
    document.getElementById("resources-result-value").innerText = `${
      resourcesCreated >= 0 ? "+" : "-"
    }${Math.abs(resourcesCreated)}`;
    document.getElementById(
      "total-investment-value"
    ).innerText = `$${totalInvestment.toLocaleString()}`;
    document.getElementById("min-roi-value").innerText = `${Math.round(
      minROI
    )}%`;

    document.getElementById("employees-value").innerText =
      employees.toLocaleString();
    document.getElementById(
      "salary-value"
    ).innerText = `$${salary.toLocaleString()}`;
    document.getElementById(
      "productivity-value"
    ).innerText = `${productivity}%`;

    const feedback = document.getElementById("roi-feedback");
    feedback.style.opacity = 1;
    setTimeout(() => {
      feedback.style.opacity = 0;
    }, 2000);
  };
  const values = [
    {
      title: translate("title1"),
      frontText: translate("frontText1"),
      backText: translate("backText1"),
    },
    {
      title: translate("title2"),
      frontText: translate("frontText2"),
      backText: translate("backText2"),
    },
    {
      title: translate("title3"),
      frontText: translate("frontText3"),
      backText: translate("backText3"),
    },
    {
      title: translate("title4"),
      frontText: translate("frontText4"),
      backText: translate("backText4"),
    },
    {
      title: translate("title5"),
      frontText: translate("frontText5"),
      backText: translate("backText5"),
    },
    {
      title: translate("title6"),
      frontText: translate("frontText6"),
      backText: translate("backText6"),
    },
  ];

  const FlipCards = () => {};

  return (
    <div className="about-page">
      <I18nProvider locale={locale}>
        <section className="darkbg">
          <nav className="navbar navbar-expand-lg navbar-light homenavs px-5">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/home">
                <img
                  src="assets/img/logo-kanam-blue.png"
                  className="logo"
                  alt=""
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo03"
                aria-controls="navbarTogglerDemo03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo03"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navwhite">
                  <li className="nav-item">
                    <Link
                      to="/home"
                      className={
                        username === "/home" ? "nav-link active" : "nav-link"
                      }
                    >
                      {translate("product")}
                    </Link>
                  </li>
                  {!user?.isEmployee && (
                    <li className="nav-item">
                      <Link
                        to="/plan"
                        className={
                          username === "/plan" ? "nav-link active" : "nav-link"
                        }
                      >
                        {translate("pricing")}
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/aboutus"
                      className={
                        username === "/aboutus" ? "nav-link active" : "nav-link"
                      }
                    >
                      {translate("aboutUs")}
                    </Link>
                  </li>
                </ul>

                <form className="d-flex">
                  <button
                    className="btn-white-menu me-3"
                    onClick={(e) => {
                      window.location.href =
                        "https://outlook.office365.com/owa/calendar/DemoKanam@darewell.co/bookings/";
                    }}
                    type="button"
                  >
                    {translate("scheduleDemo")}
                  </button>
                </form>

                {token ? (
                  <div className="dropdown">
                    <a
                      data-toggle="dropdown"
                      className="nav-link dropdown-toggle profile-after nav-link-user text-dark d-flex align-items-center"
                    >
                      <img
                        alt="image"
                        src={methodModel.userImg(user.image)}
                        className="rounded-circle mr-1"
                        width="50px"
                      />
                      <div className="ml-1 nameFont pointer ">
                        <b className="text-light text-capitalize">
                          {user.companyName}
                        </b>
                        <p className="grayCls mb-0 text-capitalize">
                          {user.role?.name}
                        </p>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover">
                      <Link to="/profile" className="dropdown-item has-icon">
                        <i className="far fa-user" /> {translate("profile")}
                      </Link>
                      <Link
                        to="/profile?tab=change-pasword"
                        className="dropdown-item has-icon"
                      >
                        <i className="fa fa-cog" aria-hidden="true"></i>{" "}
                        {translate("changePassword")}
                      </Link>
                      <a
                        onClick={() => Logout()}
                        id="logoutBtn"
                        className="dropdown-item has-icon"
                      >
                        <i className="fas fa-sign-out-alt" />{" "}
                        {translate("logout")}
                      </a>
                    </div>
                  </div>
                ) : (
                  <form className="d-flex">
                    <button
                      className="btn-white-menu me-3"
                      onClick={(e) => {
                        navigate.push(`/login`);
                      }}
                      type="button"
                    >
                      {translate("login")}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </nav>
        </section>
        <div className="containerROI">
          <h1>{translate("aboutKanam")}</h1>
          <div className="sectionROI">
            <h2>{translate("unlockingExcellence")}</h2>
            <p>{translate("atKanam")}</p>
          </div>
          <div className="sectionROI">
            <h2>{translate("moreThanTool")}</h2>
            <div className="partner-section">
              <div className="partner-box">
                <h2>{translate("powerfulOKRPlatform")}</h2>
                <p>{translate("aiAssistedGoalSetting")}</p>
              </div>

              <div className="partner-box">
                <h2>{translate("expertGuidance")}</h2>
                <p>{translate("ourTeamHelps")}</p>
              </div>

              <div className="partner-box">
                <h2>{translate("fastTrackToResults")}</h2>
                <p>{translate("weWorkAlongside")}</p>
              </div>
            </div>
          </div>

          <div className="sectionROI">
            <h2>{translate("roiOfOKRs")}</h2>
            <div className="partner-section">
              <div className="partner-box">
                <h3>{translate("higherExecutionSpeed")}</h3>
                <p>{translate("even20Improvement")}</p>
              </div>

              <div className="partner-box">
                <h3>{translate("clearerpriorities")}</h3>
                <p>{translate("clearerdetail")}</p>
              </div>

              <div className="partner-box">
                <h3>{translate("engagement")}</h3>
                <p>{translate("engagementdetail")}</p>
              </div>
            </div>
          </div>
          <div className="sectionROI">
            <div className="roi-calculator">
              <h2>{translate("CalculateROI")}</h2>
              <div className="roi-input-row">
                <div className="roi-input-group">
                  <h4>{translate("Company")}</h4>
                  <div className="roi-input-field-container">
                    <div className="roi-input-field">
                      <label>{translate("TeamSize")}</label>
                      <input
                        type="range"
                        id="employees"
                        min="1"
                        max="1000"
                        defaultValue="50"
                        onInput={calculateROI}
                      />
                      <span id="employees-value">50</span>
                    </div>
                    <div className="roi-input-field">
                      <label>{translate("SalaryPer")}</label>
                      <input
                        type="range"
                        id="salary"
                        min="30000"
                        max="200000"
                        step="500"
                        defaultValue="62500"
                        onInput={calculateROI}
                      />
                      <span id="salary-value">$62,500</span>
                    </div>
                  </div>
                </div>
                <div className="roi-input-group" style={{ flex: 1 }}>
                  <h4>{translate("Project")}</h4>
                  <div className="roi-input-field">
                    <label>{translate("ProductivityIncrease")}</label>
                    <input
                      type="range"
                      id="productivity"
                      min="-50"
                      max="50"
                      defaultValue="10"
                      onInput={calculateROI}
                    />
                    <span id="productivity-value">10%</span>
                  </div>
                  <div className="okr-footnote">
                    {translate("IndustryInsight")}
                  </div>
                </div>
              </div>
              <div className="roi-output-row">
                <div className="roi-result" style={{ flex: 1 }}>
                  <span className="roi-result-label">
                    {translate("Payroll")}
                  </span>
                  <span className="roi-result-value" id="total-salary-value">
                    $218,750
                  </span>
                  <span className="roi-result-subtext">
                    {translate("employeesalaries")}
                  </span>
                </div>
                <div className="roi-result" style={{ flex: 1 }}>
                  <span className="roi-result-label">
                    {translate("Investment")}
                  </span>
                  <span
                    className="roi-result-value"
                    id="total-investment-value"
                  >
                    $375
                  </span>
                  <span className="roi-result-subtext">
                    {translate("InvestmentDetail")}
                  </span>
                </div>
                <div className="roi-result" style={{ flex: 1 }}>
                  <span className="roi-result-label">
                    {translate("CapacityCreated")}
                  </span>
                  <span
                    className="roi-result-value"
                    id="resources-result-value"
                  >
                    +5.00
                  </span>
                  <span className="roi-result-subtext">
                    {translate("CapacityDetail")}
                  </span>
                </div>
                <div className="roi-result" style={{ flex: 1 }}>
                  <span className="roi-result-label">
                    {" "}
                    {translate("ProjectedROI")}
                  </span>
                  <span className="roi-result-value" id="roi-result-value">
                    $21,425
                  </span>
                  <span className="roi-result-subtext">
                    {translate("ProjectedDetail")}
                  </span>
                </div>
                <div className="roi-result" style={{ flex: 1 }}>
                  <span className="roi-result-label">
                    {translate("MinimumROI")}
                  </span>
                  <span className="roi-result-value" id="min-roi-value">
                    174%
                  </span>
                  <span className="roi-result-subtext">
                    {translate("MinimumDetail")}
                  </span>
                </div>
              </div>
              <div className="roi-feedback" id="roi-feedback">
                {translate("Calculated")}
              </div>
              <div className="footnote">{translate("footnote")}</div>
            </div>
          </div>
          <div className="sectionROI">
            <h2>{translate("values")}</h2>
            <div className="partner-section">
              {values.map((value, index) => (
                <div key={index} className="flip-card">
                  <div className="flip-card-inner">
                    {/* Front */}
                    <div className="flip-card-front partner-box">
                      <h3>{value.title}</h3>
                      <p>{value.frontText}</p>
                    </div>
                    {/* Back */}
                    <div className="flip-card-back partner-box">
                      <p>{value.backText}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="sectionROI cta">
            <h2>{translate("transformorganization")}</h2>
            <p>
              {translate("Iflooking")} <strong>{translate("align")}</strong>
              {translate("Kanampartner")}
            </p>
            <a
              style={{ textDecoration: "none", color: "white" }}
              onClick={() => navigate.push("/plan")}
            >
              {translate("scheduleDemo")}
            </a>
            <a
              href="https://outlook.office365.com/owa/calendar/DemoKanam@darewell.co/bookings/"
              target="_new"
              style={{ textDecoration: "none" }}
            >
              {translate("ExpertTalk")}
            </a>
          </div>
        </div>
      </I18nProvider>
      <Footer />
    </div>
  );
};

export default About;
