import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
// import { useParams } from "react-router-dom";
// import Pagination from "react-pagination-js";
// import { toast } from "react-toastify";
// import { Tooltip } from "antd";
// import loader from "../../methods/loader";
// import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
// import { CiAlignRight } from "react-icons/ci";
// import { MdDataObject } from "react-icons/md";
// import ModalOKR from "./ModalOKR";
import { useDispatch, useSelector } from "react-redux";
// import AlignmentOKR from "./AlignmentOKR";
// import { FaUsers } from "react-icons/fa6";
// import { FaUsersSlash } from "react-icons/fa6";
// import { LuCircleDotDashed } from "react-icons/lu";
// import { BsFileEarmarkBarGraph } from "react-icons/bs";
// import { MdOutlineSignalCellularAlt } from "react-icons/md";
// import { sessions } from "../../actions/session";
import Select from "react-select";
import { Menu } from "@headlessui/react";
import Layout from "../../components/global/layout";
import OKRs from "../OKRs/OKRs";
import moment, { locale } from "moment";
import datepipeModel from "../../models/datepipemodel";
// import { Router } from "react-router-dom/cjs/react-router-dom.min";
// import Switch from "antd";
import { Tooltip } from "antd";
import { PieChart } from "react-minimal-pie-chart";
import { findOKRProgress } from "../../methods/methods";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

const getBrowserLocale = () => {
  const language = navigator.language.toLowerCase();
  if (language.includes("fr")) {
    return LOCALES.FRENCH;
  }
  return LOCALES.ENGLISH;
};

const localee = getBrowserLocale();

const Dashboardss = () => {
  const [LoadingState, setLoadingState] = useState(false);
  const user = useSelector((state) => state.user);
  const [filter, setfilter] = useState({ search: "", ownerId: "" });
  const [total, settotal] = useState(0);
  const [detail, setdetail] = useState();
  const [loading, setloading] = useState(false);
  const navigate = useHistory();
  const [upto, setupto] = useState();
  const [end, setend] = useState();
  const [Profiledata, setProfiledata] = useState();
  // Create OKRs
  const sessionid = localStorage.getItem("sessionID");
  const [sessionId, setSessionId] = useState(sessionid);
  localStorage.setItem("sessionID", sessionId);
  const [owners, setowners] = useState([]);
  const [totalObjective, settotalobjective] = useState();
  const [totalKeyResult, setTotalKeyResult] = useState();
  const [keyResultsOnTrack, setkeyResultsOnTrack] = useState();
  const [keyResultsAtRisk, setkeyResultsAtRisk] = useState();
  const [keyResultsOffTrack, setkeyResultsOffTrack] = useState();
  const ownerID = JSON.parse(localStorage.getItem("ownerId"));
  const [sessionme, setsession] = useState();
  const sessionName = localStorage.getItem("sessionNAme");

  useEffect(() => {
    if (sessionid) {
      setSessionId();
      getcounts(sessionid);
    } else {
      sessions();
    }
    getTotalUser();
  }, []);

  const clearOKRForm = (sessionId) => {
    navigate.push(`/sessions/okrs/${sessionId}`);
  };

  useEffect(() => {
    sessions();
  }, []);

  const [enddate, setenddate] = useState();
  const [startdate, setstartdate] = useState();
  const [data, setData] = useState([]);
  const [daysSpent, setDaysSpent] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [hoveredSegmentIndex1, setHoveredSegmentIndex1] = useState(null);
  const [hoveredSegmentIndex2, setHoveredSegmentIndex2] = useState(null);
  const percentageLeft = ((daysSpent / totalDays) * 100).toFixed(2);

  const PlanDayReturner = () => {
    const startDate = moment(startdate);
    const endDate = moment(enddate);
    const currentDate = moment(); // Today's date

    if (!startDate.isValid() || !endDate.isValid()) {
      console.error("Invalid dates detected");
      return { totalDays: 0, daysLeft: 0, daysSpent: 0 };
    }
    const totalDays = moment.duration(endDate.diff(startDate)).asDays();
    const daysSpent = Math.floor(
      moment.duration(currentDate.diff(startDate)).asDays()
    );
    const daysLeft = Math.max(Math.floor(totalDays - daysSpent), 0);
    return { totalDays: Math.floor(totalDays), daysLeft, daysSpent };
  };

  useEffect(() => {
    if (startdate && enddate) {
      const { totalDays, daysLeft, daysSpent } = PlanDayReturner();

      setTotalDays(totalDays); // Ensure totalDays is updated here
      setData([
        { title: "Days Spent", value: daysSpent, color: "#3e98c7" },
        { title: "Days Left", value: daysLeft, color: "#e9ecef" },
      ]);
      setDaysSpent(daysSpent);
      setDaysLeft(daysLeft);
    }
  }, [startdate, enddate]);

  const getTotalUser = (p = {}) => {
    ApiClient.get(`profile?id=${user?.id}`).then((res) => {
      if (res.success) {
        setProfiledata(res?.data);

        const planValidUpToDate = new Date(res?.data?.planValidUpTo);
        const createdAtDate = new Date(res?.data?.createdAt);

        // Set end date to the beginning of the next day in UTC timezone
        const nextDay = new Date(createdAtDate);
        nextDay.setDate(nextDay.getUTCDate() + 1);
        nextDay.setUTCHours(0, 0, 0, 0);

        setupto(planValidUpToDate);
        setend(nextDay);
      }
    });
  };

  // https://stageapi.kanam.io/sessions?search=&ownerId=&sortBy=start_date asc&addedBy=661e1fcfe3f6f78a0b3c05ef&active=true

  const sessions = () => {
    let fil;

    if (user?.role == "employee") {
      fil = {
        ...filter,
        // sortBy: localStorage.getItem('CreatedAt') == 'true' ? "start_date asc" : "updatedAt desc",
        addedBy: user?.addedBy,
        sortBy: "start_date asc",
        active: true,
        // active:localStorage.getItem('CreatedAt') == 'true' ? true :''
      };
    } else {
      fil = {
        ...filter,
        sortBy: "start_date asc",
        active: true,
        // sortBy: localStorage.getItem('CreatedAt') == 'true' ? "start_date asc" : "updatedAt desc",
        addedBy: user?.id || user?._id,
        // active:localStorage.getItem('CreatedAt') == 'true' ? true :''
      };
    }

    ApiClient.get(`sessions`, fil).then((res) => {
      if (res?.success && res.data.length > 0) {
        const sessionId = res.data[0]._id || res.data[0].id;
        const sessionName = res.data[0]?.title;
        setsession(res?.data[0]?.title);
        setSessionId(sessionId);
        if (!localStorage.getItem("endate")) {
          setenddate(res.data[0]?.end_date);
          setstartdate(res.data[0]?.start_date);
        }
        getcounts(sessionId);
        if (!localStorage.getItem("sessionNAme")) {
          // localStorage.setItem("sessionNAme",sessionName)
        }
        // getData(sessionId)
        // setsession(res?.data[0]?.title)
        // setdata(res?.data[0])

        // history.push(`/OKRs/${sessionId}`);
      } else {
        // getcounts();
      }
    });
  };

  const getcounts = (sessionId = "") => {
    let str = ownerID?.map((itm) => itm.value)?.toString() || "";
    let payload = {};
    if (user?.role == "employee") {
      payload = {
        ownerIds: user?.id || user?._id,
        session: sessionid || sessionId,
      };
      // payload = { ownerIds: str }
      // payload={ownerIds:'6613c83109ddd7fbf4ab68d7'}
    } else {
      payload = { session: sessionid || sessionId, ownerIds: str };
    }
    ApiClient.get(`get/count`, { ...payload }).then((res) => {
      if (res?.success) {
        settotalobjective(res?.totalObjective);
        setTotalKeyResult(res?.totalKeyResult);
        setkeyResultsOnTrack(res?.keyResultsOnTrack);
        setkeyResultsAtRisk(res?.keyResultsAtRisk);
        setkeyResultsOffTrack(res?.keyResultsOffTrack);
      }
    });
  };

  const objectives = [
    { id: 1, completion: 80 },
    { id: 2, completion: 60 },
    { id: 3, completion: 90 },
    // Add more objectives here
  ];
  const objectivesOver70 = objectives.filter(
    (obj) => obj.completion > 70
  ).length;

  return (
    <Layout>
      <I18nProvider locale={localee}>
        <div className="cards_sales">
          <div className="highlighted-name mb-2">
            {user?.role ? (
              <h4 className="d-flex align-items-center hand">
                <img src="/assets/img/hand1.gif " /> {translate("hi")}{" "}
                <span className="text-capitalize">
                  {" "}
                  {user?.fullName.split(" ")[0] ||
                    user?.firstName.split(" ")[0]}
                </span>
              </h4>
            ) : (
              <></>
            )}
          </div>
          <div className="mt-4 analytics-box">
            <p>
              <span>{translate("analyticsSession")}</span>
              <span>
                {" "}
                <b>{sessionme || ""}</b>
              </span>
            </p>
          </div>

          <p className="shadowit mt-3">{translate("overview")}</p>
          <div className="row">
            {/* Card 1 - Total number of Objectives */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <p className="sub_heading">
                      {translate("numberObjectives")}
                    </p>
                    <img src="/assets/img/bag.png" className="wallets_img" />
                  </div>
                  <div
                    className="sales_icons"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <PieChart
                    data={[
                      {
                        title: "Over 70% Completion",
                        value: objectivesOver70,
                        color: "#3e98c7",
                      },
                      {
                        title: "Others",
                        value: totalObjective - objectivesOver70,
                        color: "#e9ecef",
                      },
                    ]}
                    radius={40}
                    lineWidth={30}
                    totalValue={totalObjective}
                    animate
                    startAngle={-90}
                  /> */}
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "100px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#3e98c7",
                      }}
                    >
                      {totalObjective}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        top: "70%",
                      }}
                    >
                      {translate("objectives")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 2 - Remaining Days */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <p className="sub_heading">{translate("timeSpent")}</p>
                    <img
                      src="/assets/img/bag.png"
                      className="wallets_img"
                      alt="bag"
                    />
                  </div>
                  <div
                    className="sales_icons"
                    style={{
                      width: "100%",
                      height: "150px",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PieChart
                      data={data}
                      radius={40}
                      lineWidth={30}
                      totalValue={totalDays}
                      animate
                      startAngle={-90}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {Math.round(percentageLeft)}%
                      </div>
                      <div style={{ fontSize: "10px" }}>
                        {daysLeft} {translate("daysLeft")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Card 3 - Key Results Summary */}
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <p className="sub_heading">{translate("resultSummary")}</p>
                    <img
                      src="/assets/img/bag.png"
                      className="wallets_img"
                      alt="bag"
                    />
                  </div>
                  <div
                    className="sales_icons"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PieChart
                      data={[
                        {
                          title: "On Track",
                          value: keyResultsOnTrack || 0,
                          color: "#A9D18E",
                        },
                        {
                          title: "At Risk",
                          value: keyResultsAtRisk || 0,
                          color: "#FFD986",
                        },
                        {
                          title: "Off Track",
                          value: keyResultsOffTrack || 0,
                          color: "#ff5050",
                        },
                      ]}
                      radius={40}
                      lineWidth={30}
                      totalValue={total}
                      animate
                      startAngle={-90}
                      segmentsStyle={{
                        transition: "stroke-dasharray 0.5s ease-out",
                        filter:
                          "drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5))",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="shadowit mt-3">{translate("currentFocus")}</p>

          {totalObjective == 0 ? (
            <>
              {/* {user?.role != "employee" ? ( */}
              <div className="success-story">
                <div className="text-center">
                  <p className="text-center">
                    <h3>Start Your Success Story Now</h3>
                  </p>
                </div>
                <div className="create_okrs">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => clearOKRForm(sessionId)}
                    id="OpenCreateOKRModal"
                    data-toggle="modal"
                    data-target="#OKRsModal"
                  >
                    Create OKRs
                  </button>
                </div>
              </div>
              {/* // ) : ( // <></>
              // )} */}
            </>
          ) : (
            <>
              {sessionId ? (
                <>
                  <OKRs
                    layout={false}
                    ownerID={ownerID}
                    sessionId={sessionId}
                    getcounts={getcounts}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </I18nProvider>
    </Layout>
  );
};

export default Dashboardss;
