import React, { useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import methodModel from "../../methods/methods";
import { logout } from "../../actions/user";
import Plan from "../Plan/Plan";
import { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../../components/global/Footer";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

export default function Dashboard() {
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const navigate = useHistory();
  const dispatch = useDispatch();
  // const {tab} = useParams();
  const { username } = window.location.pathname;
  // const [on, two] = useState(username);
  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    localStorage.removeItem("sessionID");
    localStorage.removeItem("sessionNAme");
    localStorage.removeItem("CreatedAt");
    navigate.push("/login");
  };

  const section = methodModel.getPrams("section");

  useEffect(() => {
    if (section) {
      methodModel.scrollId(section);
    }
  }, [section]);

  const getBrowserLocale = () => {
    const language = navigator.language.toLowerCase();
    if (language.includes("fr")) {
      return LOCALES.FRENCH;
    }
    return LOCALES.ENGLISH;
  };

  const locale = getBrowserLocale();

  return (
    <>
      <I18nProvider locale={locale}>
        <section className="darkbg ">
          <nav className="navbar navbar-expand-lg navbar-light homenavs px-5">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/home">
                <img
                  src="assets/img/logo-kanam-blue.png"
                  className="logo"
                  alt=""
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo03"
                aria-controls="navbarTogglerDemo03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo03"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navwhite">
                  <li className="nav-item">
                    <Link
                      to="/home"
                      className={
                        username === "/home" ? "nav-link active" : "nav-link"
                      }
                    >
                      {translate("product")}
                    </Link>
                  </li>
                  {!user?.isEmployee && (
                    <li className="nav-item">
                      <Link
                        to="/plan"
                        className={
                          username === "/plan" ? "nav-link active" : "nav-link"
                        }
                      >
                        {translate("pricing")}
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/aboutus"
                      className={
                        username === "/aboutus" ? "nav-link active" : "nav-link"
                      }
                    >
                      {translate("aboutUs")}
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="okrDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {translate("resources")}
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="okrDropdown">
                      <li>
                        <Link
                          to="/selfassessment"
                          className="dropdown-item"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title={translate("okrMaturityTooltip")}
                        >
                          {translate("okrMaturityAssessment")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>

                <form className="d-flex">
                  <Link
                    className="btn-white-border ms-3 mr-2 "
                    to="/demo"
                    style={{ textDecoration: "none", textAlign: "center" }}
                  >
                    {translate("scheduleDemo")}
                  </Link>
                </form>

                {token ? (
                  <div className="dropdown">
                    <a
                      data-toggle="dropdown"
                      className="nav-link dropdown-toggle profile-after nav-link-user text-dark d-flex align-items-center"
                    >
                      <img
                        alt="image"
                        src={methodModel.userImg(user.image)}
                        className="rounded-circle mr-1"
                        width="50px"
                      />
                      <div className="ml-1 nameFont pointer ">
                        <b className="text-light text-capitalize">
                          {user.companyName}
                        </b>
                        <p className="grayCls mb-0 text-capitalize">
                          {user.role?.name}
                        </p>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover">
                      <Link to="/profile" className="dropdown-item has-icon">
                        <i className="far fa-user" /> {translate("profile")}
                      </Link>
                      <Link
                        to="/profile?tab=change-pasword"
                        className="dropdown-item has-icon"
                      >
                        <i className="fa fa-cog" aria-hidden="true"></i>{" "}
                        {translate("changePassword")}
                      </Link>
                      <a
                        onClick={() => Logout()}
                        id="logoutBtn"
                        className="dropdown-item has-icon"
                      >
                        <i className="fas fa-sign-out-alt" />{" "}
                        {translate("logout")}
                      </a>
                    </div>
                  </div>
                ) : (
                  <form className="d-flex">
                    <button
                      className="btn-white-menu me-3"
                      onClick={(e) => {
                        navigate.push(`/login`);
                      }}
                      type="button"
                    >
                      {translate("login")}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </nav>
        </section>

        <section className="hero_section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="text-center hero-wrap-text">
                  <h3 className="lemfont-bold">{translate("topline")}</h3>
                  <h1 className="lemfont-bold text-black bold toptitle ">
                    {translate("title")}
                  </h1>
                  <p
                    className="text-black ptext toptitle lemfont-bold"
                    style={{ fontSize: "30px" }}
                  >
                    {translate("description", {
                      b: (chunks) => <b>{chunks}</b>,
                      br: () => <br />,
                    })}
                  </p>
                  <div className="mt-4">
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn-white-border-main"
                        type="submit"
                        onClick={() => navigate.push("/plan")}
                      >
                        {translate("startTrial")}
                      </button>
                      {/* <button className=" btn-white-border ms-3" type="submit">
                      Book a demo
                    </button> */}
                      {/* <form className="d-flex">
                        <a
                          className="btn-white-border ms-3 "
                          href="https://outlook.office365.com/owa/calendar/DemoKanam@darewell.co/bookings/"
                          target="_new"
                          style={{ textDecoration: "none" }}
                        >
                          {translate("scheduleDemo")}
                        </a>
                      </form> */}
                    </div>
                  </div>
                  <p className=" ptext grey-text">{translate("trialNote")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="img_secthirds">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12"></div>
            </div>
          </div>
        </section>
        {/* <section className="brands_name common-padding">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <img src="/assets/img/s1.png" className="img-fluid" alt="" />
            <img src="/assets/img/s2.png" className="img-fluid" alt="" />
            <img src="/assets/img/s3.png" className="img-fluid" alt="" />
            <img src="/assets/img/s4.png" className="img-fluid" alt="" />
          </div>
        </div>
      </section> */}
        <section className="sectinnforth common-padding ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="titled_text text-center">
                  <h2 className="main-title">
                    {" "}
                    {translate("alignAchieveExcel", { br: () => <br /> })}
                  </h2>
                  <p className="sub-title mt-4">
                    {translate("bridgeGap", { br: () => <br /> })}
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card_inner my-1">
                  <div className="icon mb-3">
                    <span className="bglightblue">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 196 196"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M98.0269 53.2777C86.1772 53.2916 74.8169 58.005 66.4379 66.384C58.0589 74.763 53.3455 86.1234 53.3315 97.973C55.789 157.267 140.274 157.25 142.722 97.9727C142.708 86.1231 137.995 74.7628 129.616 66.3839C121.237 58.005 109.877 53.2916 98.0269 53.2777ZM98.0269 135.667C88.0335 135.655 78.4528 131.68 71.3864 124.614C64.32 117.547 60.345 107.966 60.3335 97.973C62.405 47.9677 133.656 47.9822 135.72 97.9733C135.709 107.967 131.734 117.547 124.667 124.614C117.601 131.68 108.02 135.655 98.0269 135.667Z"
                          fill="#00218F"
                        />
                        <path
                          d="M98.0269 81.4975C76.2536 82.1907 76.2587 113.759 98.0273 114.449C119.8 113.756 119.795 82.1872 98.0269 81.4975ZM98.0269 107.447C95.5327 107.419 93.1502 106.409 91.3963 104.635C89.6425 102.861 88.6588 100.467 88.6589 97.9731C88.6589 95.4787 89.6426 93.085 91.3965 91.3114C93.1504 89.5378 95.533 88.5274 98.0272 88.4994C100.52 88.5294 102.901 89.5407 104.653 91.3142C106.405 93.0876 107.388 95.4802 107.388 97.9733C107.388 100.466 106.405 102.859 104.653 104.632C102.9 106.406 100.52 107.417 98.0269 107.447Z"
                          fill="#00218F"
                        />
                        <path
                          d="M192.553 94.4721H167.92C167.038 77.1494 159.759 60.7702 147.495 48.5053C135.23 36.2405 118.851 28.9623 101.528 28.0798V3.44704C101.514 2.52792 101.138 1.65128 100.483 1.00636C99.8284 0.361436 98.946 -2.75753e-05 98.0268 1.57782e-09C97.1076 2.75785e-05 96.2252 0.361543 95.5702 1.0065C94.9153 1.65146 94.5402 2.52813 94.526 3.44725V28.0798C77.2033 28.9623 60.8241 36.2405 48.5592 48.5053C36.2944 60.7702 29.0162 77.1494 28.1337 94.4721H3.50096C2.57245 94.4721 1.68197 94.8409 1.02541 95.4975C0.368851 96.154 0 97.0445 0 97.973C0 98.9016 0.368851 99.792 1.02541 100.449C1.68197 101.105 2.57245 101.474 3.50096 101.474H28.1337C29.0162 118.797 36.2944 135.176 48.5592 147.441C60.8241 159.706 77.2033 166.984 94.526 167.866V192.499C94.526 193.428 94.8948 194.318 95.5514 194.975C96.2079 195.631 97.0984 196 98.0269 196C98.9554 196 99.8459 195.631 100.502 194.975C101.159 194.318 101.528 193.428 101.528 192.499V167.866C118.851 166.984 135.23 159.706 147.495 147.441C159.759 135.176 167.038 118.797 167.92 101.474H192.553C193.472 101.46 194.349 101.085 194.994 100.43C195.639 99.7746 196 98.8923 196 97.973C196 97.0538 195.639 96.1715 194.994 95.5164C194.349 94.8614 193.472 94.4863 192.553 94.4721ZM98.0269 161.042C81.3058 161.023 65.2748 154.372 53.4512 142.549C41.6276 130.725 34.9769 114.694 34.9583 97.973C38.4213 14.3035 157.645 14.328 161.096 97.9735C161.077 114.695 154.426 130.725 142.603 142.549C130.779 154.372 114.748 161.023 98.0269 161.042Z"
                          fill="#00218F"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="text-cards">
                    <h3 className="cards_title card-head-height ">
                      {translate("unifiedGoals")}
                    </h3>
                    <p className="cards_ptext">
                      {translate("unifiedGoalsDesc", { br: () => <br /> })}
                    </p>
                    {/* <a href="" className="acards">
                    Learn more
                  </a> */}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card_inner my-1">
                  <div className="icon mb-3">
                    <span className="bglightblue">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M33.9182 14.4067C35.1792 17.5445 35.3429 21.0164 34.3827 24.259C33.4225 27.5016 31.3947 30.3246 28.6285 32.2698C25.8622 34.2151 22.5198 35.1686 19.1435 34.9755C17.7201 34.8941 16.326 34.6109 15 34.1421M23.3334 5.375C20.2493 4.67201 17.0157 4.96109 14.0979 6.2099C10.989 7.54053 8.42085 9.88268 6.81031 12.8563C6.53705 13.3608 6.29408 13.8785 6.08183 14.4067C5.04317 16.9912 4.74046 19.8257 5.22558 22.5914C5.40694 23.6254 5.69509 24.6309 6.08183 25.5932"
                          stroke="#042491"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                        />
                        <circle
                          cx="26.6667"
                          cy="6.66658"
                          r="3.33333"
                          stroke="#042491"
                          strokeWidth="2.5"
                        />
                        <circle
                          cx="11.6667"
                          cy="33.3333"
                          r="3.33333"
                          stroke="#042491"
                          strokeWidth="2.5"
                        />
                        <path
                          d="M15.6671 16.685L19.3959 15.2349C19.7845 15.0838 20.2155 15.0838 20.6041 15.2349L24.3329 16.685C24.7351 16.8414 25 17.2286 25 17.6602V19.2491C25 21.4755 23.9721 23.5772 22.2147 24.9441C20.912 25.9572 19.088 25.9572 17.7853 24.9441C16.0279 23.5772 15 21.4755 15 19.2491V17.6602C15 17.2286 15.2649 16.8414 15.6671 16.685Z"
                          stroke="#042491"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="text-cards">
                    <h3 className="cards_title card-head-height">
                      {translate("achievementInsights")}
                    </h3>
                    <p className="cards_ptext">
                      {translate("achievementInsightsDesc", {
                        br: () => <br />,
                      })}
                    </p>
                    {/* <a href="" className="acards">
                    Learn more
                  </a> */}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card_inner my-1">
                  <div className="icon mb-3">
                    <span className="bglightblue">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 186 184"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M175.576 101.744H10.4243C5.18596 101.744 0.924283 97.4825 0.924283 92.2441C0.924283 87.0057 5.1856 82.7444 10.4243 82.7444H175.576C180.815 82.7444 185.077 87.0061 185.077 92.2444C185.077 97.4828 180.815 101.744 175.576 101.744ZM10.4243 87.0644C7.56808 87.0644 5.24428 89.3882 5.24428 92.2444C5.24428 95.1007 7.56808 97.4245 10.4243 97.4245H175.576C178.433 97.4245 180.757 95.1007 180.757 92.2444C180.757 89.3882 178.433 87.0644 175.576 87.0644H10.4243Z"
                          fill="#00218F"
                        />
                        <path
                          d="M93.0004 72.384C64.7253 72.384 36.0347 67.9603 7.72576 59.2364C5.2504 58.4736 3.2254 56.7884 2.02444 54.4912C0.822763 52.1926 0.594163 49.5661 1.38076 47.0954C2.977 42.0835 8.37052 39.2643 13.4044 40.8112C66.2207 57.0408 119.78 57.0408 172.596 40.8112C177.632 39.2643 183.024 42.0835 184.62 47.0954C185.407 49.5661 185.178 52.193 183.977 54.4912C182.775 56.7884 180.751 58.4736 178.275 59.2368C149.966 67.9606 121.276 72.384 93.0004 72.384ZM10.5795 44.7061C8.32084 44.7061 6.21412 46.1547 5.49736 48.4058C5.06284 49.7698 5.1892 51.2199 5.85268 52.4893C6.51544 53.7568 7.63252 54.6867 8.998 55.1076C36.8944 63.7047 65.1569 68.0636 93.0004 68.0636C120.844 68.0636 149.106 63.7047 177.003 55.1076C178.368 54.6867 179.485 53.7568 180.148 52.4889C180.812 51.2199 180.938 49.7698 180.504 48.4058C179.623 45.6406 176.645 44.0869 173.865 44.9401C120.207 61.4284 65.7934 61.4284 12.1354 44.9401C11.6188 44.7817 11.095 44.7061 10.5795 44.7061Z"
                          fill="#00218F"
                        />
                        <path
                          d="M175.411 144.101C174.478 144.102 173.531 143.965 172.597 143.678C119.78 127.448 66.2207 127.448 13.4044 143.678C8.36944 145.225 2.97664 142.405 1.38076 137.393C0.593803 134.923 0.822403 132.296 2.02408 129.998C3.22504 127.7 5.24968 126.015 7.7254 125.252C36.0354 116.528 64.726 112.105 93.0004 112.105C121.275 112.105 149.965 116.528 178.275 125.252C180.751 126.015 182.776 127.701 183.977 129.998C185.178 132.297 185.407 134.923 184.62 137.394C183.32 141.474 179.502 144.101 175.411 144.101ZM93.0004 127.182C120.022 127.182 147.033 131.303 173.865 139.548C176.644 140.402 179.623 138.848 180.504 136.082C180.938 134.719 180.812 133.269 180.148 132C179.485 130.732 178.368 129.802 177.002 129.381C149.106 120.784 120.843 116.425 93.0004 116.425C65.1576 116.425 36.8951 120.784 8.998 129.381C7.63252 129.802 6.51544 130.732 5.85232 132C5.18884 133.269 5.06284 134.719 5.497 136.082C6.37756 138.848 9.35512 140.402 12.1354 139.548C38.9615 131.305 65.9856 127.182 93.0004 127.182Z"
                          fill="#00218F"
                        />
                        <path
                          d="M93.0004 40.7C92.3319 40.7 91.7008 40.3904 91.2918 39.8616L74.7768 18.5089C74.2728 17.8569 74.1836 16.9753 74.5464 16.2358C74.9093 15.4964 75.6617 15.0277 76.4854 15.0277H83.8337V2.32797C83.8337 1.13529 84.801 0.167969 85.9937 0.167969H100.007C101.2 0.167969 102.167 1.13529 102.167 2.32797V15.0273H109.516C110.34 15.0273 111.092 15.496 111.455 16.2355C111.818 16.9753 111.729 17.8569 111.224 18.5085L94.709 39.8612C94.2996 40.3904 93.6689 40.7 93.0004 40.7ZM80.8868 19.3477L93.0004 35.0095L105.114 19.3477H100.007C98.8144 19.3477 97.8471 18.3804 97.8471 17.1877V4.48797H88.1534V17.1873C88.1534 18.38 87.186 19.3473 85.9934 19.3473L80.8868 19.3477Z"
                          fill="#00218F"
                        />
                        <path
                          d="M100.007 183.832H85.9937C84.801 183.832 83.8337 182.865 83.8337 181.672V168.973H76.4854C75.6614 168.973 74.9093 168.504 74.5464 167.765C74.1836 167.025 74.2728 166.143 74.7768 165.491L91.2918 144.139C91.7008 143.61 92.3319 143.3 93.0004 143.3C93.6689 143.3 94.2996 143.61 94.709 144.139L111.224 165.491C111.728 166.143 111.818 167.025 111.455 167.765C111.092 168.504 110.34 168.973 109.516 168.973H102.167V181.672C102.167 182.865 101.2 183.832 100.007 183.832ZM88.1537 179.512H97.8474V166.813C97.8474 165.62 98.8148 164.653 100.007 164.653H105.114L93.0004 148.991L80.8868 164.653H85.9937C87.1864 164.653 88.1537 165.62 88.1537 166.813V179.512Z"
                          fill="#00218F"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="text-cards">
                    <h3 className="cards_title card-head-height">
                      {translate("flexibleObjectives")}
                    </h3>
                    <p className="cards_ptext">
                      {translate("flexibleObjectivesDesc", {
                        br: () => <br />,
                      })}
                    </p>
                    {/* <a href="" className="acards">
                    Learn more
                  </a> */}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card_inner my-1">
                  <div className="icon mb-3">
                    <span className="bglightblue">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 170 146"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.96244 76.513C10.8663 59.2138 18.5433 43.6511 30.0262 31.7924L43.0159 44.7835L49.0263 38.7721L36.2729 26.0174C48.4865 15.919 63.8911 9.5402 80.75 8.61745V29.7551H89.25V8.61745C106.109 9.54015 121.514 15.9192 133.728 26.0178L121.49 38.2561L127.5 44.2679L139.974 31.7929C151.457 43.6515 159.134 59.2138 161.038 76.513H143.158V85.0145H161.5C161.489 103.464 154.946 120.381 144.049 133.587L129.513 119.049L123.502 125.06L138.281 139.84L138.227 139.893L144.14 146C160.082 130.56 170 108.919 170 84.9685C170 38.0397 131.942 0 85 0C38.0579 0 0 38.0397 0 84.9685C0 108.283 9.39934 129.412 24.6062 144.76L30.6438 138.775C30.617 138.749 30.5906 138.722 30.5639 138.695L43.336 125.92L37.3256 119.909L24.9227 132.314C14.644 119.302 8.51045 102.877 8.5 85.0145H25.724V76.513H8.96244Z"
                          fill="#00218F"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M133.796 96.1564L74.4545 101.632C72.8135 101.947 71.3439 102.85 70.3218 104.172L63.3581 114.499C62.5829 119.66 63.1329 121.713 66.1831 125.043L77.3759 130.503C78.9221 131.137 80.6463 131.185 82.2252 130.637L136.266 105.676C144.305 102.886 142.152 94.5518 133.796 96.1564ZM79.9748 122.312L114.204 106.502L76.6683 109.965L71.5598 117.541C71.5381 117.886 71.5411 118.091 71.547 118.199L79.9748 122.312ZM71.4829 118.089C71.4829 118.089 71.4909 118.102 71.5007 118.124C71.4867 118.1 71.4829 118.089 71.4829 118.089ZM133.893 97.4657L133.905 97.458C133.905 97.458 133.901 97.461 133.893 97.4657Z"
                          fill="#00218F"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="text-cards">
                    <h3 className="cards_title card-head-height">
                      {translate("performanceRecognition")}
                    </h3>
                    <p className="cards_ptext">
                      {translate("performanceRecognitionDesc", {
                        br: () => <br />,
                      })}
                    </p>
                    {/* <a href="" className="acards">
                    Learn more
                  </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="secfifth  common-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="titled_text_fifth">
                <header className="text-center mb-5 ">
                  <div className="row">
                    <div className="col-12 col-lg-7 mx-auto">
                      <h2>Our Pricing</h2>
                      <p>Pay securely online and manage the booking via desktop or via the mobile app.  </p>

                      <div className='togle_data'>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div><p className='plantext mr-3'>Monthly</p></div>
                          <div>
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider"></span>
                            </label>
                          </div>
                          <div><p className='plantext ml-3'>Yearly</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                <div className="row  align-items-end">

                  <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-lg-0">
                    <div className="bg-white p-5 ">
                      <div className='text-center'>
                        <h3 className="h6 text-uppercase font-weight-bold mb-4">Standard</h3>
                        <p>The national average cost of buying coin easy.</p>
                        <h2 className="h1 font-weight-bold">$199<span className="text-small font-weight-normal ml-2">/ month</span></h2>
                        <a href="#" className="btn btn-primary btn-block p-2 shadow rounded-pill">Select Plan</a>
                      </div>

                      <div className="custom-separator my-4 mx-auto bg-primary"></div>

                      <ul className="list-unstyled my-5 text-small text-left">
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> Lorem ipsum dolor sit amet</li>
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> Sed ut perspiciatis</li>
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> At vero eos et accusamus</li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Nam libero tempore</del>
                        </li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Sed ut perspiciatis</del>
                        </li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Sed ut perspiciatis</del>
                        </li>
                      </ul>

                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-lg-0">
                    <div className=" active_card p-5 ">
                      <div className='text-center'>
                        <h3 className="h6 text-uppercase font-weight-bold mb-4">Extended</h3>
                        <p>The national average cost of buying coin easy.</p>
                        <h2 className="h1 font-weight-bold">$199<span className="text-small font-weight-normal ml-2">/ month</span></h2>
                        <a href="#" className="btn btn-primary btn-block p-2 shadow rounded-pill">Select Plan</a>
                      </div>

                      <div className="custom-separator my-4 mx-auto bg-primary"></div>

                      <ul className="list-unstyled my-5 text-small text-left">
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> Lorem ipsum dolor sit amet</li>
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> Sed ut perspiciatis</li>
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> At vero eos et accusamus</li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Nam libero tempore</del>
                        </li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Sed ut perspiciatis</del>
                        </li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Sed ut perspiciatis</del>
                        </li>
                      </ul>

                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-lg-0">
                    <div className="bg-white p-5 ">
                      <div className='text-center'>
                        <h3 className="h6 text-uppercase font-weight-bold mb-4">Standard</h3>
                        <p>The national average cost of buying coin easy.</p>
                        <h2 className="h1 font-weight-bold">$199<span className="text-small font-weight-normal ml-2">/ month</span></h2>
                        <a href="#" className="btn btn-primary btn-block p-2 shadow rounded-pill">Select Plan</a>
                      </div>

                      <div className="custom-separator my-4 mx-auto bg-primary"></div>

                      <ul className="list-unstyled my-5 text-small text-left">
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> Lorem ipsum dolor sit amet</li>
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> Sed ut perspiciatis</li>
                        <li className="mb-3">
                          <i className="fa fa-check mr-2 text_primary"></i> At vero eos et accusamus</li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Nam libero tempore</del>
                        </li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Sed ut perspiciatis</del>
                        </li>
                        <li className="mb-3 text-muted">
                          <i className="fa fa-times mr-2"></i>
                          <del>Sed ut perspiciatis</del>
                        </li>
                      </ul>

                    </div>
                  </div>




                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        {/* <Plan /> */}

        <div className="team_seciton py-5">
          <div className="container">
            <div className="row align-items-center flex-column-reverse  flex-sm-column-reverse   flex-md-row ">
              <div className=" col-12 col-sm-12 col-md-7 col-lg-7">
                <div className="mb-3">
                  <h2 className="team_h2">{translate("harmonizeGoals")}</h2>
                  <p className="platform_p">{translate("navigate")}</p>
                  {/* <div className="d-flex flex-wrap space_gap">
                  <ul className="imges_list">
                    <li>
                      <img
                        src="assets/img/images/small2"
                        alt=""
                        className="small_mg"
                      />
                    </li>
                    <li>
                      <img
                        src="assets/img/images/small2"
                        alt=""
                        className="small_mg"
                      />
                    </li>
                    <li>
                      <img
                        src="assets/img/images/small2"
                        alt=""
                        className="small_mg"
                      />
                    </li>
                    <li>
                      <img
                        src="assets/img/images/small2"
                        alt=""
                        className="small_mg"
                      />
                    </li>
                  </ul>

                  <p className="number_text">
                    More than 10.000 people have tried
                  </p>
                </div> */}
                  <button
                    className="btn btn-primary custom-btn-drk"
                    onClick={() => navigate.push("/plan")}
                  >
                    {translate("tryForFree")}
                  </button>
                </div>
              </div>
              <div className=" col-12 col-sm-12 col-md-5 col-lg-5">
                <div className="mb-3">
                  <img
                    src="assets/img/banner-two.png"
                    alt=""
                    className="Dashboard_light"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="seventh-sect py-5 my-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h2 className="main-title">
                    {" "}
                    {translate("exclusiveLaunchOffer")}
                  </h2>
                  <p className="sub-title mt-4 mb-0">
                    {translate("joinUsLaunch")}
                  </p>
                  <p className="sub-title ">{translate("thankYou")}</p>
                  <div className="">
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-primary custom-btn-drk drk-big-btn"
                        type="submit"
                        onClick={() => navigate.push("/plan")}
                      >
                        {translate("claimExclusiveOffer")}
                      </button>

                      <a
                        className=" btn blue-border-btn ms-3"
                        href="https://outlook.office365.com/owa/calendar/DemoKanam@darewell.co/bookings/"
                        target="_new"
                      >
                        {translate("askForDemo")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="eight-sect py-5" id="faqs">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="text-center hero-wrap-text">
                  <h1 className=" titleh1">{translate("getStartedTitle")}</h1>
                  <p className="ptext mb-4">
                    {translate("getStartedDescription")}
                  </p>

                  <div className="faq-sect mt-5">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="faq-left-side">
                          <div className="faq-left">
                            <h2 className="">{translate("faqTitle")}</h2>
                            <button
                              className="btn btn-primary custom-btn-drk drk-big-btn"
                              type="submit"
                              onClick={() => navigate.push("/plan")}
                            >
                              {translate("signUpForFree")}
                            </button>
                          </div>
                          <div className="ques-box">
                            <h4>{translate("stillHaveQuestion")}</h4>
                            <a href="#">
                              {" "}
                              <span>{translate("contactUs")}</span>
                              {translate("happyToHelp")}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="faq-inner">
                          <div className="accordion" id="accordionExample">
                            <div className="card">
                              <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    {translate("faq1")}
                                  </button>
                                </h2>
                              </div>

                              <div
                                id="collapseOne"
                                className="collapse show"
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  {translate("faq1Desc")}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion" id="accordionExample1">
                            <div className="card">
                              <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    {translate("faq2")}
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseTwo"
                                className="collapse"
                                aria-labelledby="headingTwo"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  {translate("faq2Desc")}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accordion" id="accordionExample4">
                            <div className="card">
                              <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    {translate("faq3")}
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseThree"
                                className="collapse"
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  {translate("faq3Desc")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="accordionExample5">
                            <div className="card">
                              <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                  >
                                    {translate("faq4")}
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseFour"
                                className="collapse"
                                aria-labelledby="headingFour"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  {translate("faq4Desc")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion" id="accordionExample6">
                            <div className="card">
                              <div className="card-header" id="headingFive">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                  >
                                    {translate("faq5")}
                                  </button>
                                </h2>
                              </div>
                              <div
                                id="collapseFive"
                                className="collapse"
                                aria-labelledby="headingFive"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  {translate("faq5Desc")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="seventh-sect py-5  my-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className=" text-center">
                  <h2 className="main-title"> {translate("transformGoals")}</h2>
                  <p className="sub-title mt-4 mb-0">
                    {translate("journeyToExcellence")}
                  </p>

                  <div className="">
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-primary custom-btn-drk drk-big-btn"
                        type="submit"
                        onClick={() => navigate.push("/plan")}
                      >
                        {translate("tryForFree")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="user_section py-5">
        <div className="container">
          <div className="d-flex justify-content-between mb-5">
            <h2 className="uses_names">Kanam is loved by users</h2>
            <div className="side_arrow d-flex align-items-center ">
              <img
                src="assets/img/images/arrow-left-circle.png"
                className="arrow_move"
                alt=""
              />
              <img
                src="assets/img/images/arrow-right-circle.png"
                className="arrow_move"
                alt=""
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-lg-4  col-lg-4">
              <div className="box-border">
                <div className="d-flex user_box">
                  <img
                    src="assets/img/images/mask2.png"
                    alt=""
                    className="user_img"
                  />
                  <div className="user_profile">
                    <h6 className="profiles">Briana Patton</h6>
                    <p className="profile_detail">Manager </p>
                  </div>
                </div>

                <p className="detail_para">
                  Sed mattis est eget penatibus mauris, sed condimentum vitae
                  viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget
                  nisl pellentesque non, porttitor. Vitae et vestibulum ac id.
                  Dui aliquet porttitor libero consequat volutpat eget sed
                  turpis. Feugiat maecenas commodo et morbi morbi gravida.
                </p>

                <ul className="star_list_bx">
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-4  col-lg-4">
              <div className="box-border">
                <div className="d-flex user_box">
                  <img
                    src="assets/img/images/mask2.png"
                    alt=""
                    className="user_img"
                  />
                  <div className="user_profile">
                    <h6 className="profiles">Briana Patton</h6>
                    <p className="profile_detail">Manager </p>
                  </div>
                </div>

                <p className="detail_para">
                  Sed mattis est eget penatibus mauris, sed condimentum vitae
                  viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget
                  nisl pellentesque non, porttitor. Vitae et vestibulum ac id.
                  Dui aliquet porttitor libero consequat volutpat eget sed
                  turpis. Feugiat maecenas commodo et morbi morbi gravida.
                </p>

                <ul className="star_list_bx">
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-4  col-lg-4">
              <div className="box-border">
                <div className="d-flex user_box">
                  <img
                    src="assets/img/images/mask.png"
                    alt=""
                    className="user_img"
                  />
                  <div className="user_profile">
                    <h6 className="profiles">Briana Patton</h6>
                    <p className="profile_detail">Manager </p>
                  </div>
                </div>

                <p className="detail_para">
                  Sed mattis est eget penatibus mauris, sed condimentum vitae
                  viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget
                  nisl pellentesque non, porttitor. Vitae et vestibulum ac id.
                  Dui aliquet porttitor libero consequat volutpat eget sed
                  turpis. Feugiat maecenas commodo et morbi morbi gravida.
                </p>

                <ul className="star_list_bx">
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                  <li>
                    <svg
                      className="star_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5245 3.46352C11.6741 3.00287 12.3259 3.00287 12.4755 3.46353L14.3574 9.25532C14.4243 9.46133 14.6163 9.60081 14.8329 9.60081H20.9228C21.4071 9.60081 21.6085 10.2206 21.2167 10.5053L16.2899 14.0848C16.1146 14.2122 16.0413 14.4379 16.1082 14.6439L17.9901 20.4357C18.1398 20.8963 17.6125 21.2794 17.2207 20.9947L12.2939 17.4152C12.1186 17.2878 11.8814 17.2878 11.7061 17.4152L6.77931 20.9947C6.38745 21.2794 5.86021 20.8963 6.00989 20.4357L7.89176 14.6439C7.9587 14.4379 7.88537 14.2122 7.71012 14.0848L2.78333 10.5053C2.39147 10.2206 2.59286 9.60081 3.07722 9.60081H9.16708C9.38369 9.60081 9.57567 9.46133 9.6426 9.25532L11.5245 3.46352Z"
                        fill="#EF7420"
                      />
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        <Footer />
      </I18nProvider>
    </>
  );
}
