import React, { useEffect, useState } from "react";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import { Checkbox, Tooltip } from "antd";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { GoIssueTrackedBy } from "react-icons/go";
import { GoIssueTracks } from "react-icons/go";
import { CgDanger } from "react-icons/cg";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AsyncSelectDropdown from "../../components/common/AsyncSelectDropdown";
import translate from "../../i18n/translate";

const ModalOKR = ({
  sessionId,
  employeeData,
  handleKeyResultAction = () => {},
  getOKRListing = () => {},
  handleUpdateKeyResultModal = () => {},
  calculateProgress = () => {},
  detail,
  handleAction,
  findOKRProgress,
  keyResult,
  setkeyResult,
  owners,
  updateKeyResult,
  setupdateKeyResult,
  clearFields,
  childObjective,
  form,
  setform,
  getData,
  sessions,
  parentKeyResult,
  detailModal,
  childKeyResult,
  setchildKeyResult,
  result = () => {},
  valueData,
  setNewValue = () => {},
  valueDataUnit,
  setNewValueUnit = () => {},
}) => {
  const user = useSelector((state) => state.user);
  const [OKRFilter, setOKRFilter] = useState("keyResult");
  const [disableed, setdisable] = useState(false);
  const [keyChildResultData, setkeyChildResultData] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [generatedOKRs, setGeneratedOKRs] = useState("");
  const [showGeneratedOKRsModal, setShowGeneratedOKRsModal] = useState(false);

  const option = (itm) => {
    return itm
      ? {
          label: (
            <div className="d-flex">
              <span className="mr-2 objective-img">
                {/* <LuCircleDotDashed /> */}
                {itm?.keyType == "child_objective" || itm?.keyType == "okr" ? (
                  <span className="objective-img ">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                      <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                      <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                      <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                      <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                      <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                      <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                      <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                      <circle cx="12" cy="12" r="1"></circle>
                    </svg>
                  </span>
                ) : (
                  <span className="key-img">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                    </svg>
                  </span>
                )}
              </span>
              <span>{itm.title}</span>
            </div>
          ),
          value: itm._id,
        }
      : null;
  };

  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    search: "",
    ownerId: "",
  });
  const [summit, setsummit] = useState(false);
  const [data, setdata] = useState([]);
  const [details, setdetail] = useState();

  const handleCreateOKR = (e) => {
    e.preventDefault();
    setdisable(true);
    loader(true);
    let payload = {
      title: form?.title,
      ownerIds: form?.ownerIds || [],
      contributors: form?.contributors || [],
      whyNow: form?.whyNow || "",
      session: form?.session?.value,
      sessionId: form?.session?.Id,
      parentId: form?.parentId || null,
      okrId: form?.parentId,
      keyType: form?.parentId ? "child_objective" : "okr",
      grandParentId: detail?.id || detail?._id,
    };
    if (form?.id) {
      payload = { ...payload, id: form?.id };
      ApiClient.put(`okr`, payload).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          result({ event: "All Submit", value: res.data });
          getData({}, false);
          getOKRListing();
          document.getElementById("CloseOKRsModal").click();
          setdisable(false);
        }
      });
    } else {
      ApiClient.post(`okr`, payload).then((res) => {
        if (res.success) {
          result({ event: "All Submit", value: res.data });
          if (form?.parentId) {
            toast.success("Child OKR Created");
            result({ event: "Child Submit", value: res.data });
          } else {
            // toast.success(res?.message);
            result({ event: "Parent Submit", value: res.data });
            toast.success("Objective created! Let's crush it! 💪");
          }
          getData({}, false);
          getOKRListing();
          document.getElementById("CloseOKRsModal").click();
          setdisable(false);
        }
        loader(false);
      });
    }
  };

  const handleCreateAIOKR = async (e) => {
    e.preventDefault();
    setdisable(true);
    loader(true);

    const payload = {
      description: form?.description,
    };

    try {
      ApiClient.post(`okr/ai`, payload)
        .then((res) => {
          if (res.success) {
            console.log(res?.data, "ai data ----");
            setGeneratedOKRs(res.data);
            setShowGeneratedOKRsModal(true);
            setdisable(false);
          }
        })
        .catch((error) => {
          console.error("Error generating OKRs:", error);
          toast.error("Failed to generate OKRs. Please try again.");
        })
        .finally(() => {
          loader(false);
          setdisable(false);
        });
    } catch (error) {
      console.error("Error generating OKRs:", error);
      toast.error("Failed to generate OKRs. Please try again.");
      loader(false);
      setdisable(false);
    }
  };

  const clearKeyResultModal = () => {
    setkeyResult({
      title: "",
      unit: "not set",
      description: "",
      note: "",
      targetNumber: "",
      initialNumber: "",
      contributors: [],
      whyNow: "",
      deadline: "no custom deadline",
      softDeadline: "",
      hardDeadline: "",
    });
  };

  const handleCreateKeyResult = (e) => {
    e.preventDefault();

    loader(true);
    let payload = {
      ...keyResult,
      ownerIds: keyResult?.ownerIds?.map((item) => item?.value) || [],
      okrId: detail?.id || detail?._id,
      session: keyResult.session || null, // Ensure session is not an empty string
      keyType: "key_result",
    };
    payload.targetNumber = Number(payload?.targetNumber);
    payload.initialNumber = Number(payload?.initialNumber);
    if ((keyResult?._id || keyResult?.id) && !childKeyResult) {
      payload = { ...payload, id: keyResult?._id || keyResult?.id };
      ApiClient.put(`okr`, payload).then((res) => {
        if (res.success) {
          document.getElementById("CloseKeyResultModal").click();
          getData({}, false);
          getOKRListing();
          detailModal(detail?.id || detail?._id, false);
          setOKRFilter("keyResult");
        }
        loader(false);
      });
    } else {
      payload = {
        ...payload,
        keyType: "key_result",
        parentId: childKeyResult || detail?.id || detail?._id || null,
        initialNumber: payload?.initialNumber || 0,
        newValue: payload?.initialNumber || 0,
      };

      ApiClient.post("okr", payload).then((res) => {
        if (res.success) {
          getData({}, false);
          getOKRListing();
          setOKRFilter("keyResult");
          detailModal(detail?.id || detail?._id, false);
          toast("New Key Result added! Let's measure success together! 📊", {
            position: "bottom-right",
            autoClose: 4000,
          });
          document.getElementById("CloseKeyResultModal").click();
        }
        loader(false);
      });
    }
  };

  const handleUpdateKeyResult = (e) => {
    e.preventDefault();
    let payload = {
      newValue: updateKeyResult?.newValue,
      confidenceLevel: updateKeyResult?.confidenceLevel,
      note: updateKeyResult?.note,
      dateOfUpdate: updateKeyResult?.dateOfUpdate,
      okrId: updateKeyResult?.okrId || null,
      id: updateKeyResult?.keyResultId,
      goal: updateKeyResult?.goal,
    };

    ApiClient.put(`okr`, payload).then((res) => {
      if (res.success) {
        getData({}, false);
        getOKRListing();
        detailModal(detail?.id || detail?._id, false);
        document.getElementById("CloseUpdateKeyResultModal").click();
        if (payload?.newValue == updateKeyResult?.goal) {
          toast(translate("victory"), {
            position: "bottom-right",
            autoClose: 4000,
          });
          var duration = 15 * 1000;
          var animationEnd = Date.now() + duration;
          var defaults = {
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            zIndex: 0,
          };

          function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
          }

          var interval = setInterval(function () {
            var timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
              return clearInterval(interval);
            }

            var particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti({
              ...defaults,
              particleCount,
              origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
            });
            confetti({
              ...defaults,
              particleCount,
              origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
            });
          }, 250);
        } else {
          toast("Another step closer to success! 👣", {
            position: "bottom-right",
            autoClose: 4000,
          });
        }
      }
    });
  };
  useEffect(() => {
    {
      getChildData();
    }
  }, []);

  const findUnitSymbol = (item) => {
    if (item?.unit == "%") return "";
    else {
      if (!item?.unit || item?.unit == "not set") return "";
      else return item?.unit;
    }
  };

  const getChildData = (p = {}, load = true) => {
    let filt = {
      ...filter,

      // sessionId: sessionId || getsessionId,keyType:"okr",
      ...p,
    };
    if (user?.role != "employee") {
      filt = { ...filt, addedBy: user?.id || user?._id };
    }

    loader(true);
    // setLoadingState(true);
    ApiClient.get(`okrs`, filt).then((res) => {
      // setLoadingState(false);
      if (res.success) {
        setdata([...res?.data]);

        // settotal(res?.total);
        // setchild(res?.data.childObjective);
        if (detail?.id || detail?._id) {
          setdetail(
            res?.data?.find(
              (item) => item?._id == detail?.id || item?._id == detail?._id
            )
          );
        }
      }
      loader(false);
    });
  };

  const teamsArray = (type = "") => {
    let arr = owners || [];
    let ownerIds = form.ownerIds || [];
    let contributors = form.contributors || [];
    let exist = [...ownerIds, ...contributors];
    if (type == "ownerIds")
      exist = exist.filter((itm) => !ownerIds.includes(itm));
    if (type == "contributors")
      exist = exist.filter((itm) => !contributors.includes(itm));

    arr = arr.filter((itm) => !exist.includes(itm.id));
    return arr;
  };

  const confidenceLevelClass = (item) => {
    let value = `${
      item?.confidenceLevel == "low" || item?.confidenceLevel == ""
        ? "red-color"
        : item?.confidenceLevel == "medium"
        ? "orange-color"
        : item?.confidenceLevel == "high"
        ? "green-color"
        : ""
    }`;

    if (item?.keyType == "child_objective" || item?.keyType == "okr") {
      value = "";
    }

    return value;
  };

  const keyResultPercent = (item) => {
    return methodModel.keyResultPercent(item);
  };

  const calculatePercentage = (currentValue, newValue, goal) => {
    // Calculate the difference between new and current values
    const difference = newValue - currentValue;

    // Calculate the percentage of progress towards the goal
    const percentage = (difference / goal) * 100;

    // Ensure the percentage is between 0 and 100
    return Math.min(Math.max(percentage, 0), 100);
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(email)
        ? prevSelected.filter((e) => e !== email)
        : [...prevSelected, email]
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (detail && detail.title) {
      setMessage(`Check out our progress on OKR ${detail.title}`);
    }
  }, [detail]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const filteredEmployees = employeeData.filter((employee) =>
    employee.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSendToTeams = () => {
    const topicName = encodeURIComponent(
      "OKR: " + (detail?.title || "Default Topic") // Customize message topic / name here
    );
    const encodedMessage = encodeURIComponent(message); // Use the state variable

    const users = selectedEmployees.join(",");
    const url = `https://teams.microsoft.com/l/chat/0/0?users=${users}&topicName=${topicName}&message=${encodedMessage}`;
    window.open(url, "_blank");
  };

  // this is the code part to fetch owners for the send to teams window ,
  //  it only does it for this window for the moment but can be moved into index if needed elsewhere
  const [rowners, setOwners] = useState([]);

  useEffect(() => {
    fetchUserOwners();
  }, []);

  const fetchUserOwners = () => {
    ApiClient.get(
      `users/list?status=active&addedBy=${user?.id || user?._id}`
    ).then((res) => {
      if (res.success) {
        // Store the entire user object
        let arr = res?.data?.map((item) => {
          return {
            ...item,
            id: item?.id || item?._id,
            value: item?.id || item?._id,
            label: item?.fullName || item?.firstName,
          };
        });

        setOwners(arr);
      }
    });
  };

  return (
    <>
      {/* Full Side Screen Modal */}
      <button
        className="btn btn-primary d-none"
        id="OpenFullSideScreenModal"
        data-toggle="modal"
        data-target="#fullSideScreenModal"
      >
        OKR Detail
      </button>
      <div
        className="modal fade drawer right-align"
        id="fullSideScreenModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fullSideScreenModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content fullSideScreenModal modal-lg">
            <div className="modal-header align-items-center">
              <h5
                className="d-flex align-items-center"
                id="fullSideScreenModalLabel"
              >
                <Tooltip title="Objective" placement="top">
                  <span className="mr-2 objective-img">
                    {/* <LuCircleDotDashed /> */}
                    {detail?.keyType == "child_objective" ||
                    detail?.keyType == "okr" ? (
                      <span className="objective-img ">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                          <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                          <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                          <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                          <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                          <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                          <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                          <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                          <circle cx="12" cy="12" r="1"></circle>
                        </svg>
                      </span>
                    ) : (
                      <span className="key-img">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path fill="none" d="M0 0h24v24H0V0z"></path>
                          <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                        </svg>
                      </span>
                    )}
                  </span>
                </Tooltip>{" "}
                {detail?.title}
              </h5>
              <div className="d-flex">
                <div className="dropdowns_custom">
                  <div className="dropdown">
                    <span
                      className="text-dark p-2"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i
                        className="fa fa-ellipsis-h text-dark"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <div className="dropdown-menu">
                      <span
                        className="dropdown-item pointer"
                        onClick={(e) => {
                          if (detail.keyType == "child_objective")
                            handleAction("Child Objective", detail);
                          else handleAction("Edit", detail?._id || detail?.id);
                        }}
                      >
                        <i className="fa fa-pen mr-2"></i>
                        Edit
                      </span>
                      {/* {user?.role != "employee" && ( */}
                      <span
                        className="dropdown-item text-danger pointer"
                        onClick={(e) => handleAction("Delete", detail?.id)}
                      >
                        <i className="fa fa-trash mr-2"></i>
                        Delete
                      </span>
                      {/* )} */}
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  id="CloseFullSideScreenModal"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label className="">{translate("session")}</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label className="text-capitalize">
                        {detail?.sessionTitle?.title || detail?.sessionTitle}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label className="">{translate("owner")}</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label className="">
                        {detail?.ownerDetails?.map((item) => {
                          return (
                            <div className="ui-chip">
                              <span className="ui-chip-icon ui-chip-icon-bg">
                                <img
                                  src={methodModel.userImg(item?.image)}
                                  width=""
                                />
                              </span>
                              <p className="mb-0">
                                {item?.fullName || item?.name}
                              </p>
                            </div>
                          );
                        })}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label className="">{translate("contributor")}</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label className="">
                        {detail?.contributorDetails?.map((item) => {
                          return (
                            <div className="ui-chip">
                              <span className="ui-chip-icon ui-chip-icon-bg">
                                <img
                                  src={methodModel.userImg(item?.image)}
                                  width=""
                                />
                              </span>
                              <p className="mb-0">
                                {item?.fullName || item?.name}
                              </p>
                            </div>
                          );
                        })}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label className="">{translate("whyNow")}</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <div className="reasoning-border">
                        <label className="">{detail?.whyNow || "--"}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="overall-progress-main">
                    <h4>{translate("overallProgress")}</h4>
                    <span className="progressCircle">
                      <CircularProgressbar
                        value={findOKRProgress(
                          detail?.keyResults,
                          "okr from child modal"
                        ).replace("%", "")}
                        text={`${findOKRProgress(
                          detail?.keyResults,
                          "okr from child modal"
                        )}`}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="py-2 border-bottom mt-3">
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <h5 className="mb-0">{translate("keyResults")}</h5>
                  <div>
                    {/* {user?.role != "employee" && ( */}
                    <button
                      className="btn btn-primary btn-small"
                      id="OpenKeyResultModal"
                      onClick={() => {
                        setchildKeyResult("");
                        clearKeyResultModal();
                      }}
                      data-toggle="modal"
                      data-target="#KeyResultModal"
                    >
                      <i className="fa fa-plus me-2"></i>
                      {translate("addkeyResult")}
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>
              <div className="">
                <table
                  className="table key-result-table object-tb"
                  valign="middle"
                >
                  <tbody>
                    {detail &&
                      detail?.keyResults?.map((item) => {
                        return (
                          <>
                            <tr>
                              <td
                                scope="row"
                                className="result-title"
                                valign="middle"
                              >
                                <div className="d-flex align-items-center">
                                  <span className="objective-img">
                                    {/* <LuCircleDotDashed /> */}
                                    {item?.keyType == "child_objective" ||
                                    item?.keyType == "okr" ? (
                                      <span className="objective-img ">
                                        <svg
                                          stroke="currentColor"
                                          fill="none"
                                          strokeWidth="2"
                                          viewBox="0 0 24 24"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M10.1 2.18a9.93 9.93 0 0 1 3.8 0"></path>
                                          <path d="M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7"></path>
                                          <path d="M21.82 10.1a9.93 9.93 0 0 1 0 3.8"></path>
                                          <path d="M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69"></path>
                                          <path d="M13.9 21.82a9.94 9.94 0 0 1-3.8 0"></path>
                                          <path d="M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7"></path>
                                          <path d="M2.18 13.9a9.93 9.93 0 0 1 0-3.8"></path>
                                          <path d="M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69"></path>
                                          <circle
                                            cx="12"
                                            cy="12"
                                            r="1"
                                          ></circle>
                                        </svg>
                                      </span>
                                    ) : (
                                      <span className="key-img">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill="none"
                                            d="M0 0h24v24H0V0z"
                                          ></path>
                                          <path d="M17 4h3v16h-3V4zM5 14h3v6H5v-6zm6-5h3v11h-3V9z"></path>
                                        </svg>
                                      </span>
                                    )}
                                  </span>
                                  <span
                                    className={`progressDiv mx-2 ${confidenceLevelClass(
                                      item
                                    )}`}
                                  >
                                    {keyResultPercent(item)}%
                                  </span>
                                  <p className="mb-0">{item?.title}</p>
                                </div>

                                {OKRFilter == "keyResult" ? (
                                  <span className="small-label">
                                    {translate("shouldMoveto")}{" "}
                                    {findUnitSymbol()}
                                    {item?.targetNumber}
                                    {item?.unit == "%" ? "%" : ""},{" "}
                                    {translate("currentProgress")}{" "}
                                    {findUnitSymbol(item)}
                                    {item?.newValue || item?.initialNumber}
                                    {item?.unit == "%" ? "%" : ""}
                                  </span>
                                ) : null}
                              </td>
                              {OKRFilter == "keyResult" ? (
                                <td valign="middle">
                                  <span
                                    className={`p-2 badges-color ${
                                      item?.confidenceLevel == "low" ||
                                      item?.confidenceLevel == ""
                                        ? "red-color"
                                        : item?.confidenceLevel == "medium"
                                        ? "orange-color"
                                        : "green-color"
                                    } text-capitalize`}
                                  >
                                    {item?.confidenceLevel == "low" ? (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                          <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                        </svg>
                                        {translate("offTrack")}{" "}
                                      </span>
                                    ) : item?.confidenceLevel == "medium" ? (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="none"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
                                            fill="currentColor"
                                          ></path>
                                          <path
                                            d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
                                            fill="currentColor"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                        {translate("atRisk")}
                                      </span>
                                    ) : item?.confidenceLevel == "high" ? (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M12 2.5a9.5 9.5 0 1 0 0 19 .75.75 0 0 1 0 1.5C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11a.75.75 0 0 1-1.5 0A9.5 9.5 0 0 0 12 2.5Z"></path>
                                          <path d="m13.759 17.48 3.728 3.314a.308.308 0 0 0 .513-.23V18h4.25a.75.75 0 0 0 0-1.5H18v-2.564a.308.308 0 0 0-.513-.23L13.76 17.02a.308.308 0 0 0 0 .46ZM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                                        </svg>
                                        {translate("onTrack")}
                                      </span>
                                    ) : (
                                      <span className="badges-diff">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 24 24"
                                          className="me-1"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M2.5 12a9.5 9.5 0 1 1 19 0 .75.75 0 0 0 1.5 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11a.75.75 0 0 0 0-1.5A9.5 9.5 0 0 1 2.5 12Z"></path>
                                          <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2.5 2.75a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7a.75.75 0 0 1-.75-.75Zm3.75 2.75a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"></path>
                                        </svg>
                                        {translate("offTrack")}
                                      </span>
                                    )}
                                  </span>
                                </td>
                              ) : null}
                              <td valign="middle">
                                <span className="img-width-result">
                                  {item?.ownerDetails?.map((itm) => {
                                    return (
                                      <>
                                        <Tooltip
                                          title={itm?.name}
                                          placement="top"
                                        >
                                          <img
                                            src={methodModel.userImg(
                                              itm?.image
                                            )}
                                            width="25px"
                                          />
                                        </Tooltip>
                                      </>
                                    );
                                  })}
                                </span>
                              </td>
                              <td>
                                <div className="progreebar-main">
                                  <div className="progress">
                                    <div
                                      className="progress-bar progress-bar-striped"
                                      role="progressbar"
                                      style={{
                                        width: `${keyResultPercent(item)}%`,
                                      }}
                                      aria-valuenow="10"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              </td>
                              {OKRFilter == "keyResult" ? (
                                <td>
                                  {/* {user?.role != "employee" && ( */}
                                  <div className="text-center">
                                    <button
                                      id="UpdateKeyResultModal201"
                                      className="btn key-btn update-btn text-center btn-font"
                                      style={{ width: "80px" }}
                                      onClick={(e) => {
                                        setNewValue(
                                          item?.newValue || item?.initialNumber
                                        );
                                        setNewValueUnit(item?.unit);
                                        handleUpdateKeyResultModal(item);
                                        clearKeyResultModal();
                                      }}
                                      data-toggle="modal"
                                      data-target="#UpdateKeyResultModal"
                                    >
                                      {translate("checkin")}
                                    </button>
                                  </div>
                                  {/* )} */}
                                </td>
                              ) : null}
                              <td>
                                {/* {user?.role != "employee" && ( */}
                                <div className="dropdowns_custom ">
                                  <div className="dropdown">
                                    <span
                                      className="text-dark"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fa fa-ellipsis-h text-dark"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <div className="dropdown-menu">
                                      {OKRFilter == "keyResult" ? (
                                        <span
                                          className="dropdown-item pointer"
                                          onClick={(e) =>
                                            handleKeyResultAction(
                                              "Child Objective",
                                              item
                                            )
                                          }
                                        >
                                          <i className="fa fa-plus mr-1"></i>
                                          {translate("addChild")}
                                        </span>
                                      ) : (
                                        <span
                                          className="dropdown-item pointer"
                                          onClick={(e) =>
                                            handleKeyResultAction(
                                              "Child Key Result",
                                              item
                                            )
                                          }
                                        >
                                          <i className="fa fa-plus mr-1"></i>
                                          {translate("addkeyResult")}
                                        </span>
                                      )}
                                      {OKRFilter == "keyResult" ? (
                                        <span
                                          className="dropdown-item pointer"
                                          onClick={(e) => {
                                            setNewValue(
                                              item?.newValue ||
                                                item?.initialNumber
                                            );
                                            setNewValueUnit(item?.unit);
                                            handleUpdateKeyResultModal(item);
                                          }}
                                          data-toggle="modal"
                                          data-target="#UpdateKeyResultModal"
                                        >
                                          <i className="fa fa-pen mr-1"></i>
                                          {translate("edit")}
                                        </span>
                                      ) : null}
                                      <span
                                        className="dropdown-item text-danger pointer"
                                        onClick={(e) =>
                                          handleKeyResultAction(
                                            "Delete",
                                            item?._id || item?.id
                                          )
                                        }
                                      >
                                        <i className="fa fa-trash mr-1"></i>
                                        {translate("delete")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-links">
              <div className="align-items-center border-bottom">
                {/* <div className="d-flex align-items-center justify-content-between">
                  <h5 className="">Collaborate & Share</h5>
                </div> */}
                <span className="small-label ">{translate("keepAligned")}</span>
              </div>
              <div className="row-buttons align-items-center">
                <button
                  className="btn btn-secondary btn-small mr-2"
                  id="UpdateKeyResultModal201"
                  // onClick={() => {
                  //   setMessage(message);
                  // }}
                  data-toggle="modal"
                  data-target="#AddEmailModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-microsoft-teams"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0m-2.238 9.488-.12-.002a5.2 5.2 0 0 0 .381-2.07V6.306a1.7 1.7 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.6 2.6 0 0 1-2.598 2.598z" />
                    <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.68.68 0 0 1-.682.682H.682A.68.68 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945z" />
                  </svg>{" "}
                  {translate("shareVia")} Teams
                </button>

                <button
                  className="btn btn-secondary btn-small"
                  onClick={() => {
                    const subject = encodeURIComponent(
                      "Check out our progress on OKR: " +
                        (detail?.title || "Default Topic")
                    );
                    const body = encodeURIComponent(
                      "Check out our progress on OKR " + (detail?.title || "")
                    );
                    const recipients = "";
                    const mailtoUrl = `mailto:${recipients}?subject=${subject}&body=${body}`;
                    window.open(mailtoUrl, "_blank");
                  }}
                >
                  <i className="fa fa-envelope me-2"></i>{" "}
                  {translate("shareVia")} Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Key Result Modal */}
      <div
        className="modal fade create-key-result"
        id="KeyResultModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="KeyResultModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="KeyResultModalLabel">
                    {keyResult?._id || keyResult?.id
                      ? translate("edit")
                      : translate("create")}{" "}
                    {translate("keyResult")}
                  </h3>
                  <p className="mb-1">
                    {translate("defineOutcome")} (
                    <span className="text-danger">*</span>).
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <form onSubmit={handleCreateKeyResult}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-3 my-2 custom-height bar d-flex flex-column justify-content-center">
                    <label>
                      {translate("okrTitle")}{" "}
                      <span className="text-danger">*</span>
                      {/* <br />
                        <small className="text-muted">
                          Provide a brief, action-oriented title that clearly
                          states the objective of this key result.
                        </small> */}
                    </label>
                  </div>
                  <div className="col-md-9 my-2 custom-height bar">
                    <input
                      type="text"
                      value={keyResult?.title}
                      onChange={(e) =>
                        setkeyResult({
                          ...keyResult,
                          title: e.target.value,
                        })
                      }
                      maxLength="120"
                      className="form-control"
                      placeholder="Example : Increase monthly active users by 20%"
                      required
                    />
                  </div>

                  <div className="col-md-3 my-2 custom-height bar">
                    <label>
                      {translate("numbers")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-md-9 my-2 custom-height bar">
                    <div className="row">
                      <div className="col-md-4 pr-md-2 mr-2">
                        <input
                          type="number"
                          value={keyResult?.initialNumber}
                          onChange={(e) => {
                            setkeyResult({
                              ...keyResult,
                              initialNumber: e.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Initial number"
                        />
                      </div>
                      <div className="col-md-4 px-md-2 mr-2">
                        <input
                          type="number"
                          value={keyResult?.targetNumber}
                          onChange={(e) => {
                            setkeyResult({
                              ...keyResult,
                              targetNumber: e.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Target number"
                        />
                      </div>
                      <div className="col-md-3 pl-md-2">
                        <select
                          className="form-select"
                          value={keyResult?.unit}
                          onChange={(e) =>
                            setkeyResult({
                              ...keyResult,
                              unit: e.target.value,
                            })
                          }
                        >
                          <option value="">{translate("unit")}</option>
                          <option value="%">%</option>
                          <option value="€">€</option>
                          <option value="$">$</option>
                          <option value="items_no_unit">
                            {translate("items")}
                          </option>
                          <option value="score_no_unit">
                            {translate("score")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 my-2 custom-height bar">
                    <label>{translate("owner")}</label>
                  </div>
                  <div className="col-md-9 my-2 custom-height bar">
                    <div className="multi-select-box">
                      <Select
                        value={keyResult?.ownerIds}
                        isClearable={true}
                        onChange={(e) =>
                          setkeyResult({ ...keyResult, ownerIds: e })
                        }
                        options={owners}
                        className="basic-multi-select"
                        placeholder="Select Owners"
                        isMulti
                      />
                    </div>
                  </div>

                  <div className="col-md-3 my-2 custom-height bar">
                    <label>{translate("targetDate")}</label>
                  </div>
                  <div className="col-md-9 my-2 custom-height bar">
                    <select
                      className="form-select"
                      value={keyResult?.deadline}
                      onChange={(e) =>
                        setkeyResult({
                          ...keyResult,
                          deadline: e.target.value,
                        })
                      }
                    >
                      <option value="no custom deadline">
                        {translate("noTargetDate")}
                      </option>
                      <option value="hard deadline">
                        {translate("hardDeadline")}
                      </option>
                      <option value="hard and soft deadline">
                        {translate("hardSoft")}
                      </option>
                    </select>
                  </div>
                  {keyResult?.deadline != "no custom deadline" ? (
                    <>
                      {keyResult?.deadline == "hard and soft deadline" ? (
                        <>
                          <div className="col-md-3 my-2">
                            <label>{translate("softDeadline")}</label>
                          </div>
                          <div className="col-md-9 my-2">
                            <DatePicker
                              selected={keyResult?.softDeadline}
                              onChange={(date) =>
                                setkeyResult({
                                  ...keyResult,
                                  softDeadline: date,
                                })
                              }
                              className="form-control w-100"
                              dateFormat="dd/MMM/yyyy"
                              placeholderText="Soft deadline"
                              minDate={new Date()}
                            />
                          </div>
                        </>
                      ) : null}
                      <div className="col-md-3 my-2 custom-height">
                        <label>{translate("hardDeadline")}</label>
                      </div>
                      <div className="col-md-9 my-2 custom-height">
                        <DatePicker
                          selected={keyResult?.hardDeadline}
                          onChange={(date) =>
                            setkeyResult({
                              ...keyResult,
                              hardDeadline: date,
                            })
                          }
                          className="form-control w-100"
                          dateFormat="dd/MMM/yyyy"
                          placeholderText="Hard deadline"
                          minDate={keyResult?.softDeadline || new Date()}
                        />
                      </div>
                    </>
                  ) : null}

                  <div className="col-md-3 my-2 ">
                    <label>{translate("okrDescription")}</label>
                  </div>
                  <div className="col-md-9 my-2 custom-height">
                    <textarea
                      value={keyResult?.description}
                      onChange={(e) => {
                        setkeyResult({
                          ...keyResult,
                          description: e.target.value,
                        });
                      }}
                      maxLength="120"
                      className="form-control"
                      placeholder="Enter description"
                      style={{
                        resize: "vertical",
                        overflow: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="">
                  <button
                    type="button"
                    id="CloseKeyResultModal"
                    className="btn btn-secondary mr-2"
                    data-dismiss="modal"
                  >
                    {translate("cancel")}
                  </button>
                  <button
                    type="submit"
                    disabled={!keyResult?.title || !keyResult?.targetNumber}
                    className="btn btn-primary btn-md ml-2"
                  >
                    {translate("add")} {translate("keyResult")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Update Key Result Modal */}
      <div
        className="modal fade"
        id="UpdateKeyResultModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="UpdateKeyResultModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="UpdateKeyResultModalLabel">
                    {translate("checkProgress")}
                  </h3>
                  <p className="mb-1">{translate("doingGreat")}</p>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => clearFields()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <Tooltip title={translate("objectiveProgress")} placement="top">
              <div className="progreebar-main d-flex">
                <div className="percentage-before">
                  <p>{updateKeyResult?.currentValue}</p>
                </div>
                <div
                  className="progress"
                  style={{
                    height: "20px",
                  }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${
                        ((updateKeyResult?.newValue -
                          updateKeyResult?.currentValue) /
                          (updateKeyResult?.goal -
                            updateKeyResult?.currentValue)) *
                        100
                      }%`,
                    }}
                    aria-valuenow={updateKeyResult?.newValue}
                    aria-valuemin={updateKeyResult?.currentValue}
                    aria-valuemax={updateKeyResult?.goal}
                  >
                    <span className="progress-bar-text">
                      {(
                        ((updateKeyResult?.newValue -
                          updateKeyResult?.currentValue) /
                          (updateKeyResult?.goal -
                            updateKeyResult?.currentValue)) *
                        100
                      ).toFixed(1)}
                      %
                    </span>
                  </div>
                </div>
                <div className="percentage-after">
                  <p>{updateKeyResult?.goal}</p>
                </div>
              </div>
            </Tooltip>

            <form onSubmit={handleUpdateKeyResult}>
              <div className="modal-body">
                <div className="">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label>{translate("currentValue")} </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <label>
                        {findUnitSymbol(updateKeyResult)}
                        {valueData}
                        {valueDataUnit == "%" ? "%" : ""},{" "}
                        {translate("shouldMoveto")}{" "}
                        {findUnitSymbol(updateKeyResult)}
                        {updateKeyResult?.goal}
                        {updateKeyResult?.unit == "%" ? "%" : ""}
                      </label>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>{translate("newValue")} </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="number"
                        value={updateKeyResult?.newValue}
                        onChange={(e) =>
                          setupdateKeyResult({
                            ...updateKeyResult,
                            newValue: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Enter new value"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>{translate("confidenceLVL")}</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <ul className="confidence-btns">
                        <li>
                          <button
                            type="button"
                            className={`btn mr-2 ${
                              updateKeyResult?.confidenceLevel == "high" ||
                              updateKeyResult?.confidenceLevel == ""
                                ? "green-color"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "high",
                              })
                            }
                          >
                            <GoIssueTrackedBy className="me-2" />{" "}
                            {translate("onTrack")}{" "}
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={`btn ${
                              updateKeyResult?.confidenceLevel == "medium"
                                ? "orange-color"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "medium",
                              })
                            }
                          >
                            <CgDanger className="me-2" />
                            {translate("atRisk")}
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className={`btn  ${
                              updateKeyResult?.confidenceLevel == "low"
                                ? "red-color"
                                : "light-yello-btn"
                            }`}
                            onClick={(e) =>
                              setupdateKeyResult({
                                ...updateKeyResult,
                                confidenceLevel: "low",
                              })
                            }
                          >
                            <GoIssueTracks className="me-2" />
                            {translate("offTrack")}{" "}
                          </button>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-md-3 my-2">
                      <label>Note</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <textarea
                        className="form-control"
                        value={updateKeyResult?.note.replace(
                          /<\/?[^>]+(>|$)/g,
                          ""
                        )}
                        onChange={(e) => {
                          setupdateKeyResult({
                            ...updateKeyResult,
                            note: e.target.value,
                          });
                        }}
                        placeholder="Enter your note here"
                        maxLength="255"
                      />
                    </div> */}

                    <div className="col-md-3 my-2">
                      <label>{translate("dateUpdate")}</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <DatePicker
                        selected={updateKeyResult?.dateOfUpdate}
                        onChange={(date) => {
                          setupdateKeyResult({
                            ...updateKeyResult,
                            dateOfUpdate: date,
                          });
                          // setkeyResult({
                          //   ...updateKeyResult,
                          //   dateOfUpdate: date,
                          // })
                        }}
                        className="form-control w-100"
                        dateFormat="dd/MMM/yyyy"
                        placeholderText="Date Of Update"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="">
                  <button
                    type="button"
                    id="CloseUpdateKeyResultModal"
                    className="btn btn-secondary mr-2 "
                    data-dismiss="modal"
                  >
                    {translate("cancel")}
                  </button>
                  <button
                    type="submit"
                    disabled={!updateKeyResult?.newValue}
                    className="btn btn-primary btn-md ml-2"
                  >
                    {translate("checkin")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Add email  Modal */}
      <div
        className="modal fade"
        id="AddEmailModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddEmailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="EmailModalLabel">
                    Discuss on Teams
                  </h3>
                  <p className="mb-1">Chat about {detail?.title}</p>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body-emails">
              <div className="">
                <div className="row mb-2">
                  <div className="col-12">
                    <label className="">Message contents</label>
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      placeholder="Check out our progress on OKR ..."
                      value={message}
                      onChange={handleMessageChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="">Recipients</label>
                  </div>

                  <div className="col-6">
                    {" "}
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="employeeDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Select receipients
                      </button>
                      <ul
                        className="dropdown-menu"
                        style={{ width: "265px", overflowY: "auto" }}
                        aria-labelledby="employeeDropdown"
                      >
                        <li className="dropdown-item">
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="form-control"
                          />
                        </li>
                        {rowners.map((employee, index) => (
                          <li
                            key={employee._id}
                            className="dropdown-item d-flex align-items-center"
                          >
                            <input
                              type="checkbox"
                              value={employee.email}
                              onChange={() =>
                                handleCheckboxChange(employee.email)
                              }
                              style={{ marginRight: "5px" }}
                            />
                            <label
                              htmlFor={`checkbox-${employee._id}`}
                              className="d-flex align-items-center"
                            >
                              <img
                                src={
                                  employee.image
                                    ? methodModel.userImg(employee.image)
                                    : "/assets/img/person.jpg"
                                }
                                alt={employee.fullName || "No Owner"}
                                className="rounded-circle me-2"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  objectFit: "cover",
                                }}
                              />
                              {employee.fullName}{" "}
                              {index === employeeData.length - 1 && "(you)"}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="">
                <button
                  type="button"
                  id="CloseAddEmailModal"
                  className="btn btn-secondary mr-2 "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  // disabled={!form.messageContent || form.ownerIds.length === 0}
                  className="btn btn-primary btn-md ml-2"
                  onClick={handleSendToTeams}
                >
                  Discuss on Teams
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* OKRs Modal */}
      <button
        className="btn btn-primary d-none"
        id="CreateOKRModalOpen"
        data-toggle="modal"
        data-target="#OKRsModal"
      >
        Create OKRs
      </button>
      <div
        className="modal fade"
        id="OKRsModal" // OKRsModal
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="OKRsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleCreateOKR}>
            <div className="modal-content">
              <div className="modal-title">
                <div className="p-3 d-flex justify-content-between align-items-start">
                  <div>
                    <h3 className="modal-title " id="ManagerModalLabel">
                      {form?.id ? translate("update") : translate("createNew")}{" "}
                      {translate("objective")}
                    </h3>
                    <p className="mb-1">{translate("captureGoal")}</p>
                  </div>
                  <button
                    type="button"
                    className="close"
                    onClick={(e) => clearFields()}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="row1">
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">
                          {translate("okrTitle")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          value={form?.title}
                          onChange={(e) =>
                            setform({ ...form, title: e.target.value })
                          }
                          maxLength="120"
                          className="form-control"
                          placeholder="Enter Objective title"
                          required
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">
                          {translate("session")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="custom-select-btn">
                          <Select
                            className="text-capitalize"
                            value={form?.session}
                            isClearable={true}
                            onChange={(e) => setform({ ...form, session: e })}
                            options={sessions}
                            placeholder="Select Session"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">
                          {translate("owner")}
                        </label>
                      </div>
                      <div className="col-12">
                        <MultiSelectDropdown
                          intialValue={form?.ownerIds}
                          placeholder="Select Owner"
                          options={teamsArray("ownerIds")}
                          result={(e) => {
                            setform({ ...form, ownerIds: e.value });
                          }}
                          displayValue="label"
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">
                          {translate("contributor")}
                        </label>
                      </div>
                      <div className="col-12">
                        <MultiSelectDropdown
                          intialValue={form?.contributors}
                          placeholder="Select Contributor"
                          options={teamsArray("contributors")}
                          result={(e) => {
                            setform({ ...form, contributors: e.value });
                          }}
                          displayValue="label"
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">
                          {translate("whyNow")}
                        </label>
                      </div>
                      <div className="col-12">
                        <textarea
                          value={form?.whyNow}
                          onChange={(e) =>
                            setform({ ...form, whyNow: e.target.value })
                          }
                          maxLength="255"
                          className="form-control"
                          placeholder={translate("explainBriefly")}
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="col-12 bold-label-down ">
                        {translate("connectParent")}
                      </div>
                      <div className="col-12">
                        <AsyncSelectDropdown
                          api="okrs"
                          option={option}
                          value={form.parent}
                          placeholder={option(childObjective)?.label || ""}
                          filter={{
                            sessionId: sessionId,
                            page: 1,
                            count: 10,
                          }}
                          onChange={(e) => {
                            setform({
                              ...form,
                              parentId: e?.value || null,
                              parent: e,
                            });
                          }}
                        />
                        <p className="hintCls">{translate("searchOKR")}</p>
                      </div>
                    </div>

                    {/* {form?.keyType=='child_objective' ? (
                      <>
                        <div className="col-md-3">Parent</div>
                        <div className="col-md-9">
                          <AsyncSelectDropdown
                          api="okrs"
                          option={option}
                          value={form.parent}
                          placeholder={option(detail?.parentData)?.label||''}
                          filter={{sessionId:sessionId,page:1,count:10}}
                          onChange={(e)=>{
                            setform({ ...form, parentId: e?.value||null,parent:e })
                          }}
                          />
                          
                        </div>
                      </>
                    ) : null} */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={(e) => clearFields()}
                  id="CloseOKRsModal"
                  className="btn btn-secondary mr-2"
                  data-dismiss="modal"
                >
                  {translate("cancel")}
                </button>
                <button
                  type="submit"
                  disabled={!form?.session || !form?.title || disableed}
                  className="btn btn-primary btn-md "
                >
                  {form?.id ? translate("update") : translate("create")} OKR
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className="modal fade"
        id="OKRsAIModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="OKRsAIModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <form onSubmit={handleCreateAIOKR}>
            <div className="modal-content">
              <div className="modal-title">
                <div className="p-3 d-flex justify-content-between align-items-start">
                  <div>
                    <h3 className="modal-title" id="ManagerModalLabel">
                      Create a new objective with AI
                    </h3>
                    <p className="mb-1">
                      Capture your goals effortlessly using AI-powered
                      suggestions!
                    </p>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="row1">
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">
                          Describe your goals
                        </label>
                      </div>
                      <div className="col-12">
                        <textarea
                          value={form?.description}
                          onChange={(e) =>
                            setform({ ...form, description: e.target.value })
                          }
                          maxLength="500"
                          className="form-control1"
                          placeholder="Describe your goal so the AI can create the most acurate OKR for you"
                        />
                      </div>
                    </div>
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">
                          Session <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="custom-select-btn">
                          <Select
                            className="text-capitalize"
                            value={form?.session}
                            isClearable={true}
                            onChange={(e) => setform({ ...form, session: e })}
                            options={sessions}
                            placeholder="Select Session"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row1">
                      <div className="col-12">
                        <label className="bold-label-down">Owner</label>
                      </div>
                      <div className="col-12">
                        <MultiSelectDropdown
                          intialValue={form?.ownerIds}
                          placeholder="Select Owners"
                          options={teamsArray("ownerIds")}
                          result={(e) => {
                            setform({ ...form, ownerIds: e.value });
                          }}
                          displayValue="label"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseOKRsAIModal"
                  className="btn btn-secondary mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  //disabled={!form?.description || disabled}
                  className="btn btn-primary btn-md "
                >
                  Generate OKRs
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* New Modal for Generated OKRs */}
      <div
        className="modal fade"
        id="GeneratedOKRsModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="GeneratedOKRsModalLabel"
        aria-hidden="true"
        style={{ display: showGeneratedOKRsModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="GeneratedOKRsModalLabel">
                    Generated OKRs
                  </h3>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowGeneratedOKRsModal(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <pre>{generatedOKRs}</pre>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowGeneratedOKRsModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalOKR;
