import React from "react";
import ReactDOM from "react-dom";
import Main from "./main";
import * as serviceWorker from "./serviceWorker";

const addGtmScript = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-MB8QCGTK`;
  document.head.appendChild(script);

  const noScript = document.createElement("noscript");
  noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MB8QCGTK" height="0" width="0" style="display:none;visibility:hidden;"></iframe>`;
  document.body.appendChild(noScript);
};

addGtmScript();

ReactDOM.render(<Main />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
