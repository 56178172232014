import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/global/Footer";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

const Demo = () => {
  const username = window.location.pathname; // Get the current path
  const user = { isEmployee: false };

  const getBrowserLocale = () => {
    const language = navigator.language.toLowerCase();
    if (language.includes("fr")) {
      return LOCALES.FRENCH;
    }
    return LOCALES.ENGLISH;
  };

  const locale = getBrowserLocale();
  return (
    <I18nProvider locale={locale}>
      {/* Header Section */}
      <section className="darkbg">
        <nav className="navbar navbar-expand-lg navbar-light homenavs px-5">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/home">
              <img
                src="assets/img/logo-kanam-blue.png"
                className="logo"
                alt=""
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navwhite">
                <li className="nav-item">
                  <Link
                    to="/home"
                    className={
                      username === "/home" ? "nav-link active" : "nav-link"
                    }
                  >
                    {translate("product")}
                  </Link>
                </li>
                {!user?.isEmployee && (
                  <li className="nav-item">
                    <Link
                      to="/plan"
                      className={
                        username === "/plan" ? "nav-link active" : "nav-link"
                      }
                    >
                      {translate("pricing")}
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link
                    to="/aboutus"
                    className={
                      username === "/aboutus" ? "nav-link active" : "nav-link"
                    }
                  >
                    {translate("aboutUs")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="okrDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {translate("resources")}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="okrDropdown">
                    <li>
                      <Link
                        to="/selfassessment"
                        className="dropdown-item"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title={translate("okrMaturityTooltip")}
                      >
                        {translate("okrMaturityAssessment")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>

      {/* Main Content Section */}
      <div
        style={{
          height: "150vh",
          overflow: "hidden",
          backgroundColor: "#f2f2ff",
        }}
      >
        <iframe
          src="https://outlook.office.com/owa/calendar/DemoKanam@darewell.co/bookings/"
          width="100%"
          height="100%"
        ></iframe>
      </div>

      {/* Footer Section */}
      <Footer />
    </I18nProvider>
  );
};

export default Demo;
