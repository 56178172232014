import React, { useEffect } from "react";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import configureStore from "./config";
import { createBrowserHistory } from "history";
import Login from "./pages/Login";
import "react-pagination-js/dist/styles.css";
import "./scss/main.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import 'react-circular-progressbar/dist/styles.css';
import SignUp from "./pages/Signup";
import Forgotpassword from "./pages/Forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import Dashboard from "./pages/DashboardPages";
import { Auth } from "./methods/auth";
import Profile from "./pages/Profile";
import EditProfile from "./components/Profile/Edit";
import ChangePassword from "./components/Profile/ChangePassword";
export const history = createBrowserHistory();
import OTPpage from "./pages/Signup/OTPpage";
import Plan from "./pages/Plan/Plan";
import TeamListing from "./pages/Teams/TeamListing";
import ManagerListing from "./pages/Manager/ManagerListing";
import EmployeesListing from "./pages/Employees/EmployeesListing";
import EmployeeDetail from "./pages/Employees/EmployeeDetail";
import ManagerDetail from "./pages/Manager/ManagerDetail";
import TeamDetail from "./pages/Teams/TeamDetail";
import OKRs from "./pages/OKRs/OKRs";
import SessionsListing from "./pages/Sessions/SessionsListing";
import OkrTeam from "./pages/OkrTeam/OkrTeam";
import Billings from "./pages/Billing/Billing";
import BillingDetails from "./pages/Billing/BillingDetails";
import AccountDomain from "./pages/Billing/AccountDomain";
import Dashboardss from "./pages/DashboardPages/dashboard";
import SetPassword from "./pages/CreatePasswordInvites/SetPassword";
import About from "./pages/AboutUs/About";
import OkrSelfAssessment from "./pages/OkrSelfAssessment/SelfAssessment";
import TeamOrg from "./pages/Teams/TeamOrg";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Onboarding from "./pages/Onboarding/index";
import Demo from "./pages/ScheduleDemo/Demo";

const { persistor, store } = configureStore(history);

export default () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Router>
              <Switch>
                <Route
                  exact={true}
                  store={store}
                  path="/login"
                  component={Login}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/otppage"
                  component={OTPpage}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/home"
                  component={Dashboard}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/aboutus"
                  component={About}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/dashboard"
                  component={Dashboardss}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/plan"
                  component={Plan}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/selfassessment"
                  component={OkrSelfAssessment}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/demo"
                  component={Demo}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/profile"
                  component={Profile}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/profile/edit"
                  component={EditProfile}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/profile/change-password"
                  component={ChangePassword}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/signup"
                  component={SignUp}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/forgotpassword"
                  component={Forgotpassword}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/resetpassword"
                  component={Resetpassword}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/setpassword"
                  component={SetPassword}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/privacy"
                  component={Privacy}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/terms"
                  component={Terms}
                />
                {/* Team Routes */}
                <Route
                  exact={true}
                  store={store}
                  path="/teams"
                  component={TeamListing}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/teamorg"
                  component={TeamOrg}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/team/:id"
                  component={TeamDetail}
                />
                {/* Manager Routes */}
                <Route
                  exact={true}
                  store={store}
                  path="/managers"
                  component={ManagerListing}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/manager/:id"
                  component={ManagerDetail}
                />
                {/* Employee Routes */}
                <Route
                  exact={true}
                  store={store}
                  path="/employees"
                  component={EmployeesListing}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/employee/:id"
                  component={EmployeeDetail}
                />
                {/* OKRs Routes */}
                <Route
                  exact={true}
                  store={store}
                  path="/sessions/okrs/:id"
                  component={OKRs}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/sessions/okrs"
                  component={OKRs}
                />
                {/* Sessions Routes */}
                <Route
                  exact={true}
                  store={store}
                  path="/sessions"
                  component={SessionsListing}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/okr-teams/:id"
                  component={OkrTeam}
                />
                {/* Billings */}
                <Route
                  exact={true}
                  store={store}
                  path="/billing"
                  component={Billings}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/billing-details"
                  component={BillingDetails}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/Account-Domain"
                  component={AccountDomain}
                />
                <Route exact path="/onboarding" component={Onboarding} />{" "}
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
              </Switch>
            </Router>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastsContainer
        position={ToastsContainerPosition.TOP_RIGHT}
        store={ToastsStore}
      />
    </>
  );
};
