import React, { useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../../actions/user";
import Footer from "../Footer";
import methodModel from "../../../methods/methods";

const PageLayout = ({ children }) => {
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const { username } = window.location.pathname;
  const history = useHistory();
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    localStorage.removeItem("sessionID");
    localStorage.removeItem("sessionNAme");
    localStorage.removeItem("CreatedAt");
    history.push("/login");
  };

  return (
    <>
      <section className="darkbg ">
        <nav className="navbar navbar-expand-lg navbar-light homenavs px-5">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/home">
              <img
                src="assets/img/logo-kanam-blue.png"
                className="logo"
                alt=""
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navwhite">
                <li className="nav-item">
                  <Link
                    to="/home"
                    className={
                      username === "/home" ? "nav-link active" : "nav-link"
                    }
                  >
                    Product
                  </Link>
                </li>
                {!user?.isEmployee && (
                  <li className="nav-item">
                    <Link
                      to="/plan"
                      className={
                        username === "/plan" ? "nav-link active" : "nav-link"
                      }
                    >
                      Pricing
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link
                    to="/aboutus"
                    className={
                      username === "/aboutus" ? "nav-link active" : "nav-link"
                    }
                  >
                    About Us
                  </Link>
                </li>
              </ul>
              {token ? (
                <div className="dropdown">
                  <a
                    data-toggle="dropdown"
                    className="nav-link dropdown-toggle profile-after nav-link-user text-dark d-flex align-items-center"
                  >
                    <img
                      alt="image"
                      src={methodModel.userImg(user.image)}
                      className="rounded-circle mr-1"
                      width="50px"
                    />
                    <div className="ml-1 nameFont pointer ">
                      <b className="text-light text-capitalize">
                        {user.companyName}
                      </b>
                      <p className="grayCls mb-0 text-capitalize">
                        {user.role?.name}
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover">
                    <Link to="/profile" className="dropdown-item has-icon">
                      <i className="far fa-user" /> Profile
                    </Link>
                    <Link
                      to="/profile?tab=change-password"
                      className="dropdown-item has-icon"
                    >
                      <i className="fa fa-cog" aria-hidden="true"></i> Change
                      Password
                    </Link>
                    <a
                      onClick={() => Logout()}
                      className="dropdown-item has-icon"
                    >
                      <i className="fas fa-sign-out-alt" /> Logout
                    </a>
                  </div>
                </div>
              ) : (
                <form className="d-flex">
                  <button
                    className="btn-white-border me-3"
                    onClick={(e) => {
                      history.push(`/login`);
                    }}
                    type="button"
                  >
                    Login
                  </button>
                </form>
              )}
            </div>
          </div>
        </nav>
      </section>
      <div className="pageLayout py-3">{children}</div>
      <Footer />
    </>
  );
};
export default PageLayout;
