import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { I18nProvider, LOCALES } from "../../../i18n";
import translate from "../../../i18n/translate";

const getBrowserLocale = () => {
  const language = navigator.language.toLowerCase();
  if (language.includes("fr")) {
    return LOCALES.FRENCH;
  }
  return LOCALES.ENGLISH;
};

const locale = getBrowserLocale();

export default function Footer() {
  return (
    <>
      <I18nProvider locale={locale}>
        <div className="footer_section py-5 ">
          <div className="container">
            <div className="box_flex">
              <div className="items_footer">
                <div className="mb-3">
                  <Link to="/">
                    <img
                      src="assets/img/images/footer_logo.png"
                      className="footer_logo_bx"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="items_footer">
                <div className="mb-3">
                  <h5 className="footer_heading">{translate("company")}</h5>
                  <ul className="list-group footer_list_items">
                    <li className="list-group-item">
                      <Link to="/aboutus">{translate("aboutUs")}</Link>
                    </li>
                    <li className="list-group-item">
                      <a href="#">{translate("product")}</a>
                    </li>
                    <li className="list-group-item">
                      <Link to="/plan">{translate("pricing")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="items_footer">
                <div className="mb-3">
                  <h5 className="footer_heading">{translate("support")}</h5>
                  <ul className="list-group footer_list_items">
                    <li className="list-group-item">
                      <Link to="/home?section=faqs">FAQ</Link>
                    </li>
                    <li className="list-group-item">
                      <Link to="/privacy">{translate("privacyPolicy")}</Link>
                    </li>
                    <li className="list-group-item">
                      <Link to="/terms">{translate("termsOfService")}</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="items_footer">
                <div className="mb-3">
                  <h4 className="Subscribe_heading">
                    {translate("subscribeNewsletter")}
                  </h4>
                  <p className="footer_signup">
                    {translate("subscribeDescription", {
                      br: () => <br />,
                    })}
                  </p>

                  <div className="position-relative ">
                    <svg
                      className="email_svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1_39)">
                        <path
                          d="M2.33335 3H15.6667C16.5834 3 17.3334 3.75 17.3334 4.66667V14.6667C17.3334 15.5833 16.5834 16.3333 15.6667 16.3333H2.33335C1.41669 16.3333 0.666687 15.5833 0.666687 14.6667V4.66667C0.666687 3.75 1.41669 3 2.33335 3Z"
                          stroke="#39364E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17 5L9 11L1 5"
                          stroke="#39364E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_39">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <input
                      type="email"
                      className="form-control enter_mailbx"
                      id="exampleInputEmail1"
                      placeholder={translate("enterYourEmail")}
                      aria-describedby="emailHelp"
                    />
                    <button className="footer_subscribe_now">
                      {translate("subscribeNow")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-center">
                <p className="copy_footer">{translate("copyright")}</p>
              </div>
            </div>
          </div>
          <ToastContainer position="top-right" />
        </div>
      </I18nProvider>
    </>
  );
}
