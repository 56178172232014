import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import PageLayout from "../../components/global/PageLayout";

import "./privacy.scss";
import translate from "../../i18n/translate";

export default function Privacy() {
  const [detail, setDetail] = useState();

  // const getDetail = () => {
  //   loader(true);
  //   const language = navigator.language || "en";
  //   ApiClient.get("content", { slug: "privacy-policies", lang: language }).then(
  //     (res) => {
  //       if (res.success) {
  //         setDetail(res.data);
  //       }
  //       loader(false);
  //     }
  //   );
  // };
  // useEffect(() => {
  //   getDetail();
  // }, []);

  {
    /* <h1 className="mb-3">{detail?.title}</h1>
this goes into the pagelayout to make it dynamic based off bdd
          <div dangerouslySetInnerHTML={{ __html: detail?.description }}></div> */
  }

  return (
    <>
      <PageLayout>
        <div className="privacy-page-container">
          <h1 className="privacy-title">{translate("privacyPolicyTitle")}</h1>
          <p className="privacy-last-updated">
            {translate("privacyLastUpdated", { date: "2025-04-01" })}
          </p>
          <a
            href="https://kanam.io/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="privacy-link"
          >
            {translate("privacyLinkText")}
          </a>

          <section className="privacy-section">
            <h2 className="privacy-heading">
              {translate("privacyDataControllerTitle")}
            </h2>
            <p className="privacy-text">
              <strong>{translate("privacyDataControllerNameLabel")}</strong>{" "}
              Darewell
              <br />
              <strong>
                {translate("privacyDataControllerAddressLabel")}
              </strong>{" "}
              8 allée de la chapelle, 74940 Annecy Le Vieux, France
              <br />
              <strong>
                {translate("privacyDataControllerSiretLabel")}
              </strong>{" "}
              910.432.152
              <br />
              <strong>{translate("privacyDataControllerDpoLabel")}</strong>{" "}
              <a href="mailto:dpo@kanam.io" className="privacy-email">
                Fouad Zein – dpo@kanam.io
              </a>
            </p>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">
              {translate("privacyDataWeCollectTitle")}
            </h2>
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>{translate("privacyTableCategory")}</th>
                  <th>{translate("privacyTableDataTypes")}</th>
                  <th>{translate("privacyTablePurpose")}</th>
                  <th>{translate("privacyTableLegalBasis")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translate("privacyTableAccountData")}</td>
                  <td>{translate("privacyTableAccountDataTypes")}</td>
                  <td>{translate("privacyTableAccountPurpose")}</td>
                  <td>{translate("privacyTableAccountLegalBasis")}</td>
                </tr>
                <tr>
                  <td>{translate("privacyTableBehavioralProfiles")}</td>
                  <td>
                    {translate("privacyTableBehavioralProfilesDataTypes")}
                  </td>
                  <td>{translate("privacyTableBehavioralProfilesPurpose")}</td>
                  <td>
                    {translate("privacyTableBehavioralProfilesLegalBasis")}
                  </td>
                </tr>
                <tr>
                  <td>{translate("privacyTableOkrData")}</td>
                  <td>{translate("privacyTableOkrDataTypes")}</td>
                  <td>{translate("privacyTableOkrPurpose")}</td>
                  <td>{translate("privacyTableOkrLegalBasis")}</td>
                </tr>
                <tr>
                  <td>{translate("privacyTablePaymentData")}</td>
                  <td>{translate("privacyTablePaymentDataTypes")}</td>
                  <td>{translate("privacyTablePaymentPurpose")}</td>
                  <td>{translate("privacyTablePaymentLegalBasis")}</td>
                </tr>
                <tr>
                  <td>{translate("privacyTableAiFeatures")}</td>
                  <td>{translate("privacyTableAiFeaturesDataTypes")}</td>
                  <td>{translate("privacyTableAiFeaturesPurpose")}</td>
                  <td>{translate("privacyTableAiFeaturesLegalBasis")}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">
              {translate("privacyHowWeUseDataTitle")}
            </h2>
            <ul className="privacy-list">
              <li>{translate("privacyHowWeUseDataItem1")}</li>
              <li>{translate("privacyHowWeUseDataItem2")}</li>
              <li>{translate("privacyHowWeUseDataItem3")}</li>
              <li>{translate("privacyHowWeUseDataItem4")}</li>
              <li>{translate("privacyHowWeUseDataItem5")}</li>
            </ul>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">
              {translate("privacyDataRetentionTitle")}
            </h2>
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>{translate("privacyTableDataType")}</th>
                  <th>{translate("privacyTableRetentionPeriod")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translate("privacyTableAccountData")}</td>
                  <td>{translate("privacyTableAccountRetention")}</td>
                </tr>
                <tr>
                  <td>{translate("privacyTableBehavioralProfiles")}</td>
                  <td>
                    {translate("privacyTableBehavioralProfilesRetention")}
                  </td>
                </tr>
                <tr>
                  <td>{translate("privacyTableOkrData")}</td>
                  <td>{translate("privacyTableOkrRetention")}</td>
                </tr>
                <tr>
                  <td>{translate("privacyTablePaymentData")}</td>
                  <td>{translate("privacyTablePaymentRetention")}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">
              {translate("privacyDataSharingTitle")}
            </h2>
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>{translate("privacyTableRecipient")}</th>
                  <th>{translate("privacyTablePurpose")}</th>
                  <th>{translate("privacyTableLocation")}</th>
                  <th>{translate("privacyTableSafeguards")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translate("privacyTableCompanyMembers")}</td>
                  <td>{translate("privacyTableCompanyMembersPurpose")}</td>
                  <td>{translate("privacyTableCompanyMembersLocation")}</td>
                  <td>{translate("privacyTableCompanyMembersSafeguards")}</td>
                </tr>
                <tr>
                  <td>{translate("privacyTableStripe")}</td>
                  <td>{translate("privacyTableStripePurpose")}</td>
                  <td>{translate("privacyTableStripeLocation")}</td>
                  <td>{translate("privacyTableStripeSafeguards")}</td>
                </tr>
                <tr>
                  <td>{translate("privacyTableOpenAi")}</td>
                  <td>{translate("privacyTableOpenAiPurpose")}</td>
                  <td>{translate("privacyTableOpenAiLocation")}</td>
                  <td>{translate("privacyTableOpenAiSafeguards")}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="privacy-section">
            <h2 className="privacy-heading">
              {translate("privacyYourRightsTitle")}
            </h2>
            <ul className="privacy-list">
              <li>{translate("privacyYourRightsItem1")}</li>
              <li>{translate("privacyYourRightsItem2")}</li>
              <li>{translate("privacyYourRightsItem3")}</li>
              <li>{translate("privacyYourRightsItem4")}</li>
              <li>{translate("privacyYourRightsItem5")}</li>
            </ul>
            <p className="privacy-text">
              {translate("privacyYourRightsContact", {
                email: "support@kanam.io",
              })}
            </p>
          </section>
          <section className="privacy-section">
            <h2 className="privacy-heading">
              {translate("privacyAiFeaturesTitle")}
            </h2>
            <ul className="privacy-list">
              <li>{translate("privacyAiFeaturesDataUsed")}</li>
              <li>{translate("privacyAiFeaturesTransparency")}</li>
              <li>{translate("privacyAiFeaturesPurpose")}</li>
            </ul>

            <h2 className="privacy-heading">
              {translate("privacyBehavioralProfilesTitle")}
            </h2>
            <ul className="privacy-list">
              <li>{translate("privacyBehavioralProfilesVisibility")}</li>
              <li>{translate("privacyBehavioralProfilesControl")}</li>
            </ul>

            <h2 className="privacy-heading">
              {translate("privacySecurityMeasuresTitle")}
            </h2>
            <ul className="privacy-list">
              <li>{translate("privacySecurityPasswordHashing")}</li>
              <li>{translate("privacySecurityDataInTransit")}</li>
              <li>{translate("privacySecurityAccessControls")}</li>
            </ul>

            <h2 className="privacy-heading">
              {translate("privacyCnilComplianceTitle")}
            </h2>
            <ul className="privacy-list">
              <li>{translate("privacyCnilNoMonitoring")}</li>
              <li>
                {translate("privacyCnilComplaints")}{" "}
                <a
                  href="https://www.cnil.fr/en/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate("privacyCnilLinkText")}
                </a>
              </li>
            </ul>

            <h2 className="privacy-heading">
              {translate("privacyUpdatesTitle")}
            </h2>
            <p className="privacy-text">
              {translate("privacyUpdatesDescription")}
            </p>

            <h2 className="privacy-heading">
              {translate("privacyContactUsTitle")}
            </h2>
            <p className="privacy-text">
              {translate("privacyContactUsDescription")}{" "}
              <a href="mailto:dpo@kanam.io" className="privacy-email">
                dpo@kanam.io
              </a>
            </p>
          </section>
        </div>
      </PageLayout>
    </>
  );
}
