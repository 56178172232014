import { act } from "react";
import { LOCALES } from "../locales";

export default {
  [LOCALES.FRENCH]: {
    //Home Page
    topline: "BÂTIR LA CONFIANCE, ACCOMPLIR DAVANTAGE.",
    title: "La seule plateforme OKR qui comprend votre équipe.",
    description:
      "Kanam aide les PME à aligner leur vision, responsabiliser leurs employés et suivre leurs progrès — tout en respectant les préférences individuelles et en renforçant la confiance à chaque étape.",
    startTrial: "Commencer l'essai gratuit",
    scheduleDemo: "Planifier une démo",
    trialNote: "Essai gratuit de 30 jours, aucune carte requise",
    product: "Produit",
    pricing: "Tarification",
    aboutUs: "À propos de nous",
    resources: "Ressources",
    okrMaturityAssessment: "Évaluation de maturité OKR",
    okrMaturityTooltip:
      "Découvrez votre niveau de maturité OKR (Explorateur, Bâtisseur ou Avancé) et obtenez un plan d'action gratuit.",
    login: "Connexion",
    profile: "Profil",
    changePassword: "Changer le mot de passe",
    logout: "Déconnexion",
    tryForFree: "Essayer gratuitement",
    signUpForFree: "S'inscrire gratuitement",
    askForDemo: "Demander une démo",
    claimExclusiveOffer: "Réclamer votre offre exclusive",
    alignAchieveExcel:
      "Aligner, réaliser, exceller : révolutionnez la performance avec l'intégration stratégique des OKR",
    bridgeGap:
      "Comblez le fossé entre aspiration et action : notre plateforme OKR traduit les objectifs ambitieux en succès mesurables,<br></br> atténuant les peurs et favorisant la croissance grâce à une approche éprouvée et empathique.",
    unifiedGoals: "Objectifs Unifiés & Alignement de la Vision",
    unifiedGoalsDesc:
      "Synchronisez chaque niveau de votre organisation grâce à nos fonctionnalités d'intégration transparentes, garantissant une direction unifiée et une collaboration améliorée. Avancez ensemble vers votre vision.",
    achievementInsights: "Aperçu Instantané des Réalisations",
    achievementInsightsDesc:
      "Accédez immédiatement à une vision claire de vos progrès grâce à nos tableaux de bord dynamiques et nos analyses, permettant des décisions rapides et basées sur les données pour garder vos objectifs à portée de main.",
    flexibleObjectives: "Création d'Objectifs Flexible",
    flexibleObjectivesDesc:
      "Concevez votre chemin vers le succès avec nos outils de définition d'objectifs adaptables. Fixez des objectifs ambitieux ou précis pour s'aligner sur vos ambitions stratégiques et permettre à votre équipe d'exceller.",
    performanceRecognition: "Performance Inspirante & Reconnaissance",
    performanceRecognitionDesc:
      "Transformez l'atteinte des objectifs en une expérience enrichissante et reconnue. Cultivez un environnement motivant et engagé qui célèbre chaque étape franchie.",
    harmonizeGoals:
      "Harmoniser les objectifs, accélérer le succès avec l'engagement OKR unifié",
    navigate:
      "Naviguez dans les complexités de l'alignement des objectifs avec facilité et confiance. Notre plateforme simplifie non seulement le processus OKR, mais le transforme en une source de motivation et de clarté, garantissant que chaque membre de l'équipe avance ensemble vers des victoires communes.",
    exclusiveLaunchOffer:
      "Offre de lancement exclusive : avantages à vie pour les premiers utilisateurs",
    joinUsLaunch:
      "Rejoignez-nous lors de notre lancement et sécurisez votre tarif spécial pour toujours, en plus d'accéder à toutes les améliorations futures sans frais supplémentaires.",
    thankYou:
      "C'est notre façon de vous remercier d'avoir fait partie de notre voyage dès le début.",
    getStartedTitle: "Commencez avec Kanam",
    getStartedDescription:
      "Pour un investissement modeste, vous utilisez moins de 0,01 % du coût annuel moyen d'un employé pour améliorer considérablement la productivité et l'alignement des objectifs au sein de votre équipe.",
    faqTitle: "Questions Fréquemment Posées",
    faq1: "Qu'est-ce que le logiciel de gestion OKR ?",
    faq1Desc:
      "Notre logiciel de gestion OKR est un outil conçu pour aider les organisations à définir, suivre et atteindre leurs objectifs et résultats clés. Il simplifie l'alignement des objectifs entre les équipes, améliorant ainsi la transparence et la responsabilité.",
    faq2: "En quoi votre logiciel est-il différent des autres outils de définition d'objectifs ?",
    faq2Desc:
      "Notre logiciel intègre des principes de neurosciences tels que les systèmes de récompenses, la gamification et le feedback instantané pour améliorer l'engagement des utilisateurs.",
    faq3: "Qui devrait utiliser un logiciel de gestion OKR ?",
    faq3Desc:
      "Toute organisation, des startups aux grandes entreprises, cherchant à améliorer l'alignement des objectifs et la productivité des équipes.",
    faq4: "Quels sont les supports et ressources disponibles pour les nouveaux utilisateurs ?",
    faq4Desc:
      "Tous les utilisateurs bénéficient d'un support personnalisé par un Account Manager dédié et un Customer Success Manager.",
    faq5: "Que comprend l’offre de lancement exclusive ?",
    faq5Desc:
      "Elle vous garantit un prix réduit à vie et un accès gratuit à toutes les futures mises à jour.",
    getStarted: "Commencez avec Kanam",
    getStartedDesc:
      "Pour un investissement modeste, vous utilisez moins de 0,01 % du coût moyen d’un employé annuel pour améliorer significativement la productivité et l’alignement des objectifs de votre équipe.",
    transformGoals: "Prêt à transformer vos objectifs en réalités ?",
    journeyToExcellence:
      "Commencez votre parcours vers l’excellence aujourd’hui",
    stillHaveQuestion: "Vous avez encore une question ?",
    contactUs: "Contactez-nous !",
    happyToHelp: "Nous serons ravis de vous aider.",

    aboutKanam: "À propos de Kanam",
    unlockingExcellence: "Débloquer l'excellence avec les OKR",
    atKanam:
      "Chez Kanam, nous croyons que la véritable performance ne consiste pas seulement à fixer des objectifs, mais à créer une culture d'excellence où chaque membre de l'équipe est aligné, engagé et habilité à obtenir des résultats.",
    moreThanTool: "Plus qu'un outil—Un partenaire dans votre succès",
    powerfulOKRPlatform: "Plateforme OKR puissante",
    aiAssistedGoalSetting:
      "Objectifs assistés par IA, suivi en temps réel et recommandations intelligentes pour garder vos équipes alignées et concentrées.",
    expertGuidance: "Conseils d'experts",
    ourTeamHelps:
      "Notre équipe vous aide à déployer les OKR efficacement, en veillant à ce que le cadre soit intégré dans l'ADN de votre entreprise, et non seulement dans un système.",
    fastTrackToResults: "Accélération des résultats",
    weWorkAlongside:
      "Nous travaillons à vos côtés pour accélérer l'adoption, minimiser les frictions et obtenir des améliorations mesurables dans l'exécution.",
    roiOfOKRs: "Le ROI des OKR—Un investissement évident",
    higherExecutionSpeed: "Vitesse d'exécution plus élevée",
    even20Improvement:
      "Même une amélioration de 20 % de l'efficacité signifie livrer plus avec les mêmes ressources.",
    clearerpriorities: "Des priorités plus claires",
    clearerdetail:
      "Les équipes consacrent moins de temps aux distractions et plus de temps à ce qui fait vraiment bouger les choses.",
    engagement: "Amélioration de l’engagement et de la responsabilisation",
    engagementdetail:
      "Les employés se sentent plus connectés à leur travail, ce qui se traduit par de meilleures performances.",

    CalculateROI: "Calculez votre ROI avec Kanam",
    Company: "Votre entreprise",
    TeamSize: "Taille de l’équipe (nombre d’employés)",
    SalaryPer: "Salaire annuel moyen par employé ($)",
    Project: "Votre projet OKR",
    ProductivityIncrease: "Augmentation prévue de la productivité",
    IndustryInsight:
      "💡 Aperçu du secteur : Selon une étude de McKinsey, les organisations qui mettent en œuvre efficacement les OKR constatent une augmentation de 30 % de l’engagement des employés et de la productivité.",
    Payroll: "Coût de la paie ($)",
    employeesalaries:
      "Le coût des salaires des employés, qui fournit une base de référence pour les calculs de l’impact sur la productivité.",
    Investment: "Investissement ($)",
    InvestmentDetail:
      "Le montant investi dans l’initiative, y compris la formation, les outils et les ressources.",
    CapacityCreated: "Capacité créée",
    CapacityDetail:
      "Représente la capacité de main-d’œuvre supplémentaire obtenue grâce à l’amélioration de la productivité, comme l’ajout de X employés à temps plein sans coût supplémentaire.",
    ProjectedROI: "Retour sur investissement prévu",
    ProjectedDetail:
      "Rendement financier estimatif généré par l’efficacité et l’alignement accrus, exprimé en dollars.",
    MinimumROI: "ROI minimum (%)",
    MinimumDetail:
      "Le retour sur investissement attendu le plus bas, ce qui garantit que même les estimations les plus prudentes montrent un impact positif.",
    Calculated: "💡 ROI calculé !",
    footnote: "Toutes les données sont présentées sur une base mensuelle.",

    //Pricing Page

    pricingTitle: "Nos Tarifs",
    pricingDescription:
      "Payez en toute sécurité en ligne et gérez la réservation via un ordinateur ou une application mobile.",
    recommended: "Recommandé",
    selectPlan: "Sélectionner ce plan",
    currentPlan: "Plan actuel",
    upgradePlan: "Mettre à niveau le plan",
    buyPlan: "Acheter un plan",
    trial: "Essai",
    freeTrial: "30 jours d'essai gratuit",
    forever: "À vie",
    noCreditCard: "(Aucune carte bancaire requise)",
    features: "Fonctionnalités",
    cancelPlan: "Annuler le plan",
    cancelConfirmation: "Êtes-vous sûr de vouloir annuler votre plan ?",
    confirmYes: "Oui",
    perMonth: "/ par mois",

    //Footer

    company: "Entreprise",
    product: "Produit",
    pricing: "Tarification",
    support: "Support",
    faq: "FAQ",
    privacyPolicy: "Politique de confidentialité",
    termsOfService: "Conditions d'utilisation",
    subscribeNewsletter: "Abonnez-vous à notre newsletter",
    subscribeDescription:
      "Inscrivez-vous et recevez les derniers conseils par e-mail.",
    enterYourEmail: "Entrez votre email",
    subscribeNow: "S'abonner maintenant",
    copyright: "©2025 Kanam. Tous droits réservés",

    //Login Page

    loginTitle: "Connectez-vous à votre compte",
    emailLabel: "Email*",
    emailPlaceholder: "Adresse e-mail",
    passwordLabel: "Mot de passe*",
    passwordPlaceholder: "Mot de passe",
    forgotPassword: "Mot de passe oublié ?",
    signInButton: "Se connecter",
    noAccount: "Vous n'avez pas de compte ?",
    startFreeTrial: "Commencez avec un essai gratuit",

    //Dashboard

    dashboard: "Tableau de bord",
    hi: "Salut",
    analyticsSession: "Analyse de la session :",
    overview: "Vue d'ensemble",
    numberObjectives: "Nombre d'objectifs",
    objectives: "Objectifs",
    timeSpent: "Temps passé",
    daysLeft: "jours restants",
    resultSummary: "Résumé des Résultats Clés",
    currentFocus: "Mon Focus Actuel",
    filter: "Filtres",
    confidence: "Confiance",
    owner: "Propriétaire",
    contributor: "Contributeurs",
    Action: "Action",
    progress: "OKR/Progression",

    session: "Session",
    objectiveProgress: "Progression de l'objectif",
    objective: "Objectif",

    //HUD

    trialPeriod: "Période d'essai: A vie",
    upgradePlan: "Mettre à niveau le plan",
    onTrack: "EN COURS",
    offTrack: "EN RETARD",
    atRisk: "A RISQUE",
    okrs: "OKRs",
    people: "Personnes",
    allSessions: "Toutes les Sessions",
    settings: "Paramètres",
    supportCenter: "Point de Support",
    notifications: "Notifications",
    edit: "Editer",
    addKR: "Ajouter un Resultat Cle",
    addChild: "Ajouter un Objectif Enfant",
    delete: "Effacer",
    recentSessions: "Sessions Recentes",
    myTeams: "Mes Equipes",
    accountAndDomain: "Compte et Domaine",
    planAndBilling: "Plan et Facturation",

    //Employee details page

    createdOn: "Créé le",
    email: "Email",
    manager: "Manager",
    team: "Équipe",
    whatILoveDoing: "Ce que j'aime faire sans effort",
    howToCommunicate: "Comment communiquer avec moi",
    whatMattersMost: "Ce qui compte le plus pour moi au travail",
    whatCanFrustrate: "Ce qui peut me frustrer ou me bloquer",
    somethingAboutMe: "Quelque chose que vous devez savoir sur moi",
    trustBattery:
      "Batterie de confiance (Comment fonctionne la confiance pour moi)",
    trustLevel: "Mon niveau de confiance par défaut",
    gainTrust: "Comment gagner ma confiance",
    loseTrust: "Comment perdre ma confiance",
    edit: "Modifier",
    deactivateEmployee: "Désactiver l'employé",
    activateEmployee: "Activer l'employé",
    deleteEmployee: "Supprimer l'employé",
    confirmDelete: "Voulez-vous vraiment supprimer cet employé ?",
    confirmStatusChange:
      "Voulez-vous vraiment changer le statut de cet employé ?",
    uploadImage: "Téléverser une image",
    cancel: "Annuler",
    update: "Mettre à jour",
    startTrust: "La confiance doit être gagnée à partir de zéro.",
    trustWithProof: "Je fais confiance aux gens, mais j'ai besoin de preuves.",
    trustUntilProvenOtherwise:
      "Je fais totalement confiance jusqu'à preuve du contraire.",
    likesDislikes: "J'aime / Je n'aime pas",
    sustainTrust:
      "Comment construisez-vous et maintenez-vous la confiance au sein de votre équipe ? Partagez votre style afin que les autres puissent mieux collaborer avec vous.",
    refillBattery: "Comment remplir ma batterie",
    drainBattery: "Comment vider ma batterie",
    employee: "l'employé",
    teams: "Équipes",
    teamsOrg: "Équipes de l'organisation",
    employees: "Employés",
    withoutTeam: "Without Team",
    noTeam: "No Team",

    //OKR Page

    alignment: "Alignement",
    lastUpdated: "Mis à jour le",
    createOKR: "Créer un OKR",
    captureGoal:
      "Définissez votre objectif et tracez le chemin vers le succès !",
    createNew: "Créer un nouveau",
    update: "Mettre à jour",
    okrTitle: "Titre",
    whyNow: "Pourquoi maintenant ?",
    connectParent: "Connecter à l'OKR parent",
    searchOKR: "Chercher des OKRs, Resultats Cles et Objectifs",
    create: "Créer",
    okrDescription: "Description",

    //OKR Pop-up Page

    overallProgress: "Progression globale",
    shouldMoveto: "devrait etre à",
    currentProgress: "progression actuelle à",
    shareVia: "Partager via",
    checkin: "Check-in",
    keepAligned:
      "Gardez tout le monde au courant! Notifiez rapidement votre équipe de tout progres ou mise a jour.",
    targetDate: "Date cible",
    noTargetDate: "Pas de date cible",
    hardDeadline: "Date limite stricte",
    hardSoft: "Date limite stricte & flexible",
    softDeadline: "Date limite flexible",
    addkeyResult: "Ajouter un Resultat Cle",
    unit: "Unité",
    numbers: "Nombres",
    defineOutcome:
      "Définissez un résultat clair et mesurable pour favoriser les progrès vers votre objectif.",
    createKR: "Créer un Resultat Cle",
    items: "Articles",
    score: "Score",
    keyResults: "Résultats Clés",
    addChild: "Ajouter un objectif enfant",
    keyResult: "Résultat Clé",
    add: "Ajouter",

    // Check-In

    checkProgress: "Check-in sur votre progres",
    doingGreat:
      "Vous faites du bon travail! Qu'avez-vous accompli cette semaine ?",
    currentValue: "Valeur actuelle",
    newValue: "Nouvelle valeur",
    dateUpdate: "Date de la mise à jour",
    confidenceLVL: "Niveau de confiance",
    victory:
      "Victoire ! Vous avez atteint un Résultat Clé. Voici des confettis ! 🏆🚀",
    show: "Afficher",
    of: "sur",
    records: "enregistrements",

    //Session Page

    createSession: "Créer Session",
    session: "session",
    asterisk: "Tous les champs obligatoires sont marques avec un asterisk",
    name: "Nom",
    sessionName: "Nom de la session",
    timeframe: "Délai",
    startDate: "Date de début",
    endDate: "Date de fin",
    cadence: "Cadence",
    weekly: "Hebdomadaire",
    twoWeeks: "Toutes les deux semaines",
    month: "Mensuelle",
    every: "Chaque",
    on: "le",
    firstDay: "Le premier jour du mois",
    lastDay: "Le dernier jour du mois",
    specificDate: "Date spécifique",
    color: "Couleur",

    // Billing page

    billingFor: "Facturation pour",
    nextInvoiceDate: "Date de la prochaine facture",
    myInvoices: "Mes factures",
    totalPaidAmount: "Montant total payé:",
    managePlan: "Gérer le plan",
    myTransactions: "Mes transactions",
    monthlyCost: "Coût mensuel",
    transactionDate: "Date de la transaction",
    seat: "Place",
    seats: "Places",
    paidAmount: "Montant payé",
    use: "utilise",

    //Team Details

    deactivate: "Désactiver",
    activate: "Activer",
    overview: "Vue d'ensemble",
    ourIdentity: "Notre Identité",
    teamName: "Nom de l'équipe",
    teamManager: "Manager de l'équipe",
    teamMembers: "Membres de l'équipe",
    dependencies: "Dépendances des autres équipes",
    dependentTeams: "Équipes dépendantes de nous",
    identifiedDependencies: "Identifiées comme Dépendances par",
    parentTeams: "Équipes parentes",
    teamColor: "Couleur de l'équipe",
    nameDesc:
      "Entrez un nom unique pour identifier cette équipe au sein de l'organisation.",
    managerDesc:
      "Indiquez le principal point de contact responsable de la direction de cette équipe.",
    memberDesc:
      "Ajoutez toutes les personnes faisant partie de cette équipe. Leurs rôles et responsabilités seront alignés sur les objectifs de l'équipe.",
    depenDesc:
      "Listez les équipes dont ce groupe dépend pour atteindre ses objectifs. Les dépendances indiquent les domaines nécessitant une collaboration ou des ressources.",
    depenteamDesc:
      "Spécifiez les équipes qui comptent sur cette équipe pour atteindre leurs objectifs. Comprendre ces dépendances peut aider à prioriser les tâches et les ressources.",
    identifiedDesc:
      "Voyez quelles équipes ont marqué cette équipe comme une dépendance. Utilisez cette information pour aligner le travail interfonctionnel et clarifier les besoins en support.",
    parentDesc:
      "Sélectionnez l'équipe parent si cette équipe fait partie d'une unité organisationnelle plus large.",
    colorDesc:
      "Choisissez une couleur pour représenter visuellement cette équipe sur la plateforme et la distinguer facilement des autres.",
    status: "Statut",
    active: "Actif",
    inactive: "Inactif",
    teamMission: "Mission de l'équipe",
    teamMissionDescription:
      "Définissez la raison d’être principale de l’équipe et l’impact unique qu’elle cherche à avoir au sein de l’organisation.",
    teamVision: "Vision de l'équipe",
    teamVisionDescription:
      "Décrivez les aspirations à long terme de l’équipe et l’avenir qu’elle souhaite créer.",
    values: "Valeurs",
    valuesDescription:
      "Listez les principes directeurs qui façonnent la culture et la prise de décision de l’équipe. Cliquez sur 'Ajouter' pour inclure chaque valeur.",
    addValue: "Ajouter Valeur",
    close: "Fermer",
    save: "Sauvegarder",
    titlename: "Titre",
    descriptionname: "Description",
    deleteTeam: "Supprimer l'équipe",
    value: "Valeur",

    //Team Listing

    hasOne: "A seulement 1 membre, dirigé par",
    ledBy: "dirigé par",
    noMember: "Pas de membres",
    image: "Image",
    teamName: "Nom de l'équipe",
    createdAt: "Créé le",
    mission: "Mission",
    members: "Membres",
    action: "Action",
    createTeam: "Créer équipe",
    change: "Changer",
    upload: "Téléverser",
    parentTeam: "Équipe parente",
    teamImage: "Image de l'équipe",
    removeImage: "Supprimer l'image",
    addChildTeam: "Ajouter une sous-équipe",
    view: "Voir",

    //Employee Listing

    all: "Tous",
    withTeam: "Avec équipe",
    witoutTeam: "Sans équipe",
    usedSeats: "Places utilisées",
    totalSeats: "Places totales",
    addSeats: "Modifier le plan",
    addEmployee: "Ajouter un employé",
    createEmployee: "Créer un employé",
    updateEmployee: "Mettre à jour l'employé",
    addSeatsPlan: "Ajouter des places au plan",

    //Profile Page

    companyName: "Nom de l'entreprise",
    mobile: "Mobile",
    role: "Rôle",
    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmPassword: "Confirmez le mot de passe",
    minLength: "Le mot de passe doit contenir au moins 8 caractères",
    notMatched: "Les mots de passe ne correspondent pas",

    Session: "Session",

    ExpertTalk: "Parlez à un expert",
    transformorganization: "Prêt à transformer votre organisation ?",
    Iflooking: "Si vous cherchez à",
    align:
      "alignez vos équipes, augmentez la vitesse d’exécution et favorisez une culture axée sur les résultats",
    Kanampartner: ", Kanam est le partenaire qu’il vous faut.",

    values: "Nos valeurs",

    title1: "Rester Humble",
    frontText1:
      "Tout le monde a quelque chose à apprendre. Cherchez d'abord à comprendre avant de chercher à être compris.",
    backText1: "Restez humble, restez curieux.",

    title2: "S'améliorer avec Intention",
    frontText2:
      "Visez une amélioration continue dans chaque action que vous entreprenez. Travaillez constamment sur vous-même.",
    backText2: "La croissance est un choix quotidien.",

    title3: "Trouver l'effet de levier",
    frontText3:
      "Identifiez l'action qui créera le plus de valeur à court ou à long terme. Soyez intentionnel avec votre temps.",
    backText3: "Petites actions, grand impact.",

    title4: "Construire des relations solides",
    frontText4:
      "Favorisez la confiance avec vos collègues, partenaires et clients. Cela signifie aussi donner et recevoir des retours honnêtes.",
    backText4: "La confiance se construit avec la cohérence.",

    title5: "Soyez passionné par votre domaine",
    frontText5:
      "Pour devenir le meilleur, développez une passion profonde pour votre domaine, apprenez des meilleurs et améliorez-vous quotidiennement.",
    backText5: "La passion mène à la maîtrise.",

    title6: "Pensez grand. Commencez petit. Commencez maintenant.",
    frontText6:
      "Créer de la valeur significative nécessite de prendre des risques et de les gérer efficacement.",
    backText6: "Commencez avant d'être prêt.",

    // Settings Page

    accountName: "Nom du compte",
    nameofOrg: "Le nom de votre organisation",
    company: "Entreprise",
    logout: "Déconnexion",
    explainBriefly:
      "Expliquez brièvement pourquoi il est important de se concentrer sur cet objectif maintenant.",

    // Onboarding page

    onboarding: "Intégration",
    company_logo: "Logo de l'entreprise",
    company_image: "Image de l'entreprise",
    company_image_required: "L'image de l'entreprise est requise",
    click_to_upload_image: "Cliquez pour télécharger l'image",
    company_name: "Nom de l'entreprise",
    company_name_required: "Le nom de l'entreprise est requis",
    company_url: "URL de l'entreprise",
    founded_year: "Année de fondation",
    industry: "Secteur",
    about: "À propos",
    vision: "Vision",
    employee_count: "Nombre d'employés",
    annual_revenue: "Revenu annuel",
    departments: "Départements",
    strategic_priorities: "Priorités stratégiques",
    products_services: "Produits et services",
    key_metrics: "Indicateurs clés",
    linkedin_url: "URL LinkedIn",
    next: "Suivant",
    skip: "Passer",
    profile_picture: "Photo de profil",
    upload_image: "Télécharger l'image",
    first_name: "Prénom",
    first_name_required: "Le prénom est requis",
    last_name: "Nom de famille",
    last_name_required: "Le nom de famille est requis",
    email_required: "L'email est requis",
    mobile_no: "Numéro de mobile",
    enter_phone_number: "Entrez le numéro de téléphone",
    what_i_love_doing: "Ce que j'aime faire",
    how_to_communicate: "Comment communiquer",
    what_matters_most: "Ce qui compte le plus",
    what_can_frustrate: "Ce qui peut frustrer",
    something_about_me: "Quelque chose à propos de moi",
    default_trust_level: "Niveau de confiance par défaut",
    how_to_gain_trust: "Comment gagner la confiance",
    how_to_lose_trust: "Comment perdre la confiance",
    previous: "Précédent",
    complete_onboarding: "Terminer l'intégration",

    //Account Domain

    nameofOrg: "Profil de l'organisation",
    edit: "Modifier le profil",
    cancel: "Annuler",
    save: "Enregistrer les modifications",
    companyName: "Nom de l'entreprise",
    companyLogo: "Logo de l'entreprise",
    annualRevenue: "Revenu annuel",
    companyUrl: "URL du site web",
    linkedinUrl: "URL LinkedIn",
    industry: "Secteur",
    employeeCount: "Nombre d'employés",
    foundedYear: "Année de fondation",
    departments: "Départements",
    keyMetrics: "Indicateurs clés",
    mission: "Mission",
    vision: "Vision",
    values: "Valeurs",
    productsServices: "Produits et services",
    strategicPriorities: "Priorités stratégiques",
    createdAt: "Créé le",
    updatedAt: "Dernière mise à jour",
    clickToUploadImage: "Cliquez pour télécharger un nouveau logo d'entreprise",
    basicInfo: "Informations de base",
    contactInfo: "Informations de contact",
    companyDetails: "Détails de l'entreprise",
    businessInfo: "Informations commerciales",
    timestamps: "Horodatages",
    noLinkedWorkspace: "Aucun espace de travail lié",

    // privacy policy

    privacyPolicyTitle: "Politique de confidentialité",
    privacyLastUpdated: "Dernière mise à jour : 2025-04-01",
    privacyLinkText: "https://kanam.io/privacy",
    privacyDataControllerTitle: "1. Responsable du traitement des données",
    privacyDataControllerNameLabel: "Nom :",
    privacyDataControllerAddressLabel: "Adresse :",
    privacyDataControllerSiretLabel: "SIRET :",
    privacyDataControllerDpoLabel: "DPO :",
    privacyDataWeCollectTitle: "2. Données que nous collectons",
    privacyTableCategory: "Catégorie",
    privacyTableDataTypes: "Types de données",
    privacyTablePurpose: "Objectif",
    privacyTableLegalBasis: "Base légale",
    privacyTableAccountData: "Données de compte",
    privacyTableAccountDataTypes:
      "Email, nom, nom de l'entreprise, mot de passe haché",
    privacyTableAccountPurpose:
      "Création de compte utilisateur, authentification",
    privacyTableAccountLegalBasis: "Contrat (Art. 6(1)(b))",
    privacyTableBehavioralProfiles: "Profils comportementaux",
    privacyTableBehavioralProfilesDataTypes:
      "Niveau de confiance par défaut, préférences de communication",
    privacyTableBehavioralProfilesPurpose:
      "Collaboration en équipe et renforcement de la confiance",
    privacyTableBehavioralProfilesLegalBasis: "Intérêt légitime (Art. 6(1)(f))",
    privacyTableOkrData: "Données OKR",
    privacyTableOkrDataTypes: "Titres OKR, niveaux de confiance, contributeurs",
    privacyTableOkrPurpose: "Suivi des OKR et alignement de l'équipe",
    privacyTableOkrLegalBasis: "Intérêt légitime (Art. 6(1)(f))",
    privacyTablePaymentData: "Données de paiement",
    privacyTablePaymentDataTypes:
      "Enregistrements de transactions, identifiants de paiement Stripe",
    privacyTablePaymentPurpose: "Traitement des paiements et facturation",
    privacyTablePaymentLegalBasis: "Obligation légale (Art. 6(1)(c))",
    privacyTableAiFeatures: "Fonctionnalités IA",
    privacyTableAiFeaturesDataTypes:
      "Secteur d'activité anonymisé, taille de l'entreprise",
    privacyTableAiFeaturesPurpose: "Recommandations et analyses OKR",
    privacyTableAiFeaturesLegalBasis: "Intérêt légitime (Art. 6(1)(f))",
    privacyHowWeUseDataTitle: "3. Comment nous utilisons vos données",
    privacyHowWeUseDataItem1: "Gérer les comptes utilisateurs et les équipes.",
    privacyHowWeUseDataItem2:
      "Suivre les OKR et les métriques de collaboration.",
    privacyHowWeUseDataItem3: "Traiter les paiements (Stripe).",
    privacyHowWeUseDataItem4:
      "Générer des recommandations OKR à l'aide de données anonymisées.",
    privacyHowWeUseDataItem5:
      "Le contenu généré par l'IA (par exemple, les suggestions OKR) est clairement identifié comme 'généré par l'IA'.",
    privacyDataRetentionTitle: "4. Conservation des données",
    privacyTableDataType: "Type de données",
    privacyTableRetentionPeriod: "Durée de conservation",
    privacyTableAccountRetention: "2 ans après la fermeture du compte",
    privacyTableBehavioralProfilesRetention:
      "2 ans après la fermeture du compte",
    privacyTableOkrRetention: "2 ans après la fermeture du compte",
    privacyTablePaymentRetention: "10 ans (loi fiscale française)",
    privacyDataSharingTitle: "5. Partage et transfert des données",
    privacyTableRecipient: "Destinataire",
    privacyTableLocation: "Emplacement",
    privacyTableSafeguards: "Garanties",
    privacyTableCompanyMembers: "Membres de l'entreprise",
    privacyTableCompanyMembersPurpose: "Accès aux données partagées",
    privacyTableCompanyMembersLocation:
      "Global (emplacements des utilisateurs)",
    privacyTableCompanyMembersSafeguards:
      "Données stockées dans l'UE (Allemagne) + Contrôles d'accès basés sur les rôles",
    privacyTableStripe: "Stripe",
    privacyTableStripePurpose: "Traitement des paiements",
    privacyTableStripeLocation: "États-Unis/UE",
    privacyTableStripeSafeguards: "Clauses contractuelles types (SCCs)",
    privacyTableOpenAi: "OpenAI",
    privacyTableOpenAiPurpose: "Fonctionnalités IA",
    privacyTableOpenAiLocation: "États-Unis (via l'UE)",
    privacyTableOpenAiSafeguards:
      "Clauses contractuelles types (SCCs) + Anonymisation",
    privacyYourRightsTitle: "6. Vos droits",
    privacyYourRightsItem1: "Accéder à vos données.",
    privacyYourRightsItem2: "Corriger les données inexactes.",
    privacyYourRightsItem3:
      "Supprimer vos données (sauf obligation légale de conservation).",
    privacyYourRightsItem4:
      "Vous opposer au traitement basé sur des intérêts légitimes.",
    privacyYourRightsItem5:
      "Porter vos données (par exemple, exporter les OKR).",
    privacyYourRightsContact:
      "Pour exercer vos droits, envoyez-nous un email à support@kanam.io. Nous répondrons sous 30 jours.",

    privacyAiFeaturesTitle: "Fonctionnalités IA",
    privacyAiFeaturesDataUsed:
      "Données utilisées : Secteur d'activité anonymisé, taille de l'entreprise.",
    privacyAiFeaturesTransparency:
      "Transparence : Le contenu généré par l'IA (par exemple, les recommandations OKR) est étiqueté comme 'généré par l'IA'.",
    privacyAiFeaturesPurpose:
      "Objectif : Améliorer l'efficacité de la gestion des OKR grâce à des suggestions automatisées.",

    privacyBehavioralProfilesTitle: "Profils Comportementaux",
    privacyBehavioralProfilesVisibility:
      "Visibilité : Tous les membres de l'entreprise peuvent voir vos préférences de communication et niveaux de confiance.",
    privacyBehavioralProfilesControl:
      "Contrôle : Modifiez ou supprimez votre profil dans les paramètres du compte.",

    privacySecurityMeasuresTitle: "Mesures de Sécurité",
    privacySecurityPasswordHashing:
      "Hachage des mots de passe : Stockés de manière sécurisée avec bcrypt.",
    privacySecurityDataInTransit:
      "Données en transit : Protégées via TLS 1.2+.",
    privacySecurityAccessControls:
      "Contrôles d'accès : Les administrateurs gèrent les permissions, et tous les membres de l'entreprise peuvent accéder aux données partagées.",

    privacyCnilComplianceTitle: "Conformité CNIL (France)",
    privacyCnilNoMonitoring:
      "Pas de surveillance des employés : Les données comportementales ne sont pas utilisées pour les évaluations de performance.",
    privacyCnilComplaints: "Réclamations : Déposez une plainte auprès de",
    privacyCnilLinkText: "la CNIL",

    privacyUpdatesTitle: "Mises à jour",
    privacyUpdatesDescription:
      "Cette politique est mise à jour chaque année. Les modifications sont notifiées par email ou via des alertes dans l'application.",

    privacyContactUsTitle: "Contactez-nous",
    privacyContactUsDescription: "Pour toute question, contactez notre DPO à",
  },
};
