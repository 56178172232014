import { Formik } from "formik";
import React, { useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import methodModel from "../../methods/methods";
import PaymentModal from "../PaymentModal/PaymentModal";

export default function SignUp() {
  const navigate = useHistory();
  const [form, setForm] = useState({
    role: "company",
    password: "",
    confirmPassword: "",
  });
  const [submit, setSubmit] = useState(false);

  const plan_id = methodModel?.getPrams("plan_id");

  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handelSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (form?.role == "company") {
      if (
        !emailRegex.test(form?.email) ||
        form?.password?.length < 6 ||
        form?.password !== form?.confirmPassword
      )
        return;
    }

    loader(true);
    ApiClient.post(`register`, { ...form, planId: plan_id }).then((res) => {
      if (res.success) {
        localStorage.setItem("SignUpEmail", form.email);
        localStorage.setItem("SignUpId", res?.data?.id);
        setTimeout(() => {
          toast.success("Please check your email for verification");
        }, 100);
        navigate.push(`/login`);
      }
      loader(false);
    });
  };

  return (
    <>
      <div className="signIn-main right_hight">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto ">
              <div className="right_side">
                <form className="centerLogin" onSubmit={(e) => handelSubmit(e)}>
                  <div
                    className="logo_set mb-4"
                    onClick={() => navigate.push("/home")}
                  >
                    <img
                      className="logo_login"
                      src="assets/img/images/footer_logo.png"
                    />
                  </div>

                  <div className="text-center mb-4">
                    <h3 className="text-center lgtext">Sign Up</h3>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          value={form?.email}
                          onChange={(e) =>
                            setForm({ ...form, email: e.target.value })
                          }
                          placeholder="Email Address"
                          className="form-control bginput"
                        />
                        {submit && !emailRegex.test(form?.email) && (
                          <p className="text-danger">Enter valid email</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label>Password</label>
                      <div className="form-group position-relative mb-0">
                        <input
                          className="form-control "
                          type={!eyes?.password ? "password" : "text"}
                          value={form?.password}
                          onChange={(e) =>
                            setForm({ ...form, password: e.target.value })
                          }
                          placeholder="Password"
                        />
                        <span className="eye_right">
                          <i
                            className={
                              eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                            }
                            onClick={() =>
                              setEyes({ ...eyes, password: !eyes?.password })
                            }
                          ></i>
                        </span>
                      </div>
                      {submit && form?.password?.length < 6 && (
                        <p className="text-danger">Password must 8 digit</p>
                      )}
                    </div>
                    <div className="col-md-12">
                      <label>Confirm Password</label>
                      <div className="form-group position-relative mb-0">
                        <input
                          className="form-control "
                          type={!eyes?.confirmPassword ? "password" : "text"}
                          value={form?.confirmPassword}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              confirmPassword: e.target.value,
                            })
                          }
                          placeholder="Confirm Password"
                        />
                        <span className="eye_right">
                          <i
                            className={
                              eyes.confirmPassword
                                ? "fa fa-eye"
                                : "fa fa-eye-slash"
                            }
                            onClick={() =>
                              setEyes({
                                ...eyes,
                                confirmPassword: !eyes?.confirmPassword,
                              })
                            }
                          ></i>
                        </span>
                      </div>
                      {submit && form?.password !== form?.confirmPassword && (
                        <p className="text-danger">Passwords do not match</p>
                      )}
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary loginclass mt-3"
                      >
                        Sign Up
                      </button>
                      <p className="text-center mt-3 no-account">
                        Already have an account?{" "}
                        <span className="d-block">
                          <Link className="sign_up" to="/login">
                            {" "}
                            Log In{" "}
                            <i
                              className="fa fa-arrow-right"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}
