import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Tooltip } from "antd";
import moment from "moment";
import loader from "../../methods/loader";
import { useDispatch, useSelector } from "react-redux";
import { sessions } from "../../actions/session";
import { SiDatabricks } from "react-icons/si";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";

const getBrowserLocale = () => {
  const language = navigator.language.toLowerCase();
  if (language.includes("fr")) {
    return LOCALES.FRENCH;
  }
  return LOCALES.ENGLISH;
};

const locale = getBrowserLocale();
const SessionsListing = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [data, setdata] = useState();
  const navigator = useHistory();
  const [dele, setdelete] = useState(false);
  const specificDates = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let el = document.getElementById("sessionlist");
    if (el) el.click();
    let payload = {};
    if (user?.role == "employee") {
      payload = {
        addedBy: user?.addedBy,
        isDeleted: false,
      };
    } else {
      payload = {
        addedBy: user?.id || user?._id,
        isDeleted: false,
      };
    }
    loader(true);
    ApiClient.get(`sessions`, payload).then((res) => {
      if (res.success) {
        setdata(res?.data);
      }
      loader(false);
    });
  };

  // Create Session
  const [form, setform] = useState({
    title: "",
    start_date: new Date(),
    end_date: new Date().setMonth(new Date().getMonth(), [1]),
    cadenceStart: "",
    cadenceType: "weekly",
    color: "#ef0606",
  });
  const [editSession, seteditSession] = useState(false);

  const handleCreateSession = (e) => {
    e.preventDefault();
    let payload = {
      ...form,
      start_date: new Date(form?.start_date).setUTCHours(0, 0, 0, 0),
      end_date: new Date(form?.end_date).setUTCHours(0, 0, 0, 0),
    };
    if (payload.end_date >= payload.start_date) {
      if (!editSession) {
        loader(true);
        ApiClient.post(`session`, payload).then((res) => {
          if (res.success) {
            getData();
            // toast.success(res?.message);
            toast.success("New session created! Let's set new goals! 🎯", {
              position: "bottom-right",
              autoClose: 4000,
            });
            localStorage.removeItem("sessionID");
            localStorage.removeItem("endate");
            localStorage.removeItem("sessionNAme");
            localStorage.removeItem("ownerId");
            localStorage.removeItem("CreatedAt");
            document.getElementById("CloseSessionModal").click();
          }
          loader(false);
        });
      } else {
        payload = { ...payload, id: form?.id };
        loader(true);
        ApiClient.put(`session`, payload).then((res) => {
          if (res.success) {
            getData();
            // toast.success(res?.message);
            localStorage.removeItem("sessionID");
            localStorage.removeItem("endate");
            localStorage.removeItem("sessionNAme");
            localStorage.removeItem("ownerId");
            localStorage.removeItem("CreatedAt");
            toast.success("Session refreshed! Ready for progress! 📈", {
              position: "bottom-right",
              autoClose: 4000,
            });
            document.getElementById("CloseSessionModal").click();
          }
          seteditSession(false);
          loader(false);
        });
      }
    } else {
      toast.success("Your end date should be increase to start date 📈");
    }
  };

  const clearFields = () => {
    seteditSession(false);
    setform({
      title: "",
      start_date: new Date(),
      end_date: new Date().setMonth(new Date().getMonth() + 1),
      cadenceStart: "",
      cadenceType: "weekly",
      permission: "",
      color: "#ef0606",
    });
  };

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this session")) {
      ApiClient.delete(`session?id=${id}`).then((res) => {
        if (res.success) {
          localStorage.removeItem("sessionID");
          localStorage.removeItem("endate");
          localStorage.removeItem("sessionNAme");
          localStorage.removeItem("CreatedAt");
          localStorage.removeItem("ownerId");
          toast.success(res?.message);
          getData();
        }
      });
    }
    // recentdetail(id);
    // navigator.push(`/sessions`)
  };

  const handleEdit = (id) => {
    ApiClient.get(`session?id=${id}`).then((res) => {
      if (res.data) {
        seteditSession(true);
        setform({
          id: res?.data?.id,
          start_date: new Date(res?.data?.start_date),
          end_date: new Date(res?.data?.end_date),
          title: res?.data?.title,
          cadenceStart: res?.data?.cadenceStart,
          cadenceType: res?.data?.cadenceType,
          color: res?.data?.color,
          specific_date: res?.data?.specific_date,
        });
        document.getElementById("OpenSessionModal").click();
      }
    });
  };

  // **********************************************************************************************************

  // const [recent,setrecenet]=useState(false)
  const recentdetail = (id) => {
    let tab = document.getElementById("pills-objective-tab");
    if (tab) tab.click();
    let el = document.getElementById("sessionlist");
    if (el) el.click();
    let payload = { recentSessionId: id };
    ApiClient.post(`recent`, payload).then((res) => {
      console.log(res);
    });

    navigator.push(`/sessions/okrs/${id}`);
    // localStorage.setItem("sessionID", id)
    localStorage.removeItem("ownerId");
  };

  // ***************************************************************************************************

  // Full Calendar
  const computeDurations = () => {
    return {
      slotDuration: { months: 1 },
      slotLabelFormat: [
        { year: "numeric", textAlign: "left" },
        { month: "short" },
      ],
      duration: { years: 1 },
    };
  };
  const { duration, slotDuration, slotLabelFormat, titleFormat } =
    computeDurations();

  const resources =
    data &&
    data.map((item) => {
      return { id: item?._id, title: item?.title, end_date: item?.end_date };
    });

  const events =
    data &&
    data.map((item) => {
      return {
        id: item?._id,
        title: "",
        resourceId: item?._id,
        start: item?.start_date,
        end: item?.end_date,
        color: item?.color,
      };
    });

  const CampaignResource = ({ resource }) => {
    const { title, id, extendedProps } = resource._resource;
    return (
      <I18nProvider>
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "20px",
          }}
        >
          {/* <div className="pointer" onClick={e => { navigator.push(`/sessions/okrs/${id}`) }}>{title}</div> */}
          <div
            className="pointer text-capitalize sessions-page"
            onClick={(e) => {
              recentdetail(id);
              dispatch(sessions(title));
              // localStorage.setItem("endate",extendedProps?.end_date)
            }}
          >
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" className="me-2" fill="currentColor" viewBox="0 0 16 16" data-icon="session" aria-hidden="true">  <path fill="#efefef" d="M0 3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"></path>  <path fill="#C1333B" fill-rule="evenodd" d="M2.5 8a.75.75 0 0 1 .75-.75H11a.75.75 0 0 1 0 1.5H3.25A.75.75 0 0 1 2.5 8ZM7.25 11.25A.75.75 0 0 1 8 10.5h4.5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM4.5 4.75A.75.75 0 0 1 5.25 4h7.25a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z" clipRule="evenodd"></path></svg> */}
            <span className="svgspan">
              {" "}
              <SiDatabricks />
            </span>
            {title}
          </div>

          {user?.role != "employee" && (
            <div className="calendar-dropdown dropdowns_custom ml-3">
              <div className="dropdown">
                <span
                  className=" text-dark "
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-ellipsis-h text-dark"
                    aria-hidden="true"
                  ></i>
                </span>
                <div className="dropdown-menu">
                  <span
                    className="dropdown-item pointer"
                    onClick={(e) => handleEdit(id)}
                  >
                    <i className="fa fa-pen text-dark me-2"></i>
                    {translate("edit")}
                  </span>
                  <span
                    className="dropdown-item text-danger pointer"
                    onClick={(e) => handleDelete(id)}
                  >
                    <i className="fa fa-trash me-2"></i>
                    {translate("delete")}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* {!recent?null:<div className="calendar-dropdown dropdowns_custom ml-3">
                    <div className="dropdown">
                        <span className=" text-dark p-2" type="button" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-ellipsis-h text-dark" aria-hidden="true"></i>
                        </span>
                        <div className="dropdown-menu">
                            <span className="dropdown-item pointer" onClick={e => handleEdit(id)}>Edit</span>
                            <span className="dropdown-item text-danger pointer" onClick={e => handleDelete(id)}>Delete</span>
                        </div>
                    </div>
                </div>} */}
        </div>
      </I18nProvider>
    );
  };

  const EventComponent = ({ event }) => (
    <Tooltip
      title={`${moment(event?.start).format("MMM DD, YYYY")} - ${moment(
        event?.end
      ).format("MMM DD, YYYY")}`}
      placement="top"
    >
      <div>&nbsp;</div>
    </Tooltip>
  );

  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  const generateWeeklyOptions = (cadenceType) => {
    locale == getBrowserLocale();
    let days = [];
    if (locale == LOCALES.FRENCH) {
      days = [
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
        "dimanche",
      ];
    } else {
      days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
    }
    return days.map((day) => (
      <option
        className="text-capitalize"
        value={`${
          cadenceType === "weekly"
            ? 'translate{("every")} ${day}'
            : `{translate("on")} ${day}`
        }`}
      >
        {cadenceType === "weekly" ? translate("every") : translate("on")}{" "}
        {day.charAt(0).toUpperCase() + day.slice(1)}
      </option>
    ));
  };

  const generateMonthlyOptions = () => (
    <>
      <option value="first day of the month">{translate("firstDay")}</option>
      <option value="last day of the month">{translate("lastDay")}</option>
      <option value="specific date">{translate("specificDay")}</option>
    </>
  );

  return (
    <>
      <I18nProvider>
        <Layout resources={resources}>
          <div className="inners_wrapper">
            {user?.role != "employee" ? (
              <div className="d-flex justify-content-end align-items-end">
                {!user?.isEmployee && (
                  <button
                    type="button"
                    id="OpenSessionModal"
                    className="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#session"
                  >
                    <i className="fa fa-plus mr-1"></i>{" "}
                    {translate("createSession")}
                  </button>
                )}
              </div>
            ) : null}
            <p></p>
            <div className="mvp_table calendar_table_main pt-3 calendar-n">
              <FullCalendar
                plugins={[resourceTimelinePlugin]}
                initialView="resourceTimeline"
                duration={duration}
                slotDuration={slotDuration}
                slotLabelFormat={slotLabelFormat}
                titleFormat={titleFormat}
                resourceAreaHeaderContent=""
                // headerToolbar={{ left: "", center: "", right: "" }}
                resources={resources}
                events={events}
                resourceLabelContent={CampaignResource}
                eventContent={({ event }) => (
                  <EventComponent event={event?._instance?.range} />
                )}
              />
            </div>
          </div>

          {/* Create Session */}
          <div
            className="modal fade"
            id="session"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="sessionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg " role="document">
              <div className="modal-content">
                <div className="modal-title">
                  <div className="p-3 d-flex justify-content-between align-items-start">
                    <div>
                      <h3 className="" id="sessionModalLabel">
                        {editSession
                          ? translate("update")
                          : translate("create")}{" "}
                        {translate("session")}
                      </h3>
                      <p className="mb-0">
                        {translate("asterisk")} (
                        <span className="text-danger">*</span>).
                      </p>
                    </div>
                    <div className="">
                      <button
                        type="button"
                        className="close"
                        onClick={(e) => clearFields()}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="p-1">
                    <form onSubmit={handleCreateSession}>
                      <div className="">
                        <div className="row">
                          <div className="col-md-3 my-2">
                            <label>
                              {translate("name")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-md-9 my-2">
                            <input
                              type="text"
                              value={form?.title}
                              onChange={(e) =>
                                setform({ ...form, title: e.target.value })
                              }
                              className="form-control"
                              placeholder={translate("sessionName")}
                              required
                            />
                          </div>
                          <div className="col-md-3 my-2">
                            <label>{translate("timeframe")}</label>
                          </div>
                          <div className="col-md-9 my-2 d-flex justify-content-between">
                            <div className="w-49">
                              <DatePicker
                                selected={form?.start_date}
                                onChange={(date) => {
                                  let end_date = form.end_date || date;
                                  if (
                                    new Date(form.start_date).getTime() >=
                                    new Date(end_date).getTime()
                                  )
                                    end_date = date;
                                  setform({
                                    ...form,
                                    start_date: date,
                                    end_date: end_date,
                                  });
                                }}
                                className="form-control"
                                dateFormat="dd/MMM/yyyy"
                                placeholderText={translate("startDate")}
                                minDate={new Date()}
                              />
                            </div>
                            <div className="w-49">
                              <DatePicker
                                selected={form?.end_date}
                                onChange={(date) =>
                                  setform({ ...form, end_date: date })
                                }
                                className="form-control"
                                dateFormat="dd/MMM/yyyy"
                                placeholderText={translate("endDate")}
                                // disabled={form?.start_date}
                                minDate={form?.start_date}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 my-2">
                            <label>{translate("cadence")}</label>
                          </div>
                          <div className="col-md-9 my-2 d-flex justify-content-between">
                            <div className="w-49">
                              <select
                                className="form-control"
                                value={form?.cadenceType}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    cadenceType: e.target.value,
                                  })
                                }
                              >
                                <option value="weekly">
                                  {translate("weekly")}
                                </option>
                                <option value="every two weeks">
                                  {translate("twoWeeks")}
                                </option>
                                <option value="monthly">
                                  {translate("month")}
                                </option>
                              </select>
                            </div>
                            <div className="w-49">
                              <select
                                className="form-control"
                                value={form?.cadenceStart}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    cadenceStart: e.target.value,
                                  })
                                }
                              >
                                {form?.cadenceType !== "monthly"
                                  ? generateWeeklyOptions(form?.cadenceType)
                                  : generateMonthlyOptions()}
                              </select>
                            </div>
                          </div>

                          {form?.cadenceStart == "specific date" &&
                          form?.cadenceType == "monthly" ? (
                            <>
                              <div className="col-md-3 my-2">
                                <label></label>
                              </div>
                              <div className="col-md-9 my-2">
                                <select
                                  className="form-control"
                                  value={form?.specific_date}
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      specific_date: e.target.value,
                                    })
                                  }
                                >
                                  <option value="">
                                    Choose the date on which your month starts
                                  </option>
                                  {specificDates &&
                                    specificDates.map((item) => {
                                      return (
                                        <option
                                          className="text-capitalize"
                                          value={item?.value}
                                        >
                                          {item?.label}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </>
                          ) : null}
                          <div className="col-md-3 my-2">
                            <label>{translate("color")}</label>
                          </div>
                          <div className="col-md-9 my-2">
                            <div className="color-choose">
                              <input
                                type="color"
                                value={form?.color}
                                onChange={(e) =>
                                  setform({ ...form, color: e.target.value })
                                }
                                className="p-0 color-choose"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 d-flex justify-content-end align-items-end">
                        <button
                          type="button"
                          id="CloseSessionModal"
                          onClick={(e) => clearFields()}
                          className="btn btn-secondary ml-2"
                          data-dismiss="modal"
                        >
                          {translate("cancel")}
                        </button>
                        <button
                          type="submit"
                          disabled={!form?.title}
                          className="btn btn-primary btn-md mr-2"
                        >
                          {editSession
                            ? translate("update")
                            : translate("create")}{" "}
                          {translate("session")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </I18nProvider>
    </>
  );
};

export default SessionsListing;
