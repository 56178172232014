import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { toast } from "react-toastify";
import methodModel from "../../../methods/methods";
import { login_success } from "../../../actions/user";
import "./style.scss";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useHistory } from "react-router-dom";
// import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const EditProfile = ({ p, Layout }) => {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState("");
  const [form, setForm] = useState(userType);
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [address, setAddress] = useState("");
  const [inputFocused, setInputFocused] = useState(false);

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        setForm({ ...res.data });
        setData(res.data);
      }
      loader(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    let value = {
      fullName: form.fullName,
      dialCode: form.dialCode || "33",
      mobileNo: form.mobileNo,
      image: form.image,
      id: form.id,
      whatILoveDoing: form.whatILoveDoing,
      howToCommunicate: form.howToCommunicate,
      whatMattersMost: form.whatMattersMost,
      whatCanFrustrate: form.whatCanFrustrate,
      somethingAboutMe: form.somethingAboutMe,
      defaultTrustLevel: form.defaultTrustLevel,
      howToGainTrust: form.howToGainTrust,
      howToLoseTrust: form.howToLoseTrust,
    };

    loader(true);
    ApiClient.put("edit/profile", value).then((res) => {
      console.log(res);
      if (res.success) {
        let uUser = { ...user, ...value };
        dispatch(login_success(uUser));
        localStorage.setItem("profileUpdateMessage", res.message);
        history.push("/profile");
        window.location.reload();
      }
      loader(false);
    });
  };

  const uploadImage = (e) => {
    setForm({ ...form, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", { file: file }).then(
      (res) => {
        if (res.data.fullpath) {
          let image = res.data.fullpath;
          setForm({ ...form, image: image });
        }
        loader(false);
      }
    );
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      gallaryData();
    }
  }, []);

  // const handleSelect = async (selectedAddress) => {
  //   try {
  //     const results = await geocodeByAddress(selectedAddress);
  //     const latLng = await getLatLng(results[0]);

  //     const addressComponents = results[0].address_components;
  //     const city = addressComponents.find((component) =>
  //       component.types.includes('locality')
  //     )?.long_name || '';
  //     const state = addressComponents.find((component) =>
  //       component.types.includes('administrative_area_level_1')
  //     )?.long_name || '';
  //     const country = addressComponents.find((component) =>
  //       component.types.includes('country')
  //     )?.long_name || '';
  //     const pincode = addressComponents.find((component) =>
  //       component.types.includes('postal_code')
  //     )?.long_name || '';

  //     const selectedLocation = {
  //       address: selectedAddress,
  //       city,
  //       state,
  //       country,
  //       pincode,
  //       ...latLng,
  //     };

  //     setForm({
  //       ...form,
  //       city: selectedLocation?.city,
  //       state: selectedLocation?.state,
  //       country: selectedLocation?.country,
  //       pincode: selectedLocation?.pincode,
  //       address: selectedLocation?.address
  //     })
  //     setAddress(selectedAddress);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  // const handleChange = (newAddress) => {
  //   setAddress(newAddress);
  //   setForm({ ...form, address: newAddress })
  // };
  // const clearaddress = () => {
  //   setForm({ ...form, address: '', pincode: '', city: '' })
  //   setAddress('')
  // }

  return (
    <>
      {Layout ? (
        <Layout>
          <Html
            handleSubmit={handleSubmit}
            setForm={setForm}
            form={form}
            uploadImage={uploadImage}
            submitted={submitted}
            Layout={false}
            // handleSelect={handleSelect}
            // handleChange={handleChange}
            // clearaddress={clearaddress}
            // inputFocused={inputFocused}
            // setInputFocused={setInputFocused}
            // address={address}
          />
        </Layout>
      ) : (
        <Html
          handleSubmit={handleSubmit}
          setForm={setForm}
          form={form}
          uploadImage={uploadImage}
          Layout={Layout}
          submitted={submitted}

          // handleSelect={handleSelect}
          // handleChange={handleChange}
          // clearaddress={clearaddress}
          // inputFocused={inputFocused}
          // setInputFocused={setInputFocused}
          // address={address}
        />
      )}
    </>
  );
};

export default EditProfile;
